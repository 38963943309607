import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import { SNavigation } from 'servisofts-component';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            limit: 10,
            excludes: ["key", "estado", "key_usuario"],
        });

        this.filter = SNavigation.getParam("filter");
    }
    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    $filter(data) {
        if (this.filter) return this.filter(data)
        return data.estado != 0
    }

    $getData() {
        return Parent.model.Action.getAll();
    }

    $menu() {
        var menu = super.$menu();
        if (Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" })) {
            menu.push({
                label: "Eliminados", onPress: () => {
                    console.log(Parent.path)
                    SNavigation.navigate(Parent.path + "/eliminados")
                }
            })
        }
        return menu;
    }


    $order() {
        return [{ key: "index", order: "asc", type: "integer" }];
    }
}
export default connect(index);