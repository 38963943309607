import { SPage } from "servisofts-component";
import Model from "../../../Model";
import _new from "./new";
import edit from "./edit";
import producto from "../profile/producto";
import sub_producto from "./sub_producto";

const model = Model.producto;
export const Parent = {
    name: "producto",
    path: `/restaurante/producto`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": producto,
    "new": _new,
    "edit": edit,
    ...sub_producto
})
