import { SPage } from "servisofts-component";
import Model from "../../../Model";
import root from "./root";
import rangos from "./rangos";
import restaurantes from "./restaurantes";
import tags from "./tags";
import producto from "./producto";

export default SPage.combinePages("profile", {
    "": root,
    rangos,
    restaurantes,
    tags,
    producto
})
