import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import { SText, SView, SHr, SNavigation, SLoad, SList, SIcon, SPopup, SThread, SNotification } from 'servisofts-component';
import item from '../item';
import SSocket from 'servisofts-socket';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil Descuento",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            item: item
        });

        this.state = {
            dataRestProd: {}
        }

        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.descuento_restaurante.Action.CLEAR()
        Model.descuento_producto.Action.CLEAR()
        this.getData()
    }

    getData() {
        SSocket.sendPromise({
            "version": "1.0",
            "component": "producto",
            "type": "getAll",
            "estado": "cargando"
        }).then((data) => {
            this.setState({ producto: data.data })
        }).catch(e => {
            console.error(e)
        })

        SSocket.sendPromise({
            "version": "1.0",
            "component": "restaurante",
            "type": "getAll",
            "estado": "cargando"
        }).then((data) => {
            this.setState({ restaurante: data.data })
        }).catch(e => {
            console.error(e)
        })

        SSocket.sendPromise({
            "version": "1.0",
            "component": "categoria_producto",
            "type": "getAll",
            "estado": "cargando"
        }).then((data) => {
            this.setState({ categoria_producto: data.data })
        }).catch(e => {
            console.error(e)
        })

    }

    onBack() {
        return true;
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    registrar_producto = () => {
        if (!this.descuento_producto) return null
        SNavigation.navigate("/restaurante", {
            filter: (obj) => {
                if (this.descuento_restaurante) {
                    let item = Object.values(this.descuento_restaurante).find(a => a.key_restaurante == obj.key && a.estado > 0)
                    if (item) {
                        return false
                    }
                }
                return true;
            },
            onSelect: (obj) => {
                if (!obj) return;
                new SThread(100, "navegacion", true).start(() => {
                    SNavigation.navigate("/restaurante/profile/producto", {
                        pk: obj.key,
                        onSelect: (obj) => {
                            let item = Object.values(this.descuento_producto).find(a => a.key_producto == obj.key && a.estado > 0)
                            if (item) {
                                SPopup.alert("El producto ya se encuentra registrado.")
                                return;
                            }

                            if (obj.habilitado == false) {
                                SPopup.confirm({
                                    title: "Producto Deshabilitado",
                                    message: "tomar en cuenta que no se aplicará el descuento si un producto se deshabilita posterior al añádirlo al descuento.",
                                    onPress: () => {
                                        Model.descuento_producto.Action.registro({
                                            data: {
                                                key_descuento: this.pk,
                                                key_producto: obj.key,
                                            },
                                            key_usuario: Model.usuario.Action.getKey()
                                        }).then((resp) => {
                                            SNavigation.navigate("/descuento/profile/producto", {
                                                pk: this.pk
                                            })
                                        }).catch((e) => {
                                            SPopup.alert("Error: " + e.data)
                                            console.log(e.data);
                                        })
                                    }
                                })
                            } else {
                                Model.descuento_producto.Action.registro({
                                    data: {
                                        key_descuento: this.pk,
                                        key_producto: obj.key,
                                    },
                                    key_usuario: Model.usuario.Action.getKey()
                                }).then((resp) => {
                                    SNavigation.navigate("/descuento/profile/producto", {
                                        pk: this.pk
                                    })
                                }).catch((e) => {
                                    SPopup.alert("Error: " + e.data)
                                    console.log(e.data);
                                })
                            }
                        }
                    })
                })
            }
        })
    }

    generetaJsonRestProd() {
        if (!this.descuento_producto) return null

        let dataRestProd = {}

        Object.values(this.descuento_producto).map((dp) => {
            if (this.descuento_producto[dp.key]) {
                let prod = this.state.producto[dp.key_producto];

                let key_restaurante = this.state.categoria_producto[prod?.key_categoria_producto]?.key_restaurante;

                let key_dp;

                if (!key_restaurante) {
                    key_restaurante = prod?.key_restaurante;
                }

                if (prod?.key === dp?.key_producto) {
                    key_dp = dp?.key;
                }

                if (!dataRestProd[key_restaurante]) {
                    dataRestProd[key_restaurante] = {
                        ...this.state.restaurante[key_restaurante],
                        productos: [],
                        key_descuento_producto: key_dp
                    };
                }
                dataRestProd[key_restaurante].productos.push(prod);
            }
        })
        return dataRestProd;
    }

    componentProducto(productos, descuento_producto) {
        if (!productos || !descuento_producto) return null
        return productos.map((prod) => {
            return <>
                <SHr />
                <SView card col={"xs-12"} row style={{
                    padding: 8
                }}>
                    <SView col={"xs-11"} row>
                        {<SText fontSize={14}>{prod?.nombre}</SText>}
                    </SView>

                    <SView flex style={{ alignItems: "flex-end" }} center
                        onPress={() => {

                            SPopup.confirm({
                                title: "Eliminar Producto",
                                message: "¿Seguro que desea remover el Producto?",
                                onPress: () => {
                                    descuento_producto.estado = 0;
                                    Model.descuento_producto.Action.editar({
                                        data: { ...descuento_producto },
                                        key_usuario: Model.usuario.Action.getKey()
                                    }).then((resp) => {
                                        this.setState(prevState => {
                                            let newProducto = { ...prevState.producto };
                                            delete newProducto[prod.key];
                                            return { producto: newProducto };
                                        });
                                    })
                                }
                            })
                        }}
                    >
                        <SIcon name={'Delete'} width={20} height={20} />
                    </SView>
                </SView>
            </>
        })
    }

    getProducto() {
        this.descuento_producto = Model.descuento_producto.Action.getAll({ key_descuento: this.pk });

        if (!this.state.producto || !this.state.categoria_producto || !this.state.restaurante || !this.descuento_producto) return <SLoad />

        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={this.generetaJsonRestProd()}
                filter={a => a.estado != 0}
                render={(obj) => {
                    return <>
                        <SText bold fontSize={20}>{obj.nombre}</SText>
                        {this.componentProducto(obj.productos, this.descuento_producto[obj.key_descuento_producto])}
                        <SHr h={25} />
                    </>
                }} />
        </SView>
    }

    $footer() {
        return <SView col={"xs-12"}>
            <SHr />
            <SText card padding={16} width={200} center onPress={this.registrar_producto.bind(this)}>AGREGAR PRODUCTO</SText>
            {this.getProducto()}
        </SView>
    }
}
export default connect(index);