import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SView, STable3, SText, SPage, SForm, SPopup } from 'servisofts-component'
import MenuBar from './MenuBar'
import TabSelection from './TabSelection'
import TreeView from './TreeView/index'
import Container from '../../Components/Container'


type ThemeProps = {
    background: any,
    primary: any,
    secondary: any,
    text: any
}
type PropsType = {
    theme: ThemeProps
}
export default class index extends Component<PropsType> {
    static defaultProps: PropsType = {
        theme: {
            background: "#000000",
            primary: "#000000",
            secondary: "#ffffff",
            text: "#ffffff"
        }
    }
    state = {
        loggin: false,
    }
    render() {
        if (!this.state.loggin) {
            return <SPage disableScroll>
                <Container center flex>
                    <SText>Nesecitas permiso para usar esta pagina</SText>
                    <SForm inputs={{
                        "password": { label: "Password", autoFocus: true, type: "password" }
                    }}
                        ref={ref => this.form = ref}
                        onSubmitName={"LOGIN"}
                        onKeyPress={(evt) => {
                            if (evt.key === 'Enter') {
                                this.form.submit();
                            }
                        }}
                        onSubmit={(val) => {
                            if (val.password == "servisofts") {
                                this.setState({ loggin: true })
                            } else {
                                SPopup.alert("Acceso denegado.")
                            }
                        }}
                    />
                </Container>
            </SPage>
        }
        // return <STable3/>
        return <SView col={"xs-12"} flex backgroundColor={this.props.backgrounColor}>
            <MenuBar />
            <SView col={"xs-12"} row flex>
                <TreeView />
                <TabSelection />
            </SView>
        </SView>
    }
}