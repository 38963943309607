import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import { SView, SHr, STheme, SText, SDate } from 'servisofts-component';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            limit: 10,
            excludes: ["key", "estado"],
        });
    }

    isDescuentoActivo = (FF) => {
        let hoy = new SDate().toString("yyyy-MM-dd");
        let fechaFin = new SDate(FF, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd");

        if (fechaFin >= hoy) {
            return true
        }
        return false
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0
    }

    $getData() {
        return Parent.model.Action.getAll();
    }

    $menu() {
        var menu = super.$menu();
        return menu;
    }


    $order() {
        return [{ key: "fecha_on", order: "desc", type: "date" }];
    }

    $item(obj) {
        return <SView key={obj.key} col={"xs-12"} card padding={8} onPress={() => {
            this.$onSelect(obj)
        }}>
            <SView col={"xs-12"} row center>

                <SView style={{
                    borderRadius: 8,
                    backgroundColor: STheme.color.card,
                }} center >
                    <SText fontSize={16} center style={{
                        padding: 4,
                        color: this.isDescuentoActivo(obj?.fecha_fin) ? STheme.color.success : STheme.color.danger
                    }}>{this.isDescuentoActivo(obj?.fecha_fin) ? "Este multiplicador esta activo" : "Este multiplicador esta Inactivo"}</SText>
                </SView>

                <SView width={25} height={25} backgroundColor={this.isDescuentoActivo(obj?.fecha_fin) ? STheme.color.success : STheme.color.danger} style={
                    {
                        borderRadius: 100,
                        marginLeft: 20
                    }
                } />

            </SView>
            <SHr />
            <SHr height={1} color='#666' />
            <SHr />
            <SView col={"xs-12"} row>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SHr h={10} />
                        <SText fontSize={16}>{`Fecha Inicio:  ${new SDate(obj?.fecha_inicio, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16} style={{
                            color: this.isDescuentoActivo(obj?.fecha_fin) ? STheme.color.success : STheme.color.danger
                        }}>{`Fecha Fin:  ${new SDate(obj?.fecha_fin, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Porcentaje:  ${(obj?.porcentaje * 100) + " %" ?? "No se pillo porcentaje"}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Descripción:  ${obj?.descripcion ? obj?.descripcion : "No hay descripción"}`}</SText>
                    </SView>
                </SView>
            </SView>
        </SView>
    }
}
export default connect(index);