import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, SView, SNavigation, STable2, SText } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import SChart from 'servisofts-charts'
import { connect } from 'react-redux'
class index extends Component {
    state = {
        data: null
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/rentabilidad_de_carreras.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin, this.params.key_driver]
        }).then(e => {
            // const data = {};

            // Object.values(e.data).map(e => {
            //     data[this.hourDisplay(e.fecha)] = e.cantidad;
            // });

            this.setState({ data: e.data });
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    isJsonEmpty(obj) {
        if (!obj) return
        return Object.keys(obj).length === 0;
    }

    // renderChart() {
    //     if (!this.state.data) return <SLoad />
    //     if (this.isJsonEmpty(this.state.data)) return <SText center fontSize={20}>No hay datos</SText>

    //     return <SChart data={this.state.data}
    //         type='Line'
    //         textColor={STheme.color.text}
    //         strokeWidth={1}
    //         showLabel={true}
    //         showValue={true}
    //         // showControl={true}
    //         showGuide={true}
    //         colors={['#fd5800', '#ff358b', '#e0ffff', '#ff006e', '#fa5820', '#e0ffff', '#8cfffb', '#39ff14', '#ff006e', '#ff00ff', '#fd5800', '#ff358b', '#e0ffff', '#ff006e', '#fd5800', '#e0ffff', '#8cfffb', '#39ff14', '#ff006e', '#ff00ff', '#fd5800', '#ff358b', '#e0ffff', '#ff006e', '#fd5800', '#e0ffff', '#8cfffb', '#39ff14', '#ff006e', '#ff00ff']} />
    // }

    renderTable() {
        // let users = Model.usuario.Action.getAll()
        // if (!users) return <SLoad />
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "fecha", label: "Fecha", width: 100, center: true, order: "desc" },
                    { key: "cantidad", label: "Cantidad", width: 80, center: true },
                    { key: "metodo_pago", label: "Método de Pago mas frecuente", width: 150, center: true },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte rentabilidad de carrera por hora desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >
            <SView card col={"xs-12"} flex>
                {this.renderTable()}
                {/* {this.renderChart()} */}
            </SView >
        </SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);