import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SInput, SNavigation, SPopup, SHr } from 'servisofts-component';
import Model from '../../../Model';
import InputDay from '../../../Components/InputDay';
import PButtom from '../../../Components/PButtom';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["key_restaurante"],
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_restaurante", "dia"]
        });

        this.state = {
            loading: false
        }

        this.key_restaurante = SNavigation.getParam("key_restaurante");
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var imp = super.$inputs();
        imp["hora_inicio"].col = "xs-5.5"
        imp["hora_inicio"].type = "hour"
        imp["hora_inicio"].required = true

        imp["hora_fin"].col = "xs-5.5"
        imp["hora_fin"].type = "hour"
        imp["hora_fin"].required = true

        return imp;
    }

    $submitName() {
        return ""
    }



    $header() {
        return <InputDay label={"Dia"} defaultValue={""} ref={ref => this.dia = ref} required />
    }

    $footer() {
        let pack = {};
        return <>
            <SInput
                ref={ref => this.iprecioOriginal = ref}
                col={"xs-5.5"}
                type={"money"}
                label={"Precio Original"}
                // defaultValue={0}
                required={true}
            />

            <SInput
                ref={ref => this.iprecio = ref}
                col={"xs-5.5"}
                type={"money"}
                label={"Precio"}
                // defaultValue={0}
                required={true}
            />

            <SInput
                ref={ref => this.icantidad = ref}
                col={"xs-5.5"}
                type={"number"}
                label={"Cantidad"}
                // defaultValue={0}
                required={true}
            />

            <SHr h={20} />

            <PButtom
                danger={true}
                width={120}
                fontSize={12}
                center
                loading={this.state.loading}
                onPress={() => {
                    pack.cantidad = this.icantidad.getValue();
                    pack.precio = this.iprecio.getValue();
                    pack.precio_original = this.iprecioOriginal.getValue();

                    this.dataAux = {
                        ...pack
                    }

                    let isValid = true;

                    if (!this.iprecioOriginal.verify()) {
                        isValid = false;
                    }

                    if (!this.iprecio.verify()) {
                        isValid = false;
                    }

                    if (!this.icantidad.verify()) {
                        isValid = false;
                    }

                    if (!this.dia.verify()) {
                        isValid = false;
                    }

                    if (!isValid) {
                        return;
                    }
                    this.form.submit()
                }}
            >
                Crear Horario
            </PButtom>
        </>
    }

    $onSubmit(data) {
        this.setState({ loading: true });
        if (data.hora_inicio.length != 5) {
            SPopup.alert("La hora inicio debe ser el formato 24:00");
            this.setState({ loading: false });
            return;
        }
        if (data.hora_fin.length != 5) {
            SPopup.alert("La hora fin debe ser el formato 24:00");
            this.setState({ loading: false });
            return;
        }

        data.key_restaurante = this.key_restaurante;

        data.dia = this.dia.getValue();

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            Model.pack.Action.registro({
                data: {
                    key_horario: resp.data.key,
                    ...this.dataAux
                },
                key_usuario: Model.usuario.Action.getKey()
            }).then((resp) => {
                if (this.key_restaurante) {
                    SNavigation.navigate("/restaurante/profile/horario", { pk: this.key_restaurante });
                } else {
                    SPopup.alert("Recuerda navegar con la interfaz de la aplicación, no con los botones del navegador, sino pasan cosas raras como esta. Nota: si necesitas recargar y no hay la opción recuerda reportarlo, para añadirlo");
                    SNavigation.goBack();
                }
                this.setState({ loading: false });
            }).catch((e) => {
                SPopup.alert(`Error: ${e.error} - intente de nuevo la acción`)
                console.error(e);
                this.setState({ loading: false });
            })

        }).catch(e => {
            SPopup.alert(`Error: ${e.error} - intente de nuevo la acción`)
            console.error(e);
            this.setState({ loading: false });
        })
    }
}

export default connect(index);