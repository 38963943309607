import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "categoria_tag_tag"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" },
        "estado": { type: "integer" },
        "key_categoria_tag": { type: "text", fk: "categoria", editable: false, notNull: false },
        "key_tag": { type: "text", fk: "tag", editable: false, notNull: false },
    },
    Action,
    Reducer,
});