import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "restaurante_tag"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" }, 
        "estado": { type: "integer" },
        
        "key_tag": { type: "text", editable: true, label: "Key Restaurante" },
        "key_restaurante": { type: "text", editable: true, label: "Key Restaurante" }
    },
    Action,
    Reducer,
});