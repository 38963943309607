import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SPage, SView, SText, SIcon, STheme, SForm, SInput, SNavigation, STable, SLoad, SButtom, SPopup, SMarker, SMapView2, SMapView, SThread, SDate, SImage } from 'servisofts-component';
import Model from '../../Model';
import SSocket from 'servisofts-socket'

const PEDIDO_VARIANT = {};
class Mapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: false,
        };
        this.circles = {};
        this.isRun = false;
    }
    componentDidMount() {
        this.isRun = true;
        this.hilo();
    }
    componentWillUnmount() {
        this.isRun = false;
    }
    hilo() {
        if (!this.isRun) return;
        this.state.data = Model.background_location.Action.CLEAR();
        Model.pedido.Action.CLEAR();
        // this.state.data = Model.background_location.Action.CLEAR();
        // this.setState({ ...this.state })
        new SThread(2500, "hilo_pedidos", true).start(() => {
            if (!this.isRun) return;
            this.hilo();
        })
    }
    uuidToColor(uuid) {
        var sum = 0;
        for (var i = 0; i < uuid.length; i++) {
            sum += uuid.charCodeAt(i);
        }
        var hex = sum.toString(16);
        return '#' + hex;
    }
    render() {
        let usuarios = Model.usuario.Action.getAll();
        let pedidos = Model.pedido.Action.getAllActivos()
        let conductores = Model.background_location.Action.getAll();
        if (!usuarios) return <SLoad />
        if (!pedidos) {
            pedidos = this.state.pedidos
        } else {
            this.state.pedidos = pedidos;
        }

        if (!conductores) {
            conductores = this.state.conductores;
        } else {
            this.state.conductores = conductores;
        }

        if (!pedidos || !conductores) return <SLoad />
        return (
            <SPage
                title={`Pedidos`}
                disableScroll
            >
                <SView center height >
                    <SMapView initialRegion={{
                        latitude: -17.783799,
                        longitude: -63.180,
                        latitudeDelta: 0.1,
                        longitudeDelta: 0.1
                    }}>
                        {Object.values(pedidos).filter(a => a.state != "pendiente_pago"
                            && a.state != "timeout_pago"
                            && a.state != "entregado"
                            && a.state != "cancelado"
                            && a.state != "listo"
                            && a.state != "pagado"
                            // && a.state != "pagado"
                            // && a.state != "listo"
                        ).map((obj) => {
                            if (!PEDIDO_VARIANT[obj.key]) {
                                PEDIDO_VARIANT[obj.key] = {
                                    lat: (Math.random() * 0.0001),
                                    lng: (Math.random() * 0.0001),
                                }
                            }
                            return <SMapView.SMarker latitude={obj?.direccion?.latitude + PEDIDO_VARIANT[obj.key].lat} longitude={obj?.direccion?.longitude + PEDIDO_VARIANT[obj.key].lng} >
                                <SView width={50} height={50} center onPress={() => {
                                    SNavigation.navigate("/pedido/profile", { pk: obj.key })
                                }}>

                                    <SView width={28} height={28}
                                        style={{
                                            borderWidth: 4,
                                            borderColor: obj.key_conductor ? this.uuidToColor(obj.key_conductor) : STheme.color.text,
                                            borderRadius: 100,
                                            overflow: "hidden"
                                        }}>
                                        <SImage src={Model.restaurante._get_image_download_path(SSocket.api, obj?.restaurante?.key)} />
                                    </SView>
                                    {/* <SIcon name={"Marker"} fill={obj.key_conductor ? this.uuidToColor(obj.key_conductor) : STheme.color.text} /> */}
                                    {/* <SText fontSize={8}>{obj.state}</SText> */}
                                    <SText fontSize={10} color={this.uuidToColor(obj.key)}>{usuarios[obj.key_conductor]?.Nombres ?? "s/c"}</SText>
                                    {/* <SText fontSize={10}>{obj.state}</SText> */}
                                </SView>
                            </SMapView.SMarker>
                        })}
                        {Object.values(conductores).filter(obj => new SDate(obj.fecha_last).toString("yyyy-MM-dd") == new SDate().toString("yyyy-MM-dd")).map((obj) => {
                            return <SMapView.SMarker latitude={parseFloat(obj.latitude??0)} longitude={parseFloat(obj.longitude??0)} >
                                <SView width={60} height={60} center onPress={() => {
                                    SNavigation.navigate("/usuario/profile", { pk: obj.key_usuario })
                                }}>
                                    <SIcon name={"Idelivery"} fill={obj.tipo == "start" ? this.uuidToColor(obj.key_usuario) : STheme.color.danger} />
                                    <SText fontSize={10}>{usuarios[obj.key_usuario]?.Correo}</SText>
                                    <SText fontSize={12}>{new SDate(obj.fecha_last).toString("hh:mm")}</SText>
                                </SView>
                            </SMapView.SMarker>
                        })}
                    </SMapView>
                </SView >

            </SPage >
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Mapa);