import { SPage } from "servisofts-component";
import config from "./config";
import restaurante_excluir_accion_global from "./restaurante_excluir_accion_global";

export const Parent = {
    name: "restaurante_accion_global",
    path: `/restaurante_accion_global`,
}

export default SPage.combinePages(Parent.name, {
    "": config,
    ...restaurante_excluir_accion_global
})
