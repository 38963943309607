import { SHr, SIcon, SImage, SText, STheme, SView } from 'servisofts-component';
import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';
import SSocket from 'servisofts-socket'
class index extends DPA.list {

    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "key_servicio", "estado"],
            menuType:"flex",
            onRefresh: (resolve) => {
                Parent.model.Action.CLEAR();
                resolve();
            }

        });
        this.state = {
            select: {
                "pendientes": true,
                "soporte_client": true,
                "soporte_partner": true,
                "soporte_driver": true,
            },
            ...this.state,
        }
    }
    optionItem({ key, label, color }) {
        var select = !!this.state.select[key]
        return <SView height={35} center style={{
            paddingLeft: 8,
            paddingRight: 8,
            opacity: select ? 1 : 0.5,
            backgroundColor: color + "AA"
        }} onPress={() => {
            if (!select) {
                this.state.select[key] = true;
            } else {
                delete this.state.select[key];
            }
            this.setState({ ...this.state })
        }} row>
            {!select ? null : <> <SIcon name={"Close"} width={12} height={12} fill={STheme.color.text} /> <SView width={8} /></>}
            <SText>{label}</SText>
        </SView>
    }
    $menu() {
        let menu = super.$menu();
        menu.push({ children: this.optionItem({ key: "soporte_client", label: "Clientes", color: "#FF9999" }) })
        menu.push({ children: this.optionItem({ key: "soporte_partner", label: "Partners", color: "#99ff99" }) })
        menu.push({ children: this.optionItem({ key: "soporte_driver", label: "Drivers", color: "#FF99ff" }) })
        menu.push({ children: this.optionItem({ key: "pedido", label: "Pedidos", color: "#9999ff" }) })
        menu.push({ children: this.optionItem({ key: "pendientes", label: "Pendientes", color: STheme.color.warning }) })
        menu.push({ children: this.optionItem({ key: "cerrados", label: "Cerrados", color: STheme.color.danger }) })
        // menu.push({ children: this.optionItem({ key: "soporte_partner", label: "Cerrados", color: STheme.color.danger }) })
        return menu;
    }
    $allowAccess() {
        return true;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $filter(data) {
        // if (data.estado == 1 && (data.tipo != "pedido")) {
        if (!Object.keys(this.state.select).includes(data.tipo)) {
            return false;
        }
        if (this.state?.select?.pendientes) {
            if (data.estado == 1) return true;
        }
        if (this.state?.select?.cerrados) {
            if (data.estado == 2) return true;
        }
        return false;
    }
    $getData() {
        return Parent.model.Action.getAll({
            // key_usuario: Model.usuario.Action.getKey()
            key_usuario: null
        });
    }
    getEstadoText(obj) {
        switch (obj.estado) {
            case 1:
                return "Pendiente"
            case 2:
                return "Cerrado"
            default:
                return "NOT FOUND"
        }
    }
    $item(obj) {
        return <SView col={"xs-12"} card padding={8} onPress={() => {
            this.$onSelect(obj)
        }}>
            <SView col={"xs-12"} row>
                <SView width={50} height={50} card>
                    <SImage src={Model.usuario._get_image_download_path(SSocket.api, obj.key_usuario)} />
                </SView>
                <SView width={4} />
                <SView flex>
                    <SText bold>{obj.descripcion}</SText>
                    <SText>{obj.tipo}</SText>
                    <SText>{this.getEstadoText(obj)}</SText>
                </SView>
            </SView>
        </SView>
    }
}
export default connect(index);