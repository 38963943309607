import { SView, SImage, SText, SIcon, SPopup, SNavigation, SLoad } from 'servisofts-component';
import DPA from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import { connect } from 'react-redux';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            // params: ["key_restaurante"],
            excludes: ["key", "fecha_on", "key_usuario", "estado"],
            onRefresh: (resolve) => {
                Model.company_patrocinado.Action.CLEAR();
                resolve();
            }
        });

        this.state = {};
    }

    componentDidMount() {
        Parent.model.Action.CLEAR();

        SSocket.sendPromise({
            component: "company",
            type: "getAll",
        }).then(e => {
            this.setState({ company: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }


    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0;
    }

    $order() {
        return [{ key: "index", order: "asc" }]
    }

    $getData() {
        return Parent.model.Action.getAll();
    }

    onEdit(obj) {
        SNavigation.navigate(Parent.path + "/edit", { pk: obj.key })
    }

    onDelete(obj) {
        if (this.deletePermiso) {
            SPopup.confirm({
                title: "Eliminar Patrocinado Company",
                message: "¿Seguro que desea remover Patrocinado Company?",
                onPress: () => {
                    Parent.model.Action.editar({
                        data: {
                            // ...obj,  
                            key: obj.key,
                            estado: 0
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }
            })
        } else {
            SPopup.alert("No tiene permisos para eliminar el Popup Inicio")
        }
    }

    $item(obj) {

        this.deletePermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
        this.editPermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" });

        if (!this.state.company) return <SLoad />

        return <>
            <SView card
                flex center row
                style={{
                    padding: 20,
                    justifyContent: "space-between"
                }}
            >

                <SView >
                    <SText>Index: {obj.index}</SText>
                    <SText>Company: {this.state?.company[obj.key_company]?.nombre}</SText>
                </SView>

                <SView row>
                    <SView style={{ marginRight: "10px" }} onPress={() => this.onEdit(obj)}>
                        {this.editPermiso ? <SIcon name={"Edit"} height={30} width={30}></SIcon> : <SView />}
                    </SView>

                    <SView onPress={() => this.onDelete(obj)}>
                        {this.deletePermiso ? <SIcon name={"Delete"} height={30} width={30}></SIcon> : <SView />}
                    </SView>
                </SView>
            </SView>

        </>
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);