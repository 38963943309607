import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_pedido", "tipo_pago", "transaction_id",]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var inp = super.$inputs();
        inp["key_cliente"].onPress = (val) => {
            SNavigation.navigate("/usuario", {
                onSelect: (val) => {
                    this.setState({ cliente: val })
                }
            })
        }
        inp["key_cliente"].value = this.state?.cliente?.Correo ?? "";
        inp["monto"].type = "money";
        // inp["fecha_off"].label = "Fecha de expiracion"
        return inp;
    }
    $onSubmit(data) {
        if (!this.state.cliente.key) return null;
        data.key_cliente = this.state.cliente.key;
        data.tipo_pago = "regalo_administrador"
        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);