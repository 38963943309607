import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SMath, SPage, SText, STheme, SView, SNavigation } from 'servisofts-component';
import Alert from 'servisofts-component/img/Alert';

class Pedido_item extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    calcularIncentivoDriver() {
        const { incentivos } = this.props.data;
        let totalIncentivo = 0;
        if (incentivos) {
            incentivos.forEach(incentivo => {
                totalIncentivo += incentivo.monto_driver;
            });
        }
        return totalIncentivo;
    }

    render() {
        const { key, state, precio, fecha, comision_delivery, cantidad, key_pedido, horario, delivery, tipo_pago } = this.props.data;
        let tipo_pago_str = "Online";

        if (tipo_pago) {
            if (tipo_pago.find(a => a.type == "efectivo")) {
                tipo_pago_str = "Efectivo";
            }
        }
        return <SView col={"xs-12"} card row style={{
            padding: 9, borderRadius: 8
        }} center onPress={() => SNavigation.navigate("/pedido/profile", { pk: key })}>
            <SView col={"xs-2.5"}
                height
                flex
                style={{ borderRightColor: "#DADADA", borderRightWidth: 2 }}
            >
                <SText fontSize={14} center>
                    {new SDate(fecha, "yyyy-MM-dd").getDayOfWeekJson().textSmall}
                </SText>
                <SText fontSize={12} center>
                    {fecha}
                </SText>
                <SText fontSize={10.5} center>
                    {/* {new SDate(fecha_on).toString("hh:mm:ss")} */}
                </SText>
            </SView>
            <SView col={"xs-2.8"} center
                height
                style={{ borderRightColor: "#DADADA", borderRightWidth: 2 }}
            >
                <SText bold fontSize={12} >DELIVERY</SText>
                <SText fontSize={12} bold>{"Bs."} {SMath.formatMoney(delivery)}</SText>
            </SView>
            <SView col={"xs-2.8"} center
                height
                style={{ borderRightColor: "#DADADA", borderRightWidth: 2 }}
            >
                <SText bold fontSize={12} >INCENTIVO</SText>
                <SText bold fontSize={12} color={STheme.color.success}> + Bs. {SMath.formatMoney(this.calcularIncentivoDriver() ?? 0)}</SText>
            </SView>
            <SView col={"xs-2.8"} center
                height
                style={{ borderRightColor: "#DADADA", borderRightWidth: 2 }}
            >
                <SText bold fontSize={12} >COMISIÓN</SText>
                <SText bold fontSize={12} color={STheme.color.danger}> - Bs. {SMath.formatMoney(comision_delivery ?? 0)}</SText>
            </SView>
            <SView center>
                {/* <SIcon name={(delivery > 0 ? "Idelivery" : "Irecoger")} width={30} fill={STheme.color.lightGray} /> */}

                <SView center>
                    <SText bold fontSize={10} color={STheme.color.success}
                        style={{
                            textTransform: "uppercase"
                        }}
                    >
                        {state}
                    </SText>
                    {/* <SIcon name='Ipago' height={9} width={14} fill={STheme.color.lightGray} /> */}
                    <SText fontSize={10}
                        style={{
                            textTransform: "uppercase",
                            color: STheme.color.gray
                        }}
                    >{tipo_pago_str}</SText>
                </SView>

            </SView>
            <SView />
        </SView>
    }
}
// const initStates = (state) => {
//     return { state }
// };
// export default connect(initStates)(Pedido_item);
export default (Pedido_item);