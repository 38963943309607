import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '..'
import { connect } from 'react-redux'
import Model from '../../../Model'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/cantidad_de_ofertas_de_carreras.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            console.log(e.data);

            let keys = [...new Set(Object.values(e.data).map(a => a.key_conductor).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario_conductor = e2?.data[a.key_conductor]?.usuario ?? {}
                })
                this.setState({ data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />

        return <STable2
            header={
                [
                    { key: "usuario_conductor", label: "Driver", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "notificadas", label: "Notificada", width: 60 },
                    { key: "aceptada", label: "Aceptada", width: 60 },
                    { key: "no_tomadas", label: "No Tomadas", width: 70 },
                    { key: "asignacion_manual", label: "Asignacion Manual", width: 120 },
                    {
                        key: "key_conductor", label: "Ver Driver", width: 120, component: val =>
                            <SText onPress={() => {
                                SNavigation.navigate("/usuario/profile", { pk: val })
                            }}>VER</SText>
                    },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de costo promedio de entrega ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >
            <SView col={"xs-12"} flex>
                {this.renderTable()}
            </SView>
        </SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);