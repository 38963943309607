import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SHr, SInput, SLoad, SNavigation, SPopup, SText, SView, SThread, STheme } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import PButtom from '../../../Components/PButtom';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: [/* "filtro_explorar" */]
        });
        this.state = {
            data: {},
        };
    }

    componentDidMount() {
        // Model.categoria_tag_tag.Action.CLEAR();
        SSocket.sendPromise({
            component: "categoria_tag",
            type: "getAll",
        }).then(res => {
            this.setState({ data: res.data });
        }).catch(e => {
            console.log(e);
        });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" });
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $inputs() {
        var inp = super.$inputs();

        inp["filtro_explorar"].label = "filter";
        inp["filtro_explorar"] = { ...inp["filtro_explorar"], type: "checkBox" };

        inp["filtro_explorar"].defaultValue = inp["filtro_explorar"].defaultValue == 1;


        return inp;
    }

    $submitName() {
        return null;
    }

    controllerSocket({ type, extra }) {
        switch (type) {
            case 'registro':
                Model.categoria_tag_tag.Action.registro({
                    data: extra,
                    key_usuario: Model.usuario.Action.getKey()
                }).then((resp) => {
                    // console.log("Registo una categoria tag tag");
                }).catch(e => {
                    console.error(e);
                })
                break;
            case 'editar':
                Model.categoria_tag_tag.Action.editar({
                    data: {
                        ...extra
                    },
                    key_usuario: ""
                }).then((resp) => {
                    // console.log("Edito una categoria tag tag");
                }).catch(e => {
                    console.error(e);
                })
                break;
        }
    }

    catTagInt(ct) {
        const catTagArray = Object.values(this.ctts || {});

        return catTagArray.find(item => item?.key_categoria_tag === ct?.key && item.key_tag === this.$params.pk);
    }

    validarCategorias(ct, valOnChange) {
        let state;
        let type = 'registro';
        let obj = null;

        let ctt = this.catTagInt(ct);

        if (ctt) {
            state = valOnChange ? 1 : 0;
            type = 'editar';
            ctt.estado = state;
            obj = ctt;
        } else if (!obj) {
            obj = {
                key_categoria_tag: ct.key,
                key_tag: this.$params.pk,
                estado: valOnChange ? 1 : 0
            };
        }

        this.handleCategoria({ type: type, extra: obj });
    }

    handleCategoria({ type, extra }) {
        this.controllerSocket({ type, extra });
    }

    renderCbCategoriaTag() {
        return Object.values(this.state.data).map(ct => (
            <SView key={ct.key}
                border={STheme.color.text}
                style={{
                    borderRadius: 10,
                    margin: 5,
                    padding: 10
                }}
            >
                <SText>{ct.descripcion}</SText>
                <SHr />
                <SInput
                    type={"checkBox"}
                    defaultValue={this.catTagInt(ct)?.estado == 1 ? true : false}
                    onChangeText={(e) => {
                        this.validarCategorias(ct, e);
                    }}
                />
            </SView>
        ));
    }

    $footer() {
        this.ctts = Model.categoria_tag_tag.Action.getAll();
        if (!this.state.data && !this.ctts) return <SLoad />;

        return (
            <SView center>
                <SText bold>Categorias Tags</SText>
                <SHr />
                <SView row>
                    {this.renderCbCategoriaTag()}
                </SView>

                <SHr />

                <PButtom
                    danger={true}
                    fontSize={20}
                    onPress={() => { this.form.submit() }}
                >
                    Editar Tag
                </PButtom>
            </SView>
        );
    }

    $onSubmit(data) {
        data.filtro_explorar = data.filtro_explorar ? 1 : 0;

        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        });
    }
}

export default connect(index);
