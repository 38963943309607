import { SAction } from "servisofts-model";
import Model from "../..";
export default class Action extends SAction {
    getAll({ key_cupon }:any) {
        return this.getAllByKeyCupon(key_cupon)
    }
    getAllByKeyCupon(key_cupon:any) {
        // var empresa: any = Model.empresa.Action.getSelect();
        // if (!empresa) return null;
        var reducer = this._getReducer();
        if (reducer.key_cupon != key_cupon) {
            reducer.data = "";
            reducer.key_cupon = key_cupon;
        }

        var resp = super.getAll({
            key_cupon: key_cupon,
            key_usuario: Model.usuario.Action.getKey()
        })
        return resp;
    }
}