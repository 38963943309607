import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SText } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/comercios_rentabilidad.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "index", label: "#", width: 50 },
                    { key: "restaurante/nombre", label: "Nombre", width: 200 },
                    { key: "cantidad_pedidos", label: "Cantidad Pedidos", width: 100 },
                    { key: "monto_total_comision_restaurante", label: "Total Comisión", order: "desc", width: 140, render: val => parseFloat((val).toFixed(2)) },
                    { key: "promedio_total_sin_delivery", label: "Promedio total sin delivery", width: 140, render: val => parseFloat((val).toFixed(2)) },
                    { key: "promedio_producto", label: "Promedio producto", width: 140, render: val => parseFloat((val).toFixed(2)) },
                    { key: "promedio_producto_con_descuento", label: "Promedio producto con descuento", width: 160, render: val => parseFloat((val).toFixed(2)) },
                    { key: "promedio_tapeke", label: "Promedio Tapeke", width: 140, render: val => parseFloat((val).toFixed(2)) },
                    // { key: "nombre", label: "Nombre Comercio", width: 200 },
                    // { key: "cantidad_pedido", label: "Cantidad Pedido", width: 100, order: "desc"},
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de COMERCIOS RENTABILIDAD ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);