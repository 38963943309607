import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SForm, SPopup, SNotification, STheme } from 'servisofts-component'
import Model from '../../../Model';
import SSocket from 'servisofts-socket';

export default class PopupCancelacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detalleOptions: [""],
            motivo: ""
        }
    }

    motivoOptions(motivo) {
        let detailOptions = [];
        switch (motivo) {
            case "comercio":
                detailOptions = ["", "Comercio Cerrado", "Falta de Stock"];
                break;

            case "cliente":
                break;

            case "logistica":
                break;

            case "pedido de prueba":
                break;
        }

        return detailOptions;
    }

    render() {
        let motivoOptions = ["", "comercio", "cliente", "logistica", "pedido de prueba"];
        return <>
            <SForm
                key={this.state.detalleOptions}
                style={{ padding: 12 }}
                inputs={{
                    motivo: {
                        type: "select",
                        options: motivoOptions,
                        label: "Motivo de cancelación",
                        value: this.state.motivo,
                        onChangeText: (motivo) => {
                            this.setState({ detalleOptions: this.motivoOptions(motivo), motivo: motivo });
                        }
                    },
                    detalle: { type: "select", options: this.state.detalleOptions, label: "Detalle de cancelación (hay motivos que no tienen detalle)" }
                }}
                onSubmitName={"Aceptar"}
                onSubmit={(val) => {
                    if (val.motivo == "") {
                        SPopup.alert("debe, seleccionar un motivo de cancelación");
                        return;
                    }


                    if (!val.detalle) {
                        val.detalle = "";
                    }

                    if (val.motivo == "pedido de prueba") {
                        val.motivo = "pedido_prueba";
                    }

                    Model.pedido.Action.editar({
                        data: {
                            ...this.props.pedido,
                            motivo_cancelacion: val
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    }).then((resp) => {
                        SSocket.sendPromise2({
                            data: {
                                key_usuario: Model.usuario.Action.getKey(),
                            },
                            component: "pedido",
                            type: "change_state",
                            key_pedido: this.props.pedido.key,
                            state: 'cancelado',
                        }).then(e => {
                            SNotification.send({
                                title: "Pedido cancelado",
                                body: "El pedido ha sido cancelado",
                                color: STheme.color.success,
                                time: 6000,
                            })
                            SPopup.close("PopupCancelacion");
                        }).catch(e => {
                            SNotification.send({
                                title: "Error en la acción",
                                body: e.error,
                                color: STheme.color.danger,
                                time: 6000,
                            })
                            console.error(e);
                        })
                        this.props.pop
                    }).catch(e => {
                        SNotification.send({
                            title: "Error en la acción",
                            body: e.error,
                            color: STheme.color.danger,
                            time: 6000,
                        })
                        console.error(e);
                    })
                }}
            />
        </>
    }
}