import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/ranking_de_productos_mas_vendidos.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin, this.params.key_restaurante]
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "index", label: "#", width: 50 },
                    { key: "nombre", label: "Producto", width: 100 },
                    { key: "monto_total_vendido", label: "monto vendido", width: 100, render: val => `${parseFloat(val).toFixed(2)} Bs` },
                    { key: "cantidad", label: "Cantidad", width: 50 },
                    { key: "precio_venta_promedio", label: "Precio venta promedio", width: 140, order: "desc", render: val => `${parseFloat(val).toFixed(2)} Bs` },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de QUÉ PRODUCTO VENDE MÁS - ITEAMS ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);