import { SPage } from "servisofts-component";

import root from "./root";
import pasarela from "./pasarela";
import restaurante from "./restaurante";
import delivery from "./delivery";
export default SPage.combinePages("conciliacion",
    {
        "": root,
        ...pasarela,
        ...restaurante,
        ...delivery

    }
)