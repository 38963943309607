import { SPage } from "servisofts-component";
import Model from "../../Model";
import list from "./list";
import table from "./table";
import _new from "./new";
import profile from "./profile";
import edit from "./edit";
import _delete from "./delete";
import horario from "./horario";
import pack from "./pack";
import producto from "./producto";
import categoria_producto from "./categoria_producto";
import restaurante_slider from "./restaurante_slider";
import tag from "./tag";

import mapa from "./mapa";
import restaurante_accion_global from "./restaurante_accion_global";
import restaurante_excluir_accion_global from "./restaurante_accion_global/restaurante_excluir_accion_global";
import roles from "./roles";

const model = Model.restaurante;
export const Parent = {
    name: "restaurante",
    path: `/restaurante`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "table": table,
    "new": _new,
    ...profile,
    "edit": edit,
    "delete": _delete,
    ...horario,
    ...pack,
    ...producto,
    ...categoria_producto,
    ...restaurante_slider,
    ...tag,
    ...restaurante_accion_global,
    ...restaurante_excluir_accion_global,
    ...roles,
    

    "mapa": mapa,
})
