import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';
import { SDate, SMath, SNavigation, SText, SButtom } from 'servisofts-component';
import descuento from '../descuento';

class index extends DPA.table {
    constructor(props) {
        super(props);
        this.pk = SNavigation.getParam("pk");
    }

    renderIncentivos(incentivo) {
        if (incentivo && incentivo?.length > 0) {
            let totalIncentivo;
            totalIncentivo = this.totalIncentivos(incentivo);
            return "Bs. " + SMath.formatMoney(totalIncentivo)
        } else {
            return "No hay incentivos"
        }
    }

    totalIncentivos(incentivos) {
        if (incentivos) {
            return incentivos.reduce((acumulador, objetoActual) => {
                return acumulador + objetoActual.monto;
            }, 0);
        }
        return 0;
    }

    renderDescuentos(pedido, tipoDescuento) {
        if (pedido) {
            switch (tipoDescuento) {
                case "delivery":
                    return "Bs. " + SMath.formatMoney(parseFloat(pedido?.total_descuento_delivery ?? 0))

                case "producto":
                    break;

                default:
                    break;
            }
        } else {
            return "Compra sin descuento"
        }
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" })
    }

    $filter(data) {
        if (data.estado != 0 && data.state == "entregado" || data.state == "no_recogido" || data.state == "cancelado" || data.state == "anulado") {
            return data
        }
    }


    $headers() {
        return {
            "index": { label: "#" },
            "key": { label: "Detalle Pedido", width: 100, component: (key) => <SButtom onPress={() => SNavigation.navigate("/pedido/profile", { pk: key })}>VER</SButtom> },
            "fecha": { label: "Fecha de Entrega o Fecha Horario", order: "desc", width: 100 },
            "fecha_on": { label: "Fecha de Pedido", width: 100, render: a => new SDate(a).toString("yyyy-MM-dd") },
            "hora_pedido": { label: "Hora de Pedido", width: 80, render: a => new SDate(a).toString("hh:mm") },
            "key_pedido": { label: "Key Pedido", width: 250 },
            "state": { label: "Estado Pedido", width: 80 },
            "usuario": { label: "Usuario", width: 200, render: a => a ? a.Nombres + " " + a.Apellidos : "No se pillo el usuario" },

            "restaurante": { label: "Restaurante", width: 200, render: a => a.nombre },

            "restaurante/nombre_representante": { label: "Nombre Representante Partner", width: 200 },

            "driver": { label: "Driver", width: 200, render: a => a ? a.Nombres + " " + a.Apellidos : "Recoger del lugar" },


            // Calculo de Tapekes
            "cantidad": { label: "Cantidad Tapekes", width: 100 },
            "precio": { label: "Precio Unitario Tapekes", width: 120, render: a => "Bs. " + SMath.formatMoney(a, 2) },


            "comision_restaurate_venta": { label: "Ganancia Partner", width: 120, render: a => "Bs. " + SMath.formatMoney(a, 2) },
            "comision_restaurante": { label: "Comisión Tapeke por venta", width: 140, render: a => "Bs. " + SMath.formatMoney(a, 2) },
            "total_tapekes": { label: "Total Tapeke", width: 120, render: a => "Bs. " + SMath.formatMoney(a, 2) },

            "comision_driver": { label: "Ganancia Driver", width: 120, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },
            "comision_delivery": { label: "Comision Tapeke por delivery", width: 150, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },
            "delivery": { label: "Delivery", width: 120, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },

            "incentivos": { label: "Total Incentivos Driver", width: 150, render: a => a ? this.renderIncentivos(a) : "Recoger del lugar" },

            "-": { label: "Descuento Delivery", width: 120, render: a => this.renderDescuentos(a, "delivery") },

            "pago_cliente_delivery": { label: "Delivery pagado por Cliente", width: 140, render: a => "Bs." + SMath.formatMoney(a, 2) },

            "total_cliente_pagado": { label: "Total pagado por el Cliente", width: 130, render: a => "Bs." + SMath.formatMoney(a, 2) },
        };
    }

    $getData() {
        let usuarios = Model.usuario.Action.getAll();
        let pedidos = Parent.model.Action.getAll();
        let restaurantes = Model.restaurante.Action.getAll();
        let horario = Model.horario.Action.getAll();
        let pack = Model.pack.Action.getAll();
        let detallePedido = [];

        // Object.values(pedidos).map(obj => {
        //     let detalle = Parent.model.Action.getDetalle({ pk: obj.pk });
        //     detallePedido.push(detalle);
        // })

        if (!usuarios || !pedidos || !restaurantes || !horario || !pack || !detallePedido) return null;

        return Object.values(pedidos).map(obj => {
            obj.usuario = usuarios[obj.key_usuario]
            obj.driver = usuarios[obj.key_conductor]
            obj.pack = pack[obj.key_pack];
            obj.horario = horario[obj?.pack?.key_horario]
            obj.restaurante = restaurantes[obj?.horario?.key_restaurante]
            obj.key_pedido = obj.key;
            obj.hora_pedido = obj.fecha_on;
            // Columnas calculadas
            obj.total_tapekes = obj.cantidad * parseFloat(obj.precio);

            obj.comision_restaurate_venta = obj.total_tapekes - obj.comision_restaurante;

            obj.comision_driver = obj.delivery - obj.comision_delivery;

            obj.pago_cliente_delivery = (obj.delivery + parseFloat(this.totalIncentivos(obj?.incentivos)) - parseFloat(obj?.total_descuento_delivery ?? 0));

            obj.total_cliente_pagado = obj.total_tapekes + obj.pago_cliente_delivery;

            return obj;
        })
    }
}
export default connect(index);