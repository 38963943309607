import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Reportes Operaciones'}>
                <SHr height={32} />

                <MenuPages path={"/reporte/operaciones"}  >
                    <MenuButtom label='CANTIDAD DE CANCELADOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/cantidad_pedidos_cancelado", evt);
                        });
                    }} />

                    <MenuButtom label='PEDIDOS NO RECOGIDOS O CANCELADOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/pedidos_no_recogidos", evt);
                        });
                    }} />

                    <MenuButtom label='CANTIDAD DE OFERTAS DE CARRERAS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/cantidad_de_ofertas_de_carreras", evt);
                        });
                    }} />

                    <MenuButtom label='TIEMPO PROMEDIO DE COMPRAS ANTICIPADAS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/tiempo_promedio_compras_anticipadas", evt);
                        });
                    }} />

                    <MenuButtom label='RENTABILIDAD DE CARRERAS' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/driver", {
                            onSelect: (obj) => {
                                if (!obj) return;
                                SPopup.dateBetween("Selecciona las fechas", (evt) => {
                                    evt.key_driver = obj.key;
                                    SNavigation.navigate("/reporte/operaciones/rentabilidad_de_carreras", evt);
                                });
                                return true;
                            }
                        })
                    }} />

                    <MenuButtom label='INGRESOS POR DRIVER' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/driver", {
                            onSelect: (obj) => {
                                if (!obj) return;
                                SPopup.dateBetween("Selecciona las fechas", (evt) => {
                                    evt.key_driver = obj.key;
                                    SNavigation.navigate("/reporte/operaciones/ingresos_por_driver", evt);
                                });
                                return true;
                            }
                        })
                    }} />

                    <MenuButtom label='COSTO PROMEDIO DE ENTREGA / DISTANCIA POR DRIVER' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/costo_promedio_entrega_distancia", evt);
                        });
                    }} />

                    <MenuButtom label='CANTIDAD DE DRIVERS CON INCENTIVO ' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/operaciones/cantidad_driver_con_incentivo", evt);
                        });
                    }} />

                    <MenuButtom label='REPORTES GPXs' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/operaciones/reportes_gpx");
                    }} />

                </MenuPages>
                <SHr height={32} />
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);