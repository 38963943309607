import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SText } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket'

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado"]
        });

        this.state = {
            company: null,
        };
    }

    componentDidMount() {
        Parent.model.Action.CLEAR();

        SSocket.sendPromise({
            component: "company_patrocinado",
            type: "getAll",
        }).then(e => {
            this.setState({ company: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var inp = super.$inputs();
        inp["key_company"].onPress = (val) => {
            SNavigation.navigate("/company", {
                filter: (obj) => {
                    if (!obj) return;
                    if (this.state?.company) {
                        let item = Object.values(this.state?.company).find(a => a.key_company == obj.key && a.estado > 0)
                        if (item) {
                            return false
                        }
                    }
                    return true;
                },

                onSelect: (val) => {
                    if (!val) return;
                    this.setState({ company: val })
                }
            })
        }

        inp["key_company"].value = this.state.company ? this.state.company.nombre : null;
        return inp;
    }

    $onSubmit(data) {
        if (!this.state.company) {
            SPopup.alert("Seleccione un company");
            return;
        }

        data.key_company = this.state.company.key;

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);