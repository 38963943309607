import { SPage } from "servisofts-component";
import _new from "./new";
import list from "./list";
import edit from "./edit";

import Model from "../../../Model";

const model = Model.company_patrocinado;
export const Parent = {
    name: "company_patrocinado",
    path: `/marketing/company_patrocinado`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "new": _new,
    "edit": edit,
})
