import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import { SText, SView, STheme, SDate, SHr, SNavigation, SLoad, SList, SIcon, SPopup } from 'servisofts-component';
import item from '../item';
import DescuentosHijos from './Components/DescuentosHijos';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil Descuento",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            item: item
        });
        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.descuento_restaurante.Action.CLEAR()
        Model.descuento_tag.Action.CLEAR()
    }

    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $footer() {
        return <MenuPages path='/descuento/profile/' params={{ pk: this.pk }}>
            <MenuButtom label='Rangos' url='/descuento/profile/rangos' params={{ pk: this.pk }} icon={<SIcon name='Ajustes' />} />
            <MenuButtom label='Restaurantes' url='/descuento/profile/restaurantes' params={{ pk: this.pk }} icon={<SIcon name='Ajustes' />} />
            <MenuButtom label='Tags' url='/descuento/profile/tags' params={{ pk: this.pk }} icon={<SIcon name='Ajustes' />} />
            <MenuButtom label='Producto' url='/descuento/profile/producto' params={{ pk: this.pk }} icon={<SIcon name='Ajustes' />} />
        </MenuPages>
    }
}
export default connect(index);