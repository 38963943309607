import React, { useRef } from 'react';
import Svg, { Line } from 'react-native-svg';
import { SButtom } from 'servisofts-component';

export default function Test() {
    const svgRef = useRef();

    const handlePress = async () => {
        svgRef.toDataURL((dataUrl) => {});
        svgRef.current.
        console.log(svgRef);
        // svgRef.current.toDataURL((dataUrl) => {
        //     console.log(dataUrl);
        // });
    };

    return (
        <>
            <Svg ref={svgRef} width="100" height="100" viewBox="0 0 200 200" fill="none">
                <Line x1="0" y1="0" x2="100" y2="100" stroke="red" strokeWidth="2" />
                <Line x1="100" y1="0" x2="0" y2="100" stroke="red" strokeWidth="2" />
            </Svg>
            <SButtom onPress={handlePress}>
                Download
            </SButtom>
        </>
    );
}