import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SForm, SHr, SIcon, SInput, SList, SLoad, SNavigation, SPage, SPopup, SSwitch, SText, STheme, SView } from 'servisofts-component';
import Container from '../../Components/Container';
import Model from '../../Model';

class root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key_zona: ""
        };
    }

    componentDidMount() {

    }
    loadData() {
        this.zonas = Model.zona.Action.getAll();
        this.data = Model.incentivo.Action.getAll();
        if (!this.data || !this.zonas) return false;
        return true;
    }



    handleChange_dia_especial(data, val) {
        if (!this.allowNew) {
            SPopup.alert("No tienes permisos para esta acción.")
            return;
        }
        console.log(data);
        if (val) {
            SPopup.openContainer({
                render: (props) => {
                    return <SView col={"xs-11"} center >
                        <SHr h={30} />
                        <SText bold>Crear un incentivo de tipo dia especial</SText>
                        <SHr />
                        <SForm inputs={{
                            monto: { label: "Monto", type: "money", required: true },
                            monto_driver: { label: "Monto para el driver", type: "money", required: true }
                        }}
                            onSubmitName={"ACEPTAR"}
                            onSubmit={(val) => {
                                props.close();
                                this.setState({ loading: true })
                                Model.incentivo.Action.registro({
                                    data: {
                                        tipo: "dia_especial",
                                        monto: val.monto,
                                        monto_driver: val.monto_driver,
                                        key_zona: this.state.key_zona,
                                        fecha_inicio: new SDate().toString("yyyy-MM-dd hh:mm:ss"),
                                    },
                                    key_usuario: Model.usuario.Action.getKey()
                                }).then(e => {
                                    this.setState({ loading: false })
                                }).catch(e => {
                                    this.setState({ loading: false })
                                })
                            }}
                        />
                        <SHr h={30} />
                    </SView>
                }
            })

        } else {
            SPopup.confirm({
                title: "Esta seguro de desactivar el modo dia especial?",
                onPress: () => {
                    this.setState({ loading: true })
                    Model.incentivo.Action.editar({
                        data: {
                            ...data,
                            fecha_fin: new SDate().toString("yyyy-MM-dd hh:mm:ss"),
                            estado: 0,
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    }).then(e => {
                        this.setState({ loading: false })
                    }).catch(e => {
                        this.setState({ loading: false })
                    })
                }
            })
        }
        // console.log(val)
    }
    handleChange_lluvia(data, val) {
        if (!this.allowNew) {
            SPopup.alert("No tienes permisos para esta acción.")
            return;
        }
        console.log(data);
        if (val) {
            SPopup.openContainer({
                render: (props) => {
                    return <SView col={"xs-11"} center >
                        <SHr h={30} />
                        <SText bold>Crear un incentivo de tipo lluvia</SText>
                        <SHr />
                        <SForm inputs={{
                            monto: { label: "Monto", type: "money", required: true },
                            monto_driver: { label: "Monto para el driver", type: "money", required: true }
                        }}
                            onSubmitName={"ACEPTAR"}
                            onSubmit={(val) => {
                                props.close();
                                this.setState({ loading: true })
                                Model.incentivo.Action.registro({
                                    data: {
                                        tipo: "lluvia",
                                        monto: val.monto,
                                        monto_driver: val.monto_driver,
                                        key_zona: this.state.key_zona,
                                        fecha_inicio: new SDate().toString("yyyy-MM-dd hh:mm:ss"),
                                    },
                                    key_usuario: Model.usuario.Action.getKey()
                                }).then(e => {
                                    this.setState({ loading: false })
                                }).catch(e => {
                                    this.setState({ loading: false })
                                })
                            }}
                        />
                        <SHr h={30} />
                    </SView>
                }
            })

        } else {
            SPopup.confirm({
                title: "Esta seguro de desactivar el modo lluvia?",
                onPress: () => {
                    this.setState({ loading: true })
                    Model.incentivo.Action.editar({
                        data: {
                            ...data,
                            fecha_fin: new SDate().toString("yyyy-MM-dd hh:mm:ss"),
                            estado: 0,
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    }).then(e => {
                        this.setState({ loading: false })
                    }).catch(e => {
                        this.setState({ loading: false })
                    })
                }
            })
        }
        // console.log(val)
    }


    render_detalle(obj) {
        if (!obj) return null;
        return <SView col={"xs-12"}>
            <SText color={STheme.color.gray}>Monto: {obj.monto}</SText>
            <SText color={STheme.color.gray}>Monto para el driver: {obj.monto_driver}</SText>
            <SText color={STheme.color.gray}>Inicio el {new SDate(obj.fecha_on).toString("DAY, dd de MONTH del yyyy a las hh:mm")}</SText>

        </SView>
    }
    render_con_zona() {
        if (!this.state.key_zona) return null;
        let data_zona = Object.values(this.data).filter(obj => obj.key_zona == this.state.key_zona && obj.estado > 0);
        let lluvia = data_zona.find(a => a.tipo == "lluvia");
        return <SView col={"xs-12"}>
            <SView col={"xs-12"} center>
                <SView col={"xs-12"} row center>
                    <SView width={40} height={40} center padding={2}>
                        <SView card flex col={"xs-12"}>
                            <SIcon name='ModoLluvia' />
                        </SView>
                    </SView>
                    <SView width={8} />
                    <SText fontSize={18} bold>{"Modo lluvia"}</SText>
                    <SView flex />
                    <SSwitch key={this.state.key_zona} size={30} loading={this.state.loading} onChange={this.handleChange_lluvia.bind(this, lluvia)} value={!!lluvia} />
                </SView>
                <SHr />
                {this.render_detalle(lluvia)}
                <SHr />
                <SView col={"xs-12"} height={2} backgroundColor={STheme.color.card} />
                <SHr />
            </SView>

        </SView>
    }
    render_dia_festivo() {
        if (!this.state.key_zona) return null;
        let data_zona = Object.values(this.data).filter(obj => obj.key_zona == this.state.key_zona && obj.estado > 0);
        let incentivo = data_zona.find(a => a.tipo == "dia_especial");
        return <SView col={"xs-12"}>
            <SView col={"xs-12"} center>
                <SView col={"xs-12"} row center>
                    <SView width={40} height={40} center padding={2}>
                        <SView card flex col={"xs-12"}>
                            <SIcon name='ModoDiaEspecial' />
                        </SView>
                    </SView>
                    <SView width={8} />
                    <SText fontSize={18} bold>{"Modo día especial"}</SText>
                    <SView flex />
                    <SSwitch key={this.state.key_zona} size={30} loading={this.state.loading} onChange={this.handleChange_dia_especial.bind(this, incentivo)} value={!!incentivo} />
                </SView>
                <SHr />
                {this.render_detalle(incentivo)}
                <SHr />
                <SView col={"xs-12"} height={2} backgroundColor={STheme.color.card} />
                <SHr />
            </SView>

        </SView>
    }
    renderContent() {
        if (!this.loadData()) return <SLoad />
        return <SView col={"xs-12"} center>
            <SHr height={50} />
            <SInput type={"select"}
                value={this.state.key_zona}
                ref={ref => this.zona_input = ref}
                onChangeText={(val) => {
                    this.setState({ key_zona: val })
                }}
                options={[
                    { key: "", content: <SText color={STheme.color.danger}>{"Seleeciona una zona"}</SText> }
                    , ...Object.values(this.zonas).map(zona => { return { key: zona.key, content: zona.nombre } })]} />
            {/* <SText fontSize={18} bold>{"Presione sobre los switch para activar los incentivos."}</SText> */}
            <SHr height={50} />
            {this.render_con_zona()}
            {this.render_dia_festivo()}
            <SHr />

        </SView>
    }
    render() {
        this.allowNew = Model.usuarioPage.Action.getPermiso({ url: "/incentivo", permiso: "new", loading: false });
        return (
            <SPage
                title={"Incentivos"}
                onRefresh={(resolve) => {
                    resolve()
                }}>
                <Container>
                    {this.renderContent()}
                </Container>
            </SPage>
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(root);