import { SPage } from "servisofts-component";
import root from "./root";
import encryptar_tarjetas from "./encryptar_tarjetas"

export default SPage.combinePages("devtool", {
    "": root,
    encryptar_tarjetas


})
