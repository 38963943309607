import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from '../../../Components/Container';
import Model from '../../../Model';
import { SDate, SHr, SList, SLoad, SMath, SNavigation, SInput, SPopup, SPage, SText, STheme, SView, SIcon, SExcel, SButtom } from 'servisofts-component';
import Btn from '../../../Components/Btn';
import ConciliarPopup from "./Components/ConciliarPopup"
import Pedido_item from './Components/Pedido_item';
import SSocket from 'servisofts-socket';
class index extends Component {
    constructor(props, { }) {
        super(props);
        this.state = {
            fecha: new SDate().toString("yyyy-MM-dd")
        };
        this.key_restaurante = SNavigation.getParam("key_restaurante");
    }

    componentDidMount() {
        Model.pedido.Action.getPendientesConciliacion({ key_restaurante: this.key_restaurante }).then((resp) => {

            this.setState({ pendientes: resp.data, ultima_conciliacion: resp.ultima_conciliacion })
            this.state.pendientes = resp.data;
            this.getArrayFinalFechaFilter();
        }).catch(e => {
            console.error(e)
        })

        SSocket.sendPromise({
            "version": "1.0",
            "component": "restaurante",
            "type": "getAll",
            "estado": "cargando"
        }).then(e => {
            this.setState({ restaurante: e.data[this.key_restaurante] })
        })
    }

    getArrayFinalFechaFilter() {
        if (!this.state.pendientes) return;
        this.pedidoPorConciliarFilterDate = null;
        let datenow = new SDate(this.state.fecha).toString("yyyy-MM-dd");
        this.pedidoPorConciliarFilterDate = Object.values(this.state.pendientes).filter(a => {
            let fechaPedido = new SDate(a.fecha).toString("yyyy-MM-dd");
            if (fechaPedido <= datenow) {
                return a;
            }
        });
    }

    calcularMontos() {
        let total = {
            efectivo: 0,
            linea: 0,
            comision_efectivo: 0,
            comision_linea: 0,
            total: 0,

            totalComisionEfectivo: 0,
            totalComisionLinea: 0,

            cantTapDel: 0,
            montoIngTapDel: 0,

            cantProdDel: 0,
            montoIngProdDel: 0,

            cantTapRecoger: 0,
            montoIngTapRecoger: 0,

            cantProdRecoger: 0,
            montoIngProdRecoger: 0,

            totalDesc: {
                totalDescCubreTapeke: 0,
                totalDescCubrePartner: 0,
            },

            totalDescCubreTapeke: 0,
            totalDescCubrePartner: 0,

            totalDescEfectivo: 0,
            totalDescLinea: 0,

            totalDescProducto: 0,
            totalDescDelivery: 0,
            totalDescuento: 0,

            totalPorConciliar: 0
        }

        const calcularTotalProdYSub = (obj) => {
            let totalProd = 0;
            if (obj.pedido_producto) {
                Object.values(obj.pedido_producto).map((prod) => {
                    if (prod.precio_sin_descuento) {
                        totalProd += (prod.cantidad * prod.precio_sin_descuento)
                    } else {
                        totalProd += (prod.cantidad * prod.precio)
                    }

                    if (prod.sub_productos) {
                        Object.values(prod.sub_productos).map((sub) => {
                            if (sub.sub_producto_detalle) {
                                Object.values(sub.sub_producto_detalle).map((subDet) => {
                                    totalProd += (subDet.cantidad * subDet.precio)
                                })
                            }
                        })
                    }
                })
            }
            return totalProd;
        }

        const contadorProd = (obj) => {
            let cantidad = 0;
            if (obj.pedido_producto) {
                Object.values(obj.pedido_producto).map((prod) => {
                    cantidad += 1;
                })
            }
            return cantidad;
        }

        this.keys_pedidos = [];
        let totalDesc = this.calcularDescuentoCobertura(null);

        this.pedidoPorConciliarFilterDate?.map(obj => {
            // Object.values(this.state?.data).map(obj => {

            totalDesc = this.calcularDescuentoCobertura(obj);
            total.totalDescCubreTapeke += totalDesc.totalDescCubreTapeke;
            total.totalDescCubrePartner += totalDesc.totalDescCubrePartner;

            if (obj.delivery > 0) {
                total.cantTapDel += obj.cantidad;
                total.montoIngTapDel += obj.cantidad * obj.precio;

                total.cantProdDel += contadorProd(obj);
                total.montoIngProdDel += calcularTotalProdYSub(obj);
            } else {
                total.cantTapRecoger += obj.cantidad;
                total.montoIngTapRecoger += obj.cantidad * obj.precio;

                total.cantProdRecoger = contadorProd(obj);
                total.montoIngProdRecoger += calcularTotalProdYSub(obj);
            }

            if (!obj.tipo_pago) return;

            if (obj.tipo_pago.find(a => a.type == "efectivo")) {
                total.efectivo += ((obj.cantidad * obj.precio) + calcularTotalProdYSub(obj));
                total.comision_efectivo += obj.comision_restaurante;
                totalDesc = this.calcularDescuentoCobertura(obj);
                total.totalDescEfectivo += (totalDesc.totalDescCubreTapeke ?? 0) + (totalDesc.totalDescCubrePartner ?? 0);
                total.totalComisionEfectivo += obj.comision_restaurante;
            } else {
                total.linea += ((obj.cantidad * obj.precio) + calcularTotalProdYSub(obj));
                total.comision_linea += obj.comision_restaurante;
                totalDesc = this.calcularDescuentoCobertura(obj);
                total.totalDescLinea += totalDesc.totalDescCubreTapeke + totalDesc.totalDescCubrePartner;
                total.totalComisionLinea += obj.comision_restaurante;
            }

            total.totalDescProducto += obj.total_descuento_producto;
            total.totalDescDelivery += obj.total_descuento_delivery;
            total.totalDescuento = total.totalDescProducto + total.totalDescDelivery;
            total.total += ((obj.cantidad * obj.precio) + calcularTotalProdYSub(obj));

            this.keys_pedidos.push(obj.key);
        })

        total.totalDescCubrePartner = total.totalDescCubrePartner - (total.totalDescEfectivo * totalDesc.porcentajeCubrePartner);


        // total.totalPorConciliar = total.linea + total.totalDescCubreTapeke - (total.totalDescCubrePartner + total.comision_linea + total.comision_efectivo);

        // TODO Calculo aprobado por la contadora
        total.totalPorConciliar = total.linea - (total.comision_linea + total.comision_efectivo + total.totalDescCubrePartner);

        return total;
    }

    calcularDescuentoCobertura(obj) {
        let totalDesc = {
            totalDescCubreTapeke: 0,
            totalDescCubrePartner: 0,
            porcentajeCubreTapeke: 0,
            porcentajeCubrePartner: 0,
        };


        if (obj?.descuentos) {
            Object.values(obj.descuentos).map((desc) => {
                if (desc.cobertura) {
                    let coberturaTapeke = desc.total_descuento_producto * (desc.cobertura ?? 0);
                    let coberturaPartner = desc.total_descuento_producto - coberturaTapeke;

                    // TODO implementacion de descuentos delivery que cubre partner.

                    totalDesc.totalDescCubreTapeke += parseFloat(coberturaTapeke, 2);
                    totalDesc.totalDescCubrePartner += parseFloat(coberturaPartner, 2);
                    totalDesc.porcentajeCubreTapeke = desc.cobertura;
                    totalDesc.porcentajeCubrePartner = 1 - desc.cobertura;
                }
            });
        }

        // TODO aca va que este tipo de descuento lo cubre el partner.
        if (obj?.pedido_producto) {
            // TODO Configuración por si se quiere excluir algun restaurante de la configuración. 
            const excludeRestaurantes = [] // Ej. = ['pollos campeón', 'sakura brasas']
            const excludePedidos = [] // Ej. = ["70e831e6-9ada-4bd8-84f7-53e0ce958f60"]

            Object.values(obj.pedido_producto).map((prod) => {
                if (prod.descuento_monto || prod.descuento_porcentaje) {
                    // TODO Se define el porcentaje que cubre Tapeke según lo que pida administración.
                    let coberturaTapeke = 1;

                    if (
                        excludeRestaurantes.some(nombre => obj.restaurante.nombre.toLowerCase().includes(nombre.toLowerCase()))
                        ||
                        excludePedidos.some(pk => prod.key_pedido.includes(pk))
                    ) {
                        coberturaTapeke = 1;
                    }

                    let coberturaPartner = 1 - coberturaTapeke;
                    let monto = (prod.cantidad * (prod.precio_sin_descuento - prod.precio))

                    totalDesc.totalDescCubreTapeke += monto * coberturaTapeke;
                    totalDesc.totalDescCubrePartner += monto * coberturaPartner
                } else {
                    if (prod.precio_sin_descuento) {
                        totalDesc.totalDescCubreTapeke += (prod.cantidad * (prod.precio_sin_descuento - prod.precio))
                    }
                }
            })
        }

        return totalDesc;
    }

    head({ cantidadTotal }) {
        return <SView col={"xs-12"} row center card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText color={STheme.color.gray} fontSize={16} bold>{this.state.fecha} </SText>
            <SHr />
            <SView flex height={2} />
            <SText color={STheme.color.text} bold fontSize={30}>Bs. {SMath.formatMoney(cantidadTotal)}</SText>
            <SHr height={10} />
            <SText color={STheme.color.text} fontSize={16} bold>TOTAL INGRESOS</SText>
            <SHr height={10} />
        </SView>
    }

    cardComodin({ title, titleLeft, labelLeft, titleRight, labelRight }) {
        return <SView col={"xs-12"} row center
            card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText fontSize={20} color={STheme.color.gray} bold>{title}</SText>
            <SHr height={5} />
            <SView height={2} col={"xs-10"} style={{
                borderBottomColor: STheme.color.gray,
                borderBottomWidth: 2
            }} />
            <SHr height={5} />
            <SView col={"xs-6"} row center
                style={{
                    borderRightColor: STheme.color.gray,
                    borderRightWidth: 2
                }}
            >
                <SText fontSize={14} color={STheme.color.text} center bold>{titleLeft}</SText>
                <SHr />
                <SText fontSize={20} color={STheme.color.text}>Bs. </SText>
                <SText fontSize={20} bold color={STheme.color.text}>{SMath.formatMoney(labelLeft)}</SText>
                <SHr />
            </SView>
            <SView col={"xs-6"} row center>
                <SText fontSize={14} color={STheme.color.text} bold>{titleRight}</SText>
                <SHr />
                <SText fontSize={20} color={STheme.color.text}>Bs. </SText>
                <SText fontSize={20} bold color={STheme.color.text}>{SMath.formatMoney(labelRight)}</SText>
                <SHr />
            </SView>
            <SHr height={15} />
        </SView>
    }

    cardDetalle({ col, title, cantTap, montoIngTap, cantProd, montoIngProd }) {

        const subCard = ({ label, cantidad, monto }) => {
            let fontSizeTitle = 12;
            let fontSizeLabel = 14;
            return <>
                <SHr height={10} />
                <SView row center>
                    <SText fontSize={fontSizeTitle} color={STheme.color.text} width={50} center bold>{label}</SText>
                    <SHr />
                    <SText fontSize={fontSizeLabel} bold color={STheme.color.text}>{cantidad}</SText>
                    <SHr />
                </SView>

                <SView row center>
                    <SText fontSize={fontSizeTitle} color={STheme.color.text} bold>INGRESOS</SText>
                    <SHr />
                    <SText fontSize={fontSizeLabel} color={STheme.color.text}>Bs. </SText>
                    <SText fontSize={fontSizeLabel} bold color={STheme.color.text}>{SMath.formatMoney(monto)}</SText>
                    <SHr />
                </SView>
            </>
        }

        return <>
            <SView col={col} row center
                card
                style={{
                    borderRadius: 8
                }}
            >
                <SHr height={10} />
                <SText fontSize={15} style={{
                    color: STheme.color.text,
                    borderBottomWidth: 1,
                    borderColor: STheme.color.lightGray,
                }} bold>
                    {title}
                </SText>

                {subCard({ label: "CANTIDAD TAPEKES", cantidad: cantTap, monto: montoIngTap })}
                {subCard({ label: "CANTIDAD PRODUCTOS", cantidad: cantProd, monto: montoIngProd })}
            </SView>
        </>
    }

    cardsDetalle({
        cantTapDel,
        montoIngTapDel,
        cantProdDel,
        montoIngProdDel,
        cantTapRecoger,
        montoIngTapRecoger,
        cantProdRecoger,
        montoIngProdRecoger
    }) {
        return <SView col={"xs-12"} row center>

            {this.cardDetalle({
                col: "xs-6",
                title: "Delivery",
                cantTap: cantTapDel,
                montoIngTap: montoIngTapDel,
                cantProd: cantProdDel,
                montoIngProd: montoIngProdDel
            })}

            {this.cardDetalle({
                col: "xs-6",
                title: "Recoger del lugar",
                cantTap: cantTapRecoger,
                montoIngTap: montoIngTapRecoger,
                cantProd: cantProdRecoger,
                montoIngProd: montoIngProdRecoger
            })}
        </SView>
    }

    labelGanancia({ label, value, color, simbolo }) {
        return <>
            <SHr height={5} />
            <SView col={"xs-10"} row center >
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-start' }}>
                    <SText fontSize={13} color={STheme.color.text} center >{label}</SText>
                </SView>
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-end', }} >
                    <SText fontSize={13} color={!color ? STheme.color.text : color} center >{simbolo ? simbolo : ""} Bs. {SMath.formatMoney(value)}</SText>
                </SView>
            </SView>
            <SView height={2} col={"xs-10"} style={{
                borderBottomColor: STheme.color.lightGray,
                borderBottomWidth: 1
            }} />
            <SHr height={15} />
        </>
    }

    ganancias({ total_ingresos, linea, efectivo, comision_tapeke_efectivo, comision_tapeke_linea, deuda, descuento_cubre_tapeke, descuento_cubre_partner, total_por_conciliar }) {
        return <SView col={"xs-12"} row center
            card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText fontSize={20} color={STheme.color.gray} bold>GANANCIAS</SText>
            <SHr height={2} color={STheme.color.gray} />
            <SHr height={5} />

            {this.labelGanancia({ label: "Total Ingresos", value: total_ingresos, color: STheme.color.text })}

            <SText fontSize={15} color={STheme.color.gray} bold>Detalle Pago</SText>

            {this.labelGanancia({ label: "Ingresos en Efectivo", value: efectivo, color: STheme.color.danger, simbolo: "-" })}

            {this.labelGanancia({ label: "Ingresos en Linea", value: linea, color: STheme.color.success })}

            {this.labelGanancia({ label: "Descuento cubre Tapeke", value: descuento_cubre_tapeke, color: STheme.color.danger, simbolo: "-" })}

            {this.labelGanancia({ label: "Descuento cubre Partner", value: descuento_cubre_partner, color: STheme.color.success })}

            {this.labelGanancia({ label: `Comision Tapeke Efectivo`, value: comision_tapeke_efectivo, color: STheme.color.success })}

            {this.labelGanancia({ label: `Comision Tapeke Linea`, value: comision_tapeke_linea, color: STheme.color.success })}

            {this.labelGanancia({ label: `Total`, value: -1 * total_por_conciliar })}

            <SHr height={15} />
        </SView>
    }

    getTableDetail(totales) {
        if (!this.state.pendientes) return <SLoad />

        let total = totales;

        this.deuda = parseFloat((total.linea) - (total.comision_linea + total.comision_efectivo)).toFixed(2);

        return <SView col={"xs-12"} >
            <SHr />
            <SText>Última conciliación: {new SDate(this.state?.ultima_conciliacion?.fecha_cierre).toString("yyyy-MM-dd")}</SText>
            <SText># Pedidos por Conciliar: {this.pedidoPorConciliarFilterDate?.length}</SText>
            <SHr />

            {this.head({ cantidadTotal: total.total })}

            <SHr height={10} />
            {/* {this.cardsDetalle({ cantidadDelivery: total.cantidad_delivery, gananciaDelivery: total.total_delivery, cantidadRecoger: total.cantidad_recoger, gananciaRecoger: total.total_recoger })} */}
            {this.cardsDetalle({
                cantTapDel: total.cantTapDel,
                montoIngTapDel: total.montoIngTapDel,
                cantProdDel: total.cantProdDel,
                montoIngProdDel: total.montoIngProdDel,
                cantTapRecoger: total.cantTapRecoger,
                montoIngTapRecoger: total.montoIngTapRecoger,
                cantProdRecoger: total.cantProdRecoger,
                montoIngProdRecoger: total.montoIngProdRecoger
            })}

            <SHr height={10} />
            {this.cardComodin({ title: "Pagos", titleLeft: "Efectivo", labelLeft: total.efectivo, titleRight: "Linea", labelRight: total.linea })}

            <SHr height={10} />
            {this.cardComodin({ title: "Descuentos", titleLeft: "Efectivo", labelLeft: total.totalDescEfectivo, titleRight: "Linea", labelRight: total.totalDescLinea })}
            {this.cardComodin({ title: "", titleLeft: "Cubre Tapeke", labelLeft: total.totalDescCubreTapeke, titleRight: "Cubre Partner", labelRight: total.totalDescCubrePartner })}

            <SHr height={10} />
            {this.ganancias({
                total_ingresos: total.total,
                linea: total.linea,
                efectivo: total.efectivo,
                comision_tapeke_efectivo: total.totalComisionEfectivo,
                comision_tapeke_linea: total.totalComisionLinea,
                deuda: this.deuda,
                descuento_cubre_tapeke: total.totalDescCubreTapeke,
                descuento_cubre_partner: total.totalDescCubrePartner,
                total_por_conciliar: total.totalPorConciliar
            })}

            {console.log(total)}



            <SHr height={10} />
            {this.buttonExcel()}

        </SView>
    }

    buttonExcel() {
        return <Btn
            onPress={() => {
                SNavigation.navigate("/conciliacion/restaurante/tablaPedido", { conciliado: false, key_restaurante: this.key_restaurante, fecha_fin: this.state.fecha });
            }}
        >Ver tabla pedidos a conciliar</Btn>
    }

    render_lista_pedidos() {
        if (!this.state.ver_pedidos) return null;
        return <>
            <SText fontSize={16}>Pedidos pendientes de conciliación: {this.pedidoPorConciliarFilterDate?.length}</SText>
            <SHr />
            <SList
                data={this.pedidoPorConciliarFilterDate}
                limit={20}
                order={[{ key: "fecha", order: "desc", peso: 1, }]}
                render={(obj) => <Pedido_item data={obj} />}
            />
        </>
    }


    render() {
        let totales = this.calcularMontos()

        if (!this.state.restaurante && !this.state.pendientes) return <SLoad />

        return (
            <SPage title={'Conciliar'} onRefresh={res => {
                this.componentDidMount();
            }}>
                <Container >
                    <SHr />
                    <SText center fontSize={30}>{this.state.restaurante?.nombre}</SText>
                    <SHr />
                    <SText>Para conciliar, debe seleccionar la fecha hasta donde se conciliarán los pedidos.</SText>
                    <SHr />
                    <SInput type={"date"} value={this.state.fecha} width={110} onChangeText={(val) => {
                        if (new SDate(val, "yyyy-MM-dd").isAfter(new SDate())) {
                            SPopup.alert("No puedes seleccionar una fecha mayor a hoy.")
                            return;
                        }
                        this.state.fecha = val;
                        this.getArrayFinalFechaFilter()
                        this.setState({ fecha: val })
                    }} />
                    <SHr />
                    {this.getTableDetail(totales)}
                    <SHr />
                    <Btn onPress={() => {
                        ConciliarPopup.open({
                            key_restaurante: this.key_restaurante,
                            monto: totales.totalPorConciliar,
                            fecha: this.state.fecha,
                            keys_pedidos: this.keys_pedidos
                        });
                    }} color={STheme.color.success}>Conciliar</Btn>
                    <SHr height={32} />
                    <Btn onPress={() => {
                        this.setState({
                            ver_pedidos: !this.state.ver_pedidos
                        })
                    }}>{!this.state.ver_pedidos ? `Mostrar pedidos` : `Ocultar pedidos`}</Btn>
                    <SHr />
                    {this.render_lista_pedidos()}
                    <SHr h={30} />
                </Container>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);