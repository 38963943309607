import { SPage } from "servisofts-component";
import root from "./root.js";
import form from "./form.js"


export default SPage.combinePages("recompensa",
    {
        "": root,
        form
    }
)

