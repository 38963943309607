import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import { SView, SHr, STheme, SText, SDate } from 'servisofts-component';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado"],
            // item: item
        });
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $item() {
        return <>
            <SView col={"xs-12"} row center>

                <SView style={{
                    borderRadius: 8,
                    backgroundColor: STheme.color.card,
                }} center >
                    <SText fontSize={16} center style={{
                        padding: 4,
                        color: this.isDescuentoActivo(this.data?.fecha_fin) ? STheme.color.success : STheme.color.danger
                    }}>{this.isDescuentoActivo(this.data?.fecha_fin) ? "Este descuento esta activo" : "Este descuento esta Inactivo"}</SText>
                </SView>

                <SView width={25} height={25} backgroundColor={this.isDescuentoActivo(this.data?.fecha_fin) ? STheme.color.success : STheme.color.danger} style={
                    {
                        borderRadius: 100,
                        marginLeft: 20
                    }
                } />

            </SView>
            <SHr />
            <SHr height={1} color='#666' />
            <SHr />
            <SView col={"xs-12"} row center>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SHr h={10} />
                        <SText fontSize={16}>{`Fecha Inicio:  ${new SDate(this.data?.fecha_inicio, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16} style={{
                            color: this.isDescuentoActivo(this.data?.fecha_fin) ? STheme.color.success : STheme.color.danger
                        }}>{`Fecha Fin:  ${new SDate(this.data?.fecha_fin, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Porcentaje:  ${(this.data?.porcentaje * 100) + " %" ?? "No se pillo porcentaje"}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Descripción:  ${this.data?.descripcion ? this.data?.descripcion : "No hay descripción"}`}</SText>

                    </SView>
                </SView>
            </SView>
        </>
    }

    isDescuentoActivo = (FF) => {
        let hoy = new SDate().toString("yyyy-MM-dd");
        let fechaFin = new SDate(FF, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd");

        if (fechaFin >= hoy) {
            return true
        }
        return false
    }
}
export default connect(index);