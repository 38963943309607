var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { Text as TextReact } from "react-native";
import SStorage from "../SStorage";
var Text = /** @class */ (function (_super) {
    __extends(Text, _super);
    function Text() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            language: SLanguage.language
        };
        return _this;
    }
    Text.prototype.onChangeLanguage = function (language) {
        this.setState({ language: language });
    };
    Text.prototype.componentDidMount = function () {
        SLanguage.addListener(this.onChangeLanguage.bind(this));
    };
    Text.prototype.componentWillUnmount = function () {
        SLanguage.removeListener(this.onChangeLanguage);
    };
    Text.prototype.render = function () {
        var value = this.props[this.state.language];
        return React.createElement(TextReact, __assign({}, this.props), value);
    };
    return Text;
}(React.Component));
export { Text };
var SLanguage = /** @class */ (function (_super) {
    __extends(SLanguage, _super);
    function SLanguage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SLanguage.loadStorage = function () {
        SStorage.getItem("language", function (l) {
            if (!l)
                return;
            SLanguage.change(l);
        });
    };
    SLanguage.addListener = function (listener) {
        SLanguage.Listeners.push(listener);
    };
    SLanguage.removeListener = function (listener) {
        var index = SLanguage.Listeners.indexOf(listener);
        if (index !== -1) {
            SLanguage.Listeners.splice(index, 1);
        }
    };
    SLanguage.notifyListener = function (language) {
        SLanguage.Listeners.forEach(function (a) { return a(language); });
    };
    SLanguage.select = function (l) {
        return l[this.language];
    };
    var _a;
    _a = SLanguage;
    SLanguage.Text = Text;
    SLanguage.Listeners = [];
    SLanguage.language = "es";
    SLanguage.change = function (language) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(_a, function (_b) {
            if (SLanguage.language == language)
                return [2 /*return*/];
            SLanguage.language = language;
            SStorage.setItem("language", language);
            SLanguage.notifyListener(language);
            return [2 /*return*/];
        });
    }); };
    return SLanguage;
}(React.Component));
export default SLanguage;
