import React, { Component } from 'react';
import { SHr, SPage, SText, SList, STable3 } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Container from '../Components/Container';
export default class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getUsuarios() {
        SSocket.sendPromise({
            "version": "2.0",
            "service": "usuario",
            "component": "usuario",
            "type": "getAll",
            "estado": "cargando",
            "cabecera": "usuario_app",
            "key_usuario": ""
        }).then(e => {
            console.log("Exito size:" + (JSON.stringify(e).length))
        })
    }

    getPedidos() {

        // new Array(100).fill(0).map(() => {
        SSocket.sendPromise({
            "component": "pedido",
            "type": "getAll",
            "estado": "cargando",
        }).then(e => {
            console.log("Exito size:" + (JSON.stringify(e).length))
        }).catch(e => {
            console.log(e)
        })
        // })

    }
    getRestaurante() {
        SSocket.sendPromise({
            "component": "restaurante",
            "type": "getAll",
            "estado": "cargando",
        }).then(e => {
            console.log("Exito size:" + (JSON.stringify(e).length))
        }).catch(e => {
            console.log(e)
        })
    }
    getPedidosHttp() {
        console.log("PIDIENDO")
        SSocket.sendHttpAsync("http://192.168.2.1:30031/api", {
            "component": "pedido",
            "type": "getAll",
            "estado": "cargando",
        }).then(e => {
            console.log("Exito size:" + (JSON.stringify(e).length))
        }).catch(e => {
            console.log(e)
        })
    }
    render() {
        return (
            <SPage title={'Test'} hidden disableScroll>
                <Container >
                    <SHr h={50} />
                    <SText center padding={8} card onPress={() => {
                        this.getUsuarios()
                    }}>{"START USUARIOS"}</SText>
                    <SHr h={50} />
                    <SText center padding={8} card onPress={() => {
                        this.getRestaurante()
                    }}>{"START RESTAURANTE"}</SText>
                    <SHr h={50} />
                    <SText center padding={8} card onPress={() => {
                        this.getPedidos()
                    }}>{"START PEDIDOS"}</SText>
                    <SHr h={50} />
                    <SText center padding={8} card onPress={() => {
                        this.getPedidosHttp()
                    }}>{"START PEDIDOS HTTP"}</SText>
                </Container>
            </SPage >
        );
    }
}
