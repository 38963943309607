import { SReducer } from "servisofts-model";

export default class Reducer extends SReducer {
    extra(state: any, action: any): void {
        if (action.component == "horario") {
            if (action.type == "editar" && action.estado == "exito") {

                if(action.data.estado == 0){
                    return state;
                }

                const key_horario = action.pack.key_horario;
                Object.values(state.data).map((item: any) => {
                    if (item.key_horario == key_horario) {
                        // item.horario = action.pack.horario;
                        state.data[item.key] = {
                            ...state.data[item.key],
                            ...action.pack
                        }
                    }
                });
                return {
                    ...state
                }
            }
        }
    }
}