import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SLoad, SNavigation, SPage, STable2, SText, SPopup } from 'servisofts-component';
import Container from '../../Components/Container';
import Model from '../../Model';
import SSocket from 'servisofts-socket'
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.pk = SNavigation.getParam("pk");
    }


    componentDidMount() {
        SSocket.sendPromise({
            component: "pedido",
            type: "getMovimientos",
            key_pedido: this.pk
        }).then(e => {
            let key_usuario = new Set();
            let key_conductor = new Set();
            let administrador = new Set();

            Object.values(e.data).forEach(a => {
                if (a.key_usuario == 'TODO') {
                    console.log(e.data)
                    return
                }

                if (a.key_usuario !== null && a.key_usuario !== undefined) {
                    key_usuario.add(a.key_usuario);
                }
                if (a.data.key_conductor !== null && a.data.key_conductor !== undefined) {
                    key_conductor.add(a.data.key_conductor);
                }
                if (a.data.administrador !== null && a.data.administrador !== undefined) {
                    administrador.add(a.data.administrador);
                }
            });

            let keys = [...key_usuario, ...key_conductor, ...administrador];

            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                this.setState({ data: e.data, users: e2.data })
            }).catch(e2 => {
                this.setState({ data: e.data })
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            console.error(e);
        })
    }

    renderContent() {
        if (!this.state.data) return <SLoad />

        return <STable2
            header={[
                { key: "index", width: 50 },
                { key: "fecha_on", width: 140, order: "desc", render: (a) => new SDate(a).toString("yyyy-MM-dd hh:mm:ss") },
                { key: "action", width: 150 },
                { key: "old_state", width: 150 },
                { key: "state", width: 150 },
                { key: "state", width: 150 },
                {
                    key: "key_usuario", label: "usuario modificador", width: 200, render:
                        (key) => this.state.users[key] ? `${this.state?.users[key]?.usuario?.Nombres} ${this.state?.users[key]?.usuario?.Apellidos}` : "Servidor Tapeke"
                },
                {
                    key: "data/key_conductor", label: "driver asignado", width: 200, component: (key) =>
                        <SText
                            onPress={() => {
                                SNavigation.navigate("/usuario/profile", { pk: key })
                            }}>
                            {this.state.users[key] ? `${this.state.users[key]?.usuario?.Nombres} ${this.state.users[key]?.usuario?.Apellidos}` : ""}
                        </SText>
                },
                {
                    key: "data/administrador", label: "usuario asignador driver", width: 200, component: (key) =>
                        <SText
                            onPress={() => {
                                SNavigation.navigate("/usuario/profile", { pk: key })
                            }}>
                            {this.state.users[key] ? `${this.state.users[key]?.usuario?.Nombres} ${this.state.users[key]?.usuario?.Apellidos}` : ""}
                        </SText>
                },
            ]}
            data={this.state.data} />
    }
    render() {
        return (
            <SPage disableScroll>
                {this.renderContent()}
            </SPage>
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);