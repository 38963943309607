import SComponentContainer from "./Component/SComponentContainer";
import SGrid from "./Component/SGrid";
import SIcon from './Component/SIcon';
import SNavBar from "./Component/SNavBar";
import SNavigation from "./Component/SNavigation";
import SPage from "./Component/SPage";
import SSCrollView from "./Component/SScrollView";
import SText from "./Component/SText";
import STheme from './Component/STheme';
import SThread from "./Component/SThread";
import { SUuid } from "./Component/SUuid";
import SView from "./Component/SView";
import SStorage from './Component/SStorage';
import SScrollView2 from "./Component/SScrollView2";
import SScrollView3 from "./Component/SScrollView3";
import SScroll from "./Component/SScroll";
import { SInput } from "./Component/SInput";
import SInput2 from "./Component/SInput2";
import SInfo from "./SInfo";
import SPopup, { SPopupOpen, SPopupClose } from "./Component/SPopup";
import STable from "./Component/STable";
import STable2 from "./Component/STable2";
import STable3 from "./Component/STable3/index2";
import STable4 from "./Component/STable4";
import SForm from './Component/SForm';
import Submit from "./Component/SForm/submit";
import { SButtom } from './Component/SButtom';
import SLoad from "./Component/SLoad";
import SOrdenador from "./Component/SOrdenador";
import SDate from "./Component/SDate";
import SImage from "./Component/SImage";
import SHr from "./Component/SHr";
import SGradient from "./Component/SGradient";
import SColorPicker from "./Component/SColorPicker";
import SMapView2 from "./Component/SMapView";
import SMapView from "./Component/SMapView2";
import SExcel from "./Component/SExcel";
import SExcelReader from "./Component/SExcelReader";
import SMarker from "./Component/SMapView/SMarker";
import SList from "./Component/SList";
import SList2 from "./Component/SList2";
import Font from "./font";
import SBuscador from "./Component/SBuscador";
import SMath from "./Component/SMath";
import SPolyline from "./Component/SMapView/SPolyline";
import SLocation from "./Component/SMapView/SLocation";
import SSection from "./Component/SSection";
import ExportExcel from "./Component/STable2/ExportExcel";
import SIFechaAlert from "./Component/SInput/SInputTypes/SIFechaAlert";
import SRangeSlider from "./Component/SRangeSlider";
// import SAlvaro from "./Component/SAlvaro";
import Upload from "./Component/SForm/Upload";
import SPromise from "./Component/SPromise";
import SSwitch from "./Component/SSwitch";
import SNativeModules from "./SNativeModules";
import SNotification from "./Component/SNotification";
import SGeolocation from "./Component/SGeolocation";
import SDatePicker from "./Component/SDatePicker";
import SSPiner from "./Component/SSPiner";
import SUtil from "./Component/SUtil";
import DropFile from "./Component/SInput/SInputTypes/DropFile";
import DropFileSingle from "./Component/SInput/SInputTypes/DropFileSingle";
import SLanguage from "./Component/SLanguage";
export * from './Types';
export { SUtil, SHr, SGradient, SDate, SInfo, SOrdenador, SComponentContainer, SGrid, SIcon, SNavBar, SNavigation, SPage, SScroll, SSCrollView, SScrollView2, SScrollView3, SText, STheme, SThread, SRangeSlider, SUuid, SImage, SList, SList2, SIFechaAlert, SBuscador, SView, SLoad, SDatePicker, SStorage, SPopup, SPopupOpen, SPopupClose, STable, STable2, STable3, STable4, SInput, SInput2, SSwitch, SForm, Submit, SButtom, SMath, SSection, Upload, SExcel, DropFile, DropFileSingle, SExcelReader, ExportExcel, SColorPicker, SPromise, SSPiner, SLanguage, 
// MAPA
SMapView, SMapView2, SGeolocation, SMarker, SPolyline, SLocation, 
// FUENTES
Font, SNativeModules, SNotification,
//alvaro
// SAlvaro
 };
