import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Reporte'}>
                <SHr height={32} />
                <MenuPages path={"/reporte/"}  >

                    <MenuButtom label='Reportes App' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/app");
                    }} />

                    <MenuButtom label='Reportes Clientes' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/cliente");
                    }} />

                    <MenuButtom label='Reportes Comercios' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/comercio");
                    }} />

                    <MenuButtom label='Reportes Operaciones' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/operaciones");
                    }} />

                </MenuPages>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);