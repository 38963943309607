import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '..'
import { connect } from 'react-redux'
class index extends Component {
  state = {
    data: {}
  }
  constructor(props) {
    super(props);
    this.params = SNavigation.getAllParams() ?? {}
  }

  componentDidMount() {
    this.setState({ loading: true, error: "" })
    // SSocket.sendPromise({
    //   component: "reporte",
    //   type: "executeFile",
    //   src: "query/contexto_data/promedio_de_pedido_por_dia_de_la_semana.pgsql",
    //   params: [this.params.fecha_inicio, this.params.fecha_fin]
    // }).then(e => {
    //   this.setState({ loading: false, error: "", data: e.data })
    // }).catch(e => {
    //   SPopup.alert(e.error)
    //   this.setState({ loading: false, error: e.error })
    // })

    SSocket.sendPromise({
      component: "reporte",
      type: "executeFile",
      src: "query/contexto_data/dias_de_pedidos.pgsql",
      params: [this.params.fecha_inicio, this.params.fecha_fin]
    }).then(e => {
      this.setState({ loading: false, error: "", data: e.data })
    }).catch(e => {
      SPopup.alert(e.error)
      this.setState({ loading: false, error: e.error })
    })
  }

  renderDay(day) {
    day = day.replace(/\s+/g, '');;

    switch (day) {
      case "Monday":
        day = "Lunes";
        break;
      case "Tuesday":
        day = "Martes";
        break;
      case "Wednesday":
        day = "Miércoles";
        break;
      case "Thursday":
        day = "Jueves";
        break;
      case "Friday":
        day = "Viernes";
        break;
      case "Saturday":
        day = "Sábado";
        break;
      case "Sunday":
        day = "Domingo";
        break;

      default:
        dia = "";
        break;
    }

    return day;
  }

  renderTable() {
    if (!this.state.data) return <SLoad />
    return <STable2
      header={
        [
          { key: "dia_str", label: "Día", width: 140, render: day => this.renderDay(day) },
          { key: "cantidad_pedidos", label: "Cantidad Pedidos", width: 100, order: "desc" },
          { key: "cantidad_tapekes", label: "Cantidad Tapekes", width: 100 },
          { key: "cantidad_productos", label: "Cantidad Productos", width: 100 },
        ]
      }
      data={this.state.data} />
  }

  render() {
    return <SPage
      title={`Reporte de promedio de pedido por dia de la semana desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
      disableScroll
    >{this.renderTable()}</SPage>
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);