import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import { SButtom, SHr, SList, SLoad, SText, SPopup, SNavigation, SView, SIcon } from 'servisofts-component';
import Model from '../../Model';
import SSocket from 'servisofts-socket';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado",],
        });
        this.state = {
            data: {}  // Asegúrate de inicializar data como un objeto
        }
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: 'company',
            type: 'getAllRestaurantes',
            key_company: this.$params.pk,
            key_usuario: Model.usuario.Action.getKey()
        }).then(res => {
            this.setState({ data: res.data ?? {} })  
        }).catch(e => {
            console.log(e.data);
        })
    }

    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $onSelect(obj) {
        SPopup.confirm({
            title: "Eliminar Restaurante de la Company",
            message: "¿Seguro que desea remover el restaurante de la company?",
            onPress: () => {
                Model.restaurante.Action.editar({
                    data: {
                        ...obj,
                        key_company: null,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                }).then(res => {
                    this.setState(prevState => {
                        let newData = { ...prevState.data };
                        delete newData[obj.key];
                        return { data: newData };
                    });
                }).catch(e => {
                    SPopup.alert(e.data);
                    console.log(e.data);
                })
            }
        })
    }

    $footer() {
        if (!this.state.data) return <SLoad />

        return <>
            <SHr h={10} />
            <SText fontSize={20} bold>Restaurantes o Sucursales</SText>
            <SButtom
                onPress={() => {
                    SNavigation.navigate("/restaurante", {
                        filter: (obj) => {
                            if (this.state.data) {
                                let item = Object.values(this.state.data).find(a => a.key == obj.key && a.estado > 0)
                                if (item) {
                                    return false
                                }
                            }
                            return true;
                        },
                        onSelect: (obj) => {
                            if (!obj) return;
                            if (this.state.data) {
                                let item = Object.values(this.state.data).find(a => a.key_restaurante == obj.key && a.estado > 0)
                                if (item) {
                                    SPopup.alert("El restaurante ya se encuentra registrado.")
                                    return;
                                }
                            }
                            Model.restaurante.Action.editar({
                                data: {
                                    ...obj,
                                    key_company: this.$params.pk,
                                },
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(res => {
                                this.setState(prevState => {
                                    let newData = { ...prevState.data };
                                    newData[obj.key] = obj;
                                    return { data: newData };
                                });
                            }).catch(e => {
                                SPopup.alert(e.data);
                                console.log(e.data);
                            })
                        }
                    })
                }}
            >+ Añadir</SButtom>
            <SList
                data={this.state.data}
                space={0}
                order={[{ key: "tipo", order: "desc", peso: 1 }]}
                render={(obj) => {
                    if (obj.key) {
                        return (
                            <SView key={obj.key} flex>
                                <SView flex row card style={{
                                    padding: 8,
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }} onPress={() => this.$onSelect(obj)}>
                                    <SText fontSize={16}>{obj?.nombre}</SText>
                                    <SIcon name={"Delete"} height={30} width={30}></SIcon>
                                </SView>
                                <SHr />
                            </SView>
                        )
                    }
                }}
            />
        </>
    }
}

export default connect(index);
