import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import { SHr, SIcon, SList, SLoad, SNavigation, SText, STheme, SView, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil cupón",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            // item: item
        });
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    // $allowDelete() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    // }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    getUsuarios() {
        var cupon_usuario = Model.cupon_usuario.Action.getAll({ key_cupon: this.pk });
        var usuarios = Model.usuario.Action.getAll();
        if (!cupon_usuario) return <SLoad />
        if (!usuarios) return <SLoad />
        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={cupon_usuario}
                render={(obj) => {
                    var usuario = usuarios[obj.key_custodio]
                    return <SView card col={"xs-12"} style={{
                        padding: 4
                    }}>
                        <SText fontSize={16}>{usuario?.Nombres} {usuario?.Apellidos}</SText>
                        <SText color={STheme.color.lightGray}>{usuario?.Correo}</SText>
                    </SView>
                }} />
        </SView>
    }
    getRestaurante() {
        var cupo_restaurante = Model.cupon_restaurante.Action.getAll({ key_cupon: this.pk });

        var restaurantes = Model.restaurante.Action.getAll();
        if (!cupo_restaurante) return <SLoad />
        if (!restaurantes) return <SLoad />
        this.cupon_restaurante = cupo_restaurante;
        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={cupo_restaurante}
                filter={a => a.estado != 0}
                render={(obj) => {
                    var restaurante = restaurantes[obj.key_restaurante]
                    return <SView card col={"xs-12"} row style={{
                        padding: 8
                    }}>
                        <SView col={"xs-11"} row>
                            <SText fontSize={16}>{restaurante?.nombre}</SText>
                        </SView>
                        <SView flex style={{ alignItems: "flex-end" }} center
                            onPress={() => {
                                SPopup.confirm({
                                    title: "Eliminar Restaurante",
                                    message: "¿Seguro que desea remover el restaurante?",
                                    onPress: () => {
                                        obj.estado = 0;
                                        Model.cupon_restaurante.Action.editar({
                                            data: obj,  
                                            key_usuario: Model.usuario.Action.getKey()
                                        })
                                    }
                                })
                            }}
                        >
                            <SIcon name={'Delete'} width={20} height={20} />
                        </SView>
                    </SView>
                }} />
        </SView>
    }
    
    $footer() {
        return <SView col={"xs-12"} center>
            <SHr />

            <SView card style={{ padding: 8 }} onPress={() => { 
 
                SNavigation.navigate("/restaurante", {
                    filter: (obj) => {
                        if (this.cupon_restaurante) {
                            let item = Object.values(this.cupon_restaurante).find(a => a.key_restaurante == obj.key && a.estado > 0)
                            if (item) {
                                return false
                            }
                        }
                        return true;
                    },
                    onSelect: (obj) => {
                        if (!obj) return;
                        if (this.cupon_restaurante) {
                            let item = Object.values(this.cupon_restaurante).find(a => a.key_restaurante == obj.key && a.estado > 0)
                            if (item) {
                                SPopup.alert("El restaurante ya se encuentra registrado.")
                                return;
                            }

                        }
                        Model.cupon_restaurante.Action.registro({
                            data: {
                                key_cupon: this.pk,
                                key_restaurante: obj.key,
                            },
                            key_usuario: Model.usuario.Action.getKey()
                        })
                    }
                })
            }}><SText>Agregar Restaurante</SText></SView>
            {this.getRestaurante()}
            <SHr />
            <SText>{"Usuarios que adquirieron el cupón:"}</SText>
            {this.getUsuarios()}
        </SView>
    }
}
export default connect(index);