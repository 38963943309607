import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import { SHr, SText, SView, SDate, STheme } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado"],
            // item: item
        });
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        if (this.data?.estado == 0) return;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $allowRestore() {
        if (this.data?.estado != 0) return false;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "restore" })
    }

    $item(obj) {
        let usuarioCliente = Model.usuario.Action.getByKey(obj.key_cliente);
        let usuarioAdmin = Model.usuario.Action.getByKey(obj.key_usuario);
        return <>
            <SView card style={{
                padding: 40,
                margin: 40,
            }}>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SHr h={10} />
                        <SText fontSize={16}>{`Tipo de Pago:  ${(obj?.tipo_pago)}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Fecha Creación:  ${new SDate(obj?.fecha_on, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Cliente:  ${usuarioCliente?.Nombres} ${usuarioCliente?.Apellidos}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{usuarioAdmin ? `Creador Transacción:  ${usuarioAdmin?.Nombres} ${usuarioAdmin?.Apellidos}` : "Creador Transacción: Servidor Tapeke"}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>Detalle:  {obj.detalle ? obj.detalle  : "No hay detalle"}</SText>

                        <SHr h={10} />
                        <SText style={{
                            color: obj?.monto < 0 ? STheme.color.danger : "green"
                        }} fontSize={16}>{`Monto:  ${obj?.monto}`}</SText>
                    </SView>
                </SView>
            </SView>
        </>
    }
}

export default connect(index);