import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SPage, SText, SList, STable3 } from 'servisofts-component';
import testJson from "./test.json"
export default class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <SPage title={'Test'} hidden disableScroll>
                <STable3
                    header={["key", "Nombres", "Apellidos", ]}
                    data={Object.values(testJson)}
                />
            </SPage >
        );
    }
}
// const initStates = (state) => {
//     return { state }
// };
// export default connect(initStates)(Test);