import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SText, SDate, SButtom } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
class index extends Component {
    state = {
        usuario: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        this.getData();
    }

    async getData() {

        const usuarioResp = await SSocket.sendPromise({
            service: "usuario",
            version: "2.0",
            component: "usuario",
            type: "getAll",
            cabecera: "usuario_app"
        })

        const usuarioRolResp = await SSocket.sendPromise({
            service: "roles_permisos",
            component: "usuarioRol",
            type: "getAll"
        })

        const rolResp = await SSocket.sendPromise({
            service: "roles_permisos",
            component: "rol",
            type: "getAll"
        })



        let usuario = usuarioResp.data;
        let usuarioRol = usuarioRolResp.data;
        let rol = rolResp.data;

        const rolMap = new Map(Object.values(rol).map(r => [r.key, r]));

        const usuarioRolMap = new Map();
        Object.values(usuarioRol).forEach(ur => {
            if (!usuarioRolMap.has(ur.key_usuario)) {
                usuarioRolMap.set(ur.key_usuario, []);
            }
            usuarioRolMap.get(ur.key_usuario).push(ur.key_rol);
        });

        Object.values(usuario).forEach(u => {


            const rolesKeys = usuarioRolMap.get(u.key) || [];
            u.roles = rolesKeys
                .map(keyRol => rolMap.get(keyRol))
                .filter(Boolean);
        });

        this.setState({ usuario: usuario })
    }

    renderRoles(roles) {
        let rolesString = "";
        if (roles) {
            const rolesArray = Object.values(roles);
            rolesArray.map((r, index) => {
                rolesString += `${r.descripcion}`;
                if (index !== rolesArray.length - 1) {
                    rolesString += " | ";
                }
            });
        }
        return rolesString !== "" ? rolesString : "NO TIENE ROL";
    }

    calcularEdad(fechaNacimiento) {
        if (fechaNacimiento !== '') {
            const hoy = new Date();
            const fechaNac = new Date(fechaNacimiento);

            let edad = hoy.getFullYear() - fechaNac.getFullYear();

            const mes = hoy.getMonth() - fechaNac.getMonth();
            if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
                edad--;
            }

            return edad;
        }
        return "NO HAY DATA";
    }

    renderTable() {
        if (!this.state.usuario) return <SLoad />
        return <STable2
            header={
                [
                    { key: "-", label: "Usuario", width: 140, center: true, render: val => `${val.Nombres} ${val.Apellidos}` },
                    { key: "roles", label: "roles", width: 200, center: true, render: val => this.renderRoles(val) },
                    { key: "Telefono", label: "Celular", center: true, width: 140 },
                    { key: "Correo", label: "Correo", center: true, width: 200 },
                    { key: "fecha_nacimiento", label: "Edad", width: 100, center: true, render: val => this.calcularEdad(val) },
                    { key: "genero", label: "Genero", width: 100, center: true, render: val => val ? val : "NO HAY DATA" },
                    { key: "key", label: "Mapa Pedidos", width: 100, center: true, component: val => <SButtom onPress={() => SNavigation.navigate("/reporte/cliente/lugares_pedidos_cliente_puntual", { key_usuario: val })}>Ir a Mapa</SButtom> },
                ]
            }
            data={this.state.usuario} />
    }

    render() {
        return <SPage
            title={`Reporte de DATA USUARIO EDAD GENERO`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);