import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from '../../../Components/Container';
import Model from '../../../Model';
import { SDate, SHr, SList, SLoad, SMath, SNavigation, SInput, SPopup, SPage, SText, STheme, SView, SIcon, SExcel } from 'servisofts-component';
import Btn from '../../../Components/Btn';
import ConciliarPopup from "./Components/ConciliarPopup"
import Pedido_item from './Components/Pedido_item';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: new SDate().toString("yyyy-MM-dd")
        };
        this.key_conductor = SNavigation.getParam("key_conductor");
    }
    componentDidMount() {
        Model.pedido.Action.getPendientesConciliacionDelivery({ key_conductor: this.key_conductor }).then((resp) => {
            this.setState({ pendientes: resp.data, cantidad_recogidas: resp.cantidad, ultima_conciliacion: resp.ultima_conciliacion })
        }).catch(e => {
            console.error(e)
        })
    }

    getArrayFinalFechaFilter() {
        let datenow = new SDate(this.state.fecha).toString("yyyy-MM-dd");
        let arrayFinal = Object.values(this.state.pendientes).filter(a => {
            let fechaPedido = new SDate(a.fecha).toString("yyyy-MM-dd");

            if (fechaPedido <= datenow) {
                return a;
            }

        });
        return arrayFinal;
    }

    head({ cantidadTotal }) {
        return <SView col={"xs-12"} row center card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText color={STheme.color.gray} fontSize={16} bold>{this.state.fecha} </SText>
            <SHr />
            <SView flex height={2} />
            <SText color={STheme.color.text} bold fontSize={30}>Bs. {SMath.formatMoney(cantidadTotal)}</SText>
            <SHr height={10} />
            <SText color={STheme.color.text} fontSize={16} bold>TOTAL INGRESOS</SText>
            <SHr height={10} />
        </SView>
    }

    pagos({ efectivo, linea, label }) {
        return <SView col={"xs-12"} row center
            card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText fontSize={20} color={STheme.color.gray} bold>{label}</SText>
            <SHr height={5} />
            <SView height={2} col={"xs-10"} style={{
                borderBottomColor: STheme.color.gray,
                borderBottomWidth: 2
            }} />
            <SHr height={5} />
            <SView col={"xs-6"} row center
                style={{
                    borderRightColor: STheme.color.gray,
                    borderRightWidth: 2
                }}
            >
                <SText fontSize={14} color={STheme.color.text} center bold>EN EFECTIVO</SText>
                <SHr />
                <SText fontSize={20} color={STheme.color.text}>Bs. </SText>
                <SText fontSize={20} bold color={STheme.color.text}>{SMath.formatMoney(efectivo)}</SText>
                <SHr />
            </SView>
            <SView col={"xs-6"} row center>
                <SText fontSize={14} color={STheme.color.text} bold>EN LÍNEA</SText>
                <SHr />
                <SText fontSize={20} color={STheme.color.text}>Bs. </SText>
                <SText fontSize={20} bold color={STheme.color.text}>{SMath.formatMoney(linea)}</SText>
                <SHr />
            </SView>
            <SHr height={15} />
        </SView>
    }

    labelDetalle({ title, totalIngresos, colorMonto, simbol }) {
        return <>
            <SView col={"xs-10"} row center >
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-start' }}>
                    <SText fontSize={13} color={STheme.color.text} center >{title}</SText>
                </SView>
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-end', }} >
                    <SText fontSize={13} color={colorMonto ?? STheme.color.text} center >{simbol ? simbol : null} Bs. {SMath.formatMoney(totalIngresos)}</SText>
                </SView>
            </SView>
            <SView height={2} col={"xs-10"} style={{
                borderBottomColor: STheme.color.lightGray,
                borderBottomWidth: 1
            }} />
        </>
    }
    ganancias({ total_ingresos, linea, efectivo, comision_tapeke, deuda, descuento_efectivo }) {
        return <SView col={"xs-12"} row center
            card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText fontSize={20} color={STheme.color.gray} bold>GANANCIAS</SText>
            <SHr height={5} />
            <SView height={2} col={"xs-5"} style={{
                borderBottomColor: STheme.color.gray,
                borderBottomWidth: 2
            }} />
            <SHr height={15} />

            {this.labelDetalle({ title: "Total Ingresos", totalIngresos: total_ingresos })}

            <SHr height={20} />
            <SText fontSize={15} color={STheme.color.gray} bold>Detalle Pago</SText>
            <SHr height={5} />

            {this.labelDetalle({ title: "Ingresos en Efectivo", totalIngresos: efectivo, colorMonto: STheme.color.danger })}

            {this.labelDetalle({ title: "Abono Descuento Efectivo", totalIngresos: descuento_efectivo, colorMonto: STheme.color.success })}

            {this.labelDetalle({ title: "Ingresos en Linea", totalIngresos: linea, colorMonto: STheme.color.success, simbol: '+' })}

            {this.labelDetalle({ title: "Abono Cancelacion", totalIngresos: linea, colorMonto: STheme.color.success, simbol: '+' })}

            {this.labelDetalle({ title: "Comisión Tapeke", totalIngresos: comision_tapeke, colorMonto: STheme.color.danger, simbol: '-' })}

            <SView col={"xs-10"} row center >
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-start' }}>
                    <SText fontSize={20} color={STheme.color.text} center >Total</SText>
                </SView>
                <SView col={"xs-6"} row center style={{ justifyContent: 'flex-end', }} >
                    <SText fontSize={20} color={STheme.color.text}>Bs.</SText>
                    <SText fontSize={20} bold color={STheme.color.text} center > {deuda * -1}</SText>
                </SView>
            </SView>
            <SHr height={15} />
        </SView>
    }

    deliveryDato({ title, cantidad, monto }) {

        const viewIngresos = () => {
            if (monto) {
                return <>
                    <SView col={"xxs-12 xs-12 sm-7 md-7 lg-7 xl-7"} row center>
                        <SText fontSize={14} color={STheme.color.text} bold>INGRESOS</SText>
                        <SHr />
                        <SText fontSize={20} color={STheme.color.text}>Bs. </SText>
                        <SText fontSize={25} bold color={STheme.color.text}>{SMath.formatMoney(monto)}</SText>
                        <SHr />
                    </SView>
                </>
            }
        }
        return <SView col={"xs-5.75"} row center
            card
            style={{
                borderRadius: 8
            }}
        >
            <SHr height={10} />
            <SText fontSize={20} color={STheme.color.gray} bold>{title}</SText>
            <SHr height={5} />
            <SView height={2} col={"xs-5"} style={{
                borderBottomColor: STheme.color.gray,
                borderBottomWidth: 2
            }} />

            <SHr height={15} />
            <SView col={"xxs-12 xs-12 sm-5 md-5 lg-5 xl-5"} row center>
                <SText fontSize={14} color={STheme.color.text} center bold>CANTIDAD</SText>
                <SHr />
                <SText fontSize={25} bold color={STheme.color.text}>{cantidad}</SText>
                <SHr />
            </SView>

            {viewIngresos()}

            <SHr height={10} />
        </SView>
    }

    getTableDetail() {
        if (!this.state.pendientes) return <SLoad />
        let cantidad_delivery = 0;

        let total = {
            efectivo: 0,
            linea: 0,
            comision_efectivo: 0,
            comision_linea: 0,
            descuento_efectivo: 0,
            descuento_linea: 0,
            total: 0,
            comision_total: 0
        }
        let incentivos_f_especial = 0;
        let incentivos_f_especial_monto = 0;
        let incentivos_lluvia = 0;
        let incentivos_lluvia_monto = 0;


        this.keys_pedidos = [];
        let arrayFinal = this.getArrayFinalFechaFilter();

        arrayFinal.map(obj => {
            this.keys_pedidos.push(obj.key);

            let data_in_de = (obj.incentivos ?? []).find(a => a.tipo == "dia_especial");
            if (!!data_in_de) {
                incentivos_f_especial += 1;
                incentivos_f_especial_monto += data_in_de.monto_driver;
            }
            let data_in_ll = (obj.incentivos ?? []).find(a => a.tipo == "lluvia");
            if (!!data_in_ll) {
                incentivos_lluvia += 1;
                incentivos_lluvia_monto += data_in_ll.monto_driver;
            }

            if (obj.delivery > 0) {
                cantidad_delivery += 1;
            }

            if (obj.tipo_pago.find(a => a.type == "efectivo")) {
                total.efectivo += parseFloat(obj.delivery + (data_in_de?.monto ?? 0) + (data_in_ll?.monto ?? 0))
                total.comision_efectivo += obj.comision_delivery;
                total.descuento_efectivo += obj.total_descuento_delivery;
            } else {
                total.linea += parseFloat(obj.delivery + (data_in_de?.monto ?? 0) + (data_in_ll?.monto ?? 0))
                total.comision_linea += obj.comision_delivery;
                total.descuento_linea += obj.total_descuento_delivery;
            }

            total.total += parseFloat(obj.delivery + ((data_in_de?.monto ?? 0) + (data_in_ll?.monto ?? 0)))
            total.comision_total += (obj.comision_delivery + ((data_in_de?.monto ?? 0) - (data_in_de?.monto_driver ?? 0)) + ((data_in_ll?.monto ?? 0) - (data_in_ll?.monto_driver ?? 0)));
        })

        this.deuda = parseFloat(((total.linea + total.descuento_efectivo) - total.comision_total)).toFixed(2);
        return <SView col={"xs-12"} >
            <SHr />
            <SText>Última conciliación: {new SDate(this.state?.ultima_conciliacion?.fecha_cierre).toString("yyyy-MM-dd")}</SText>
            <SHr />
            {this.head({ cantidadTotal: total.total })}
            <SHr height={10} />
            <SView col={"xs-12"} row style={{ justifyContent: "space-between" }}>
                {this.deliveryDato({ title: "Entregados", cantidad: cantidad_delivery/* , monto: cantidad_delivery */ })}
                {this.deliveryDato({ title: "Retiros del local", cantidad: this.state.cantidad_recogidas/* , monto: this.state.cantidad_recogidas */ })}
                <SHr />
                {this.deliveryDato({ title: "Fechas especiales", cantidad: incentivos_f_especial, monto: incentivos_f_especial_monto })}
                {this.deliveryDato({ title: "Lluvia", cantidad: incentivos_lluvia, monto: incentivos_lluvia_monto })}
            </SView>
            {/* {this.deliveryDato({ cantidadDelivery: cantidad_delivery, gananciaDelivery: cantidad_delivery * 1, cantidadRecoger: this.state.cantidad_recogidas, gananciaRecoger: this.state.cantidad_recogidas * 1 })} */}
            <SHr height={10} />
            {/* {this.deliveryDato2({ cantidadDelivery: incentivos_f_especial, gananciaDelivery: incentivos_f_especial * 1, cantidadRecoger: incentivos_lluvia, gananciaRecoger: incentivos_lluvia * 1 })} */}
            <SHr height={10} />
            {this.pagos({ efectivo: total.efectivo, linea: total.linea, label: "PAGOS" })}
            {this.pagos({ efectivo: total.descuento_efectivo, linea: total.descuento_linea, label: "DESCUENTOS" })}
            {/* {this.pagos({ efectivo: total.efectivo - total.descuento_efectivo, linea: total.linea - total.descuento_linea, label: "TOTAL" })} // TODO Revisar logica. */}
            <SHr height={10} />
            {this.ganancias({ total_ingresos: total.total, linea: total.linea, efectivo: total.efectivo, comision_tapeke: total.comision_total, deuda: this.deuda, descuento_efectivo: total.descuento_efectivo })}
            <SHr height={10} />
            <SExcel name='conciliacion' data={arrayFinal}
                renderData={(obj) => {
                    obj.total = obj.delivery;
                    obj.incentivo = 0;
                    let tipo_pago = "Online";

                    if (obj.tipo_pago.find(a => a.type == "efectivo")) {
                        tipo_pago = "Efectivo";
                    }
                    obj.tipo_pagoSTR = tipo_pago;

                    if (obj.delivery > 0) {
                        obj.tipo_envio = "Delivery";
                    } else {
                        obj.tipo_envio = "Recojer del lugar";
                    }
                    return obj;
                }}
                header={[
                    { key: "fecha_on", label: "Fecha de registro", type: "s", style: { width: 100 } },
                    { key: "fecha", label: "Fecha de pedido", type: "s", style: { width: 100 } },
                    { key: "horario.hora_inicio", label: "H. inicio", type: "s", style: { width: 100 } },
                    { key: "horario.hora_fin", label: "H. fin", type: "s", style: { width: 100 } },
                    { key: "delivery", label: "Delivery", type: "s", style: { width: 100 } },
                    { key: "incentivo", label: "Delivery", type: "s", style: { width: 100 } },
                    { key: "total", label: "Total", type: "s", style: { width: 100 } },
                    { key: "tipo_pagoSTR", label: "Tipo de pago", type: "s", style: { width: 100 } },
                    { key: "tipo_envio", label: "Tipo de envio", type: "s", style: { width: 100 } },
                    { key: "comision_delivery", label: "Comisión", type: "s", style: { width: 100 } },
                ]}>
                <Btn >Exportar Excel</Btn>
            </SExcel>
        </SView>
    }

    render_lista_pedidos() { // Aca hay ese array
        if (!this.state.ver_pedidos) return null;
        let arrayFinal = this.getArrayFinalFechaFilter();
        return <>
            <SText fontSize={16}>Pedidos pendientes de conciliación:</SText>
            <SHr />
            <SList
                data={arrayFinal}
                limit={20}
                order={[{ key: "fecha_on", order: "desc", peso: 1, }]}
                render={(obj) => <Pedido_item data={obj} />}
            />
        </>
    }
    render() {
        return (
            <SPage title={'Conciliar'} onRefresh={res => {
                this.componentDidMount();
            }}>
                <Container >
                    <SHr />
                    <SText>Para conciliar, debe seleccionar la fecha hasta donde se conciliarán los pedidos.</SText>
                    <SHr />
                    <SInput type={"date"} value={this.state.fecha} width={110} onChangeText={(val) => {
                        if (new SDate(val, "yyyy-MM-dd").isAfter(new SDate())) {
                            SPopup.alert("No puedes seleccionar una fecha mayor a hoy.")
                            return;
                        }
                        this.setState({ fecha: val })
                    }} />
                    <SHr />
                    {this.getTableDetail()}
                    <SHr />
                    <Btn onPress={() => {
                        ConciliarPopup.open({
                            key_conductor: this.key_conductor,
                            monto: this.deuda,
                            fecha: this.state.fecha,
                            keys_pedidos: this.keys_pedidos
                        });
                    }} color={STheme.color.success}>Conciliar</Btn>
                    <SHr height={20} />
                    {/* {this.$footer()} */}

                    <Btn onPress={() => {
                        this.setState({
                            ver_pedidos: !this.state.ver_pedidos
                        })
                    }}>{!this.state.ver_pedidos ? "Mostrar pedidos" : "Ocultar pedidos"}</Btn>
                    <SHr />
                    {this.render_lista_pedidos()}
                    <SHr h={30} />
                </Container>
            </SPage>
        );
    }

    itemMonto({ label, val }) {
        return <>
            <SView col={"xs-12"} row>
                <SText>{label}</SText><SView flex /><SText>{val}</SText>
            </SView>
            <SHr h={1} color={STheme.color.card} />
        </>
    }
    $footer() {
        var envs = Model.enviroment.Action.getAll();
        if (!envs || !this.state.pendientes) return <SLoad />

        let arr = Object.values(this.state.pendientes);
        let cantidad = arr.length;
        let arr_d_es = arr.filter(a => !!(a.incentivos ?? []).find(a => a.tipo == "dia_especial"));
        let arr_lluvia = arr.filter(a => !!(a.incentivos ?? []).find(a => a.tipo == "lluvia"));
        let incentivos_f_especial = arr_d_es.length;
        let incentivos_lluvia = arr_lluvia.length;

        return <SView col={"xs-12"}>
            <SText fontSize={16}>Pedidos pendientes de conciliación:</SText>
            <SHr />
            {this.itemMonto({
                label: "Cantidad de recogidas en restaurante",
                val: this.state.cantidad_recogidas
            })}
            {this.itemMonto({
                label: "Cantidad de pedidos entregados",
                val: cantidad
            })}
            {this.itemMonto({
                label: "Incentivos fechas especiales",
                val: incentivos_f_especial
            })}
            {this.itemMonto({
                label: "Incentivos caso de lluvia",
                val: incentivos_lluvia
            })}
        </SView >
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);