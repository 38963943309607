import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import CryptoJS from 'crypto-js';
import Model from '../../Model';
import DatosDocumentosEditar from './Components/DatosDocumentosEditar';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: []
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inputs = super.$inputs();
        inputs["Password"].type = "password"
        // inputs["Password"].values = CryptoJS.MD5(inputs["Password"].values).toString();
        inputs["Correo"].type = "email"
        inputs["Telefono"].type = "phone"
        inputs["enable"].type = "select"
        inputs["enable"].defaultValue = inputs["enable"].defaultValue == "true" ? "true" : "false"
        inputs["enable"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        return inputs;
    }
    $onSubmit(data) {
        data.Password = CryptoJS.MD5(data.Password).toString();
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            // SNavigation.goBack();
            this.presolve(this.pk)
        }).catch(e => {
            console.error(e);
        })
    }

    $submitName() {
        return ""
    }
    $footer() {
        return <DatosDocumentosEditar key_usuario={this.pk} onSubmit={() => {
            return new Promise((resolve, reject) => {
                this.presolve = resolve;
                this.form.submit();
                // resolve("KEY_USUARIO");
            })
        }} />
    }
}

export default connect(index);