import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SDate, SLoad, SNavigation, SPage, SPopup, STable2 } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);

        this.params = SNavigation.getAllParams() ?? {}
    }
    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "restaurante",
            type: "get_historico_tapeke_deshabilitado",
            // key_restaurante: this.params.key_restaurante
        }).then(e => {

            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })

                SSocket.sendPromise({
                    component: "restaurante",
                    type: "getAll",
                }).then(rest => {
                    this.setState({ loading: false, error: "", data: e.data, restaurantes: rest.data })
                }).catch(e => {
                    SPopup.alert(rest.error)
                })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })


    }

    displayTipo(type) {
        if (type === "disable") {
            return "Deshabilitado"
        } else {
            return "Habilitado"
        }
    }

    displayRestaurante(key) {
        if (this.state.restaurantes == null) {
            return "Restaurante no pillado"
        } else {
            return this.state.restaurantes[key]?.nombre
        }
    }

    renderTable() {

        if (!this.state.data) return <SLoad />

        return <STable2
            header={
                [
                    { key: "fecha_on", label: "fecha", width: 140, order: "desc", render: val => new SDate(val).toString("yyyy-MM-dd hh:mm") },
                    { key: "key_restaurante", label: "Restaurante", width: 200, render: key => this.displayRestaurante(key) },
                    { key: "usuario", label: "cliente", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "tipo", width: 100, render: val => this.displayTipo(val) },
                ]
            }
            data={this.state.data} />
    }
    render() {
        return <SPage
            // title={`Reporte Tapeke Deshabilitados Restaurante ${this.restaurantes?.nombre}`}
            title={`Reporte Tapeke Deshabilitados Restaurante`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);