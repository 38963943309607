import { SPage } from "servisofts-component";

import root from "./root";
import registros_por_rol from "./registros_por_rol"
import cantidad_pedidos from "./cantidad_pedidos"
import promedio_de_pedido_por_dia_de_la_semana from "./promedio_de_pedido_por_dia_de_la_semana"
import pedidos_por_horas_del_dia from "./pedidos_por_horas_del_dia"
import lugares_pedidos from "./lugares_pedidos";
import ranking_orden_pago from "./ranking_orden_pago"


export default SPage.combinePages("app",
    {
        "": root,
        registros_por_rol,
        cantidad_pedidos,
        promedio_de_pedido_por_dia_de_la_semana,
        pedidos_por_horas_del_dia,
        ...lugares_pedidos,
        ranking_orden_pago,
    }
)

export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k+"-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}