//import liraries
import React, { Component } from 'react';
import DPA, { connect } from 'servisofts-page';
import Model from '../../../../Model';
import { SText, SView, STheme, SDate, SHr, SNavigation, SLoad, SList, SIcon, SPopup, SInput } from 'servisofts-component';
import RangoItem from './RangoItem';
// create a component
class DescuentosHijos extends Component {
    render() {
        let descuentos = Model.descuento.Action.getAllBy({ key_descuento: this.props?.data?.key })
        if (!descuentos) return <SLoad />
        return <SView col={"xs-12"}>
            <SHr />
            <SText card center border={STheme.color.lightGray} padding={16} onPress={() => {
                let nuevo_hijo = {
                    ...this.props.data,
                    key_descuento: this.props?.data?.key
                }
                delete nuevo_hijo["key"]
                delete nuevo_hijo["fecha_on"]
                delete nuevo_hijo["key_usuario"]
                Model.descuento.Action.registro({
                    data: nuevo_hijo,
                    key_usuario: Model.usuario.Action.getKey()
                }).then(e => {

                }).catch(e => {
                    console.log(e);
                })
            }}> + CREAR RANGO</SText>
            <SHr />
            <SList
                data={descuentos}
                filter={a => a.estado != 0}
                render={a => {
                    return <RangoItem data={a} />
                }}
            />
        </SView>
    }
}


//make this component available to the app
export default DescuentosHijos;
