import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'REPORTES GPXs'}>
                <SHr height={32} />
                <MenuPages path={"/reporte/operaciones/reportes_gpx"}  >
                    <MenuButtom label='HISTORIAL DE RASTREO POR DRIVER' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/driver", {
                            onSelect: (obj) => {
                                if (!obj) return;
                                SPopup.date("Selecciona las fechas", (evt) => {
                                    evt.key_usuario = obj.key;
                                    SNavigation.navigate("/gpx", evt);
                                });
                                return true;
                            }
                        })
                    }} />

                </MenuPages>
                <SHr height={32} />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);