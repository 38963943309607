import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "multiplicador_billetera"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" },
        "fecha_inicio": { type: "timestamp", editable: true, notNull: true, label: "Fecha de inicio" },
        "fecha_fin": { type: "timestamp", editable: true, notNull: true, label: "Fecha fin" },
        "porcentaje": { type: "double", editable: true, notNull: true },
        "descripcion": { type: "text", editable: true, notNull: true }
    },
    Action,
    Reducer,
});