import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SDate, SInput, SLoad, SMapView, SNavigation, SPage, SText, SThread, SView, SPopup } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import { SelectEntreFechas } from '../../../../Components/Fechas';

export default class mapa_calor_por_linea extends Component {
    state = {
        ...SNavigation.getAllParams(),
        fecha_inicio: SNavigation.getParam("fecha_inicio", new SDate().addDay(-30).toString("yyyy-MM-dd")),
        fecha_fin: SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd")),
    }

    componentDidMount() {

    }

    getData() {
        delete this.state.data;
        this.setState({ loading: true, data: null })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/lugares_pedidos_cliente.pgsql",
            params: [this.state.fecha_inicio, this.state.fecha_fin]
        }).then(e => {
            this.setState({ data: e.data, loading: false })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    _ref = {}
    input({ label, key, onPress }) {
        return < SView col={"xs-12 sm-6"} padding={4} row >
            <SText onPress={onPress}>{key}: </SText>
            <SInput ref={ref => this._ref[key] = ref} style={{
                height: 30,
                padding: 0,
                justifyContent: "center"
            }}
                height={30}
                flex
                defaultValue={this.state[key] ?? ""}
                placeholder={label}
                onChangeText={e => {
                    if (!e && this.state[key]) {
                        delete this.state[key]
                    } else {
                        this.state[key] = e;

                    }
                    new SThread(1000, "sadasdas", true).start(() => {
                        this.getData();
                    })

                }} />
        </SView >
    }

    filtros() {
        return <SView col={"xs-12"} row>
            <SelectEntreFechas
                fecha_inicio={this.state.fecha_inicio}
                fecha_fin={this.state.fecha_fin}
                onChange={e => {
                    this.state = {
                        ...this.state,
                        ...e,
                    }
                    new SThread(1000, "sadasdas", true).start(() => {
                        this.getData();
                    })

                }} />
        </SView>
    }

    getMapa() {
        if (!this.state.data) return <SView center>
            <SLoad />
            <SText>Por favor aguarde, esto puede demorar unos minutos...</SText>
        </SView>
        return <SMapView initialRegion={{
            latitude: -17.7826045,
            longitude: -63.179657,
            latitudeDelta: 0.1,
            longitudeDelta: 0.1
        }}
            headmap={{
                positions: this.state.data,
                options: {
                    opacity: 0.5,
                    radius: 15
                }
            }}
        >

        </SMapView>
    }
    render() {
        return <SPage title={"MAPA DE CALOR LUGARES DE PEDIDOS DE LOS CLIENTES"} disableScroll>
            {this.filtros()}
            {this.getMapa()}
        </SPage>
    }
}
