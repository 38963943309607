import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import { SText, SView, STheme, SDate, SHr, SNavigation, SLoad, SList, SIcon, SPopup } from 'servisofts-component';
import item from '../item';
import DescuentosHijos from './Components/DescuentosHijos';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil Descuento",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            item: item
        });
        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.descuento_restaurante.Action.CLEAR()
        Model.descuento_tag.Action.CLEAR()
    }

    onBack() {
        return true;
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    getTags() {
        var descuento_tag = Model.descuento_tag.Action.getAll({ key_descuento: this.pk });
        var tags = Model.tag.Action.getAll();
        if (!descuento_tag) return <SLoad />
        if (!tags) return <SLoad />
        this.descuento_tag = descuento_tag;
        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={descuento_tag}
                filter={a => a.estado != 0}
                render={(obj) => {
                    var tag = tags[obj.key_tag]
                    return <SView card col={"xs-12"} row style={{
                        padding: 8
                    }}>
                        <SView col={"xs-11"} row>
                            <SText fontSize={16}>{tag?.descripcion}</SText>
                        </SView>
                        <SView flex style={{ alignItems: "flex-end" }} center
                            onPress={() => {
                                SPopup.confirm({
                                    title: "Eliminar Tag",
                                    message: "¿Seguro que desea remover el tag?",
                                    onPress: () => {
                                        obj.estado = 0;
                                        Model.descuento_tag.Action.editar({
                                            data: obj,
                                            key_usuario: Model.usuario.Action.getKey()
                                        })
                                    }
                                })
                            }}
                        >
                            <SIcon name={'Delete'} width={20} height={20} />
                        </SView>
                    </SView>
                }} />
        </SView>
    }

    componenteTag() {
        return <>
            <SHr h={10} />
            <SView col={"xs-12"} center>
                <SView card style={{ padding: 8 }} onPress={() => {
                    SNavigation.navigate("/restaurante/tag", {
                        // filter: (obj) => {
                        //     if (this.descuento_tag) {
                        //         let item = Object.values(this.descuento_tag).find(a => a.key_restaurante == obj.key && a.estado > 0)
                        //         if (item) {
                        //             return false
                        //         }
                        //     }
                        //     return true;
                        // },
                        onSelect: (obj) => {
                            if (!obj) return;
                            if (this.descuento_tag) {
                                let item = Object.values(this.descuento_tag).find(a => a.key_tag == obj.key && a.estado > 0)
                                if (item) {
                                    SPopup.alert("El tag ya se encuentra registrado.")
                                    return;
                                }

                            }
                            Model.descuento_tag.Action.registro({
                                data: {
                                    key_descuento: this.pk,
                                    key_tag: obj.key,
                                },
                                key_usuario: Model.usuario.Action.getKey()
                            })
                        }
                    })
                }}><SText>Agregar Tag</SText>
                </SView>
            </SView>
            {this.getTags()}
        </>
    }

 
    $footer() {
        return <SView col={"xs-12"}>
            {this.componenteTag()}
        </SView>
        // return <DescuentosHijos data={this.data}/>
    }
}
export default connect(index);