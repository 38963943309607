import { SHr, SNavigation, SPopup, SText, SThread, SView } from 'servisofts-component';

import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "key_servicio", "estado"],
            // item: Item,

        });
    }
    componentDidMount() {
        new SThread(100, "AwaitNav").start(() => {
            SNavigation.navigate("/driver", {
                onSelect: (obj) => {
                    if (this.state.key_conductor) return;
                    if (!obj) {
                        SNavigation.goBack();
                        return;
                    }
                    this.state.key_conductor = obj.key
                    this.setState({ key_conductor: obj.key })
                }
            })
        })
    }
    // $allowNew() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    // }
    onNew() {
        super.onNew({ key_conductor: this.state.key_conductor })
    }

    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    $filter(data) {
        return data.estado != 0
    }
    $getData() {
        if (!this.state.key_conductor) return null;
        // this.empresa = Model.empresa.Action.getSelect();
        // if (!this.empresa) return null;
        return Parent.model.Action.getAllBy({ key_conductor: this.state.key_conductor })
    }
    $header() {
        let valid = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
        return <SView col={"xs-12"}>
            <SHr />
            <SView col={"xs-12"} height={50} card center onPress={() => {
                if (!valid) {
                    SPopup.alert("No tienes permisos para esta acción.")
                    return;
                }
                let key = this.pk ?? this.state.key_conductor
                super.onNew({ key_conductor: key })
            }}>
                <SText fontSize={18} bold>{"CONCILIAR"}</SText>
            </SView >
            <SHr />
            <SText>Historial de conciliaciones:</SText>
        </SView>
    }
}
export default connect(index);