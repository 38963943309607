import { SPage } from "servisofts-component";
import root from "./root.js";
import form from "./form.js"
import recompensa from "./recompensa/index";

export default SPage.combinePages("insignia",
    {
        "": root,
        form,
        ...recompensa
    }
)

