import React, { Component } from 'react';
import { SForm, SGradient, SHr, SImage, SLoad, SMath, SNavigation, SPage, SPopup, SStorage, SText, STheme, SView, SIcon, SInput, Upload } from 'servisofts-component';
import Btn from '../../../../Components/Btn';
import Model from '../../../../Model';
import SSocket from 'servisofts-socket'

type PropsType = {
    key_restaurante: any,
    monto: any,
    fecha: any,
    keys_pedidos: any
}

export default class Popup extends Component<PropsType> {
    static POPUP_CODE = "POPUP_CONCILIAR";
    static open(props: PropsType) {
        SPopup.open({
            key: this.POPUP_CODE,
            content: <Popup {...props} />
        })
    }
    static close() {
        SPopup.close(this.POPUP_CODE)
    }
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    render() {
        return <SView width={362} height={356} center style={{ borderRadius: 8 }} withoutFeedback backgroundColor={STheme.color.background}   >

            <SHr height={16} />
            <SView col={"xs-11"} center >
                <SText color={STheme.color.lightGray} style={{ fontSize: 12 }} bold center >1. Confirma el monto a conciliar</SText>
            </SView>
            <SHr height={8} />
            <SView width={150} center>
                <SInput ref={ref => this.imonto = ref} type={"money"} defaultValue={parseFloat(this.props.monto).toFixed(2)} style={{
                    color: this.props.monto > 0 ? STheme.color.danger : STheme.color.text
                }} />
            </SView>
            <SHr height={16} />
            <SView col={"xs-11"} center >
                <SText color={STheme.color.lightGray} style={{ fontSize: 12 }} bold center >2. Sube la foto del váucher.</SText>
            </SView>
            <SHr height={8} />
            <SView height={100} col={"xs-11"} center style={{
                overflow: "hidden"
            }}>
                <SInput ref={ref => this.ivaucher = ref} type='image' />
            </SView>
            <SHr height={16} />
            <SView col={"xs-11"} center >
                <SText color={STheme.color.lightGray} style={{ fontSize: 12 }} bold center >3. Presiona confirmar.</SText>
            </SView>
            <SHr height={8} />
            <SText color={STheme.color.danger}>{this.state.error}</SText>
            <SView col={"xs-11"} center>

                <Btn onPress={() => {
                    if (this.state.loading) return;
                    let monto = this.imonto.getValue();
                    let vaucher = this.ivaucher.getValue();
                    if (!monto) {
                        this.setState({ error: "Debe ingresar el monto" });
                        return;
                    }
                    if (!vaucher) {
                        this.setState({ error: "Debe ingresar el vaucher" });
                        return;
                    }

                    if (parseFloat(this.props.monto) <= 0) {
                        monto = Math.abs(monto) * -1;
                    }
                    let data = {
                        key_restaurante: this.props.key_restaurante,
                        fecha_cierre: this.props.fecha,
                        total_pagado: monto,
                        descripcion: "",
                    }
                    this.setState({ error: "", loading: true })
                    Model.conciliacion_restaurante.Action.registro({
                        data: data,
                        keys_pedido: this.props.keys_pedidos,
                        key_usuario: Model.usuario.Action.getKey(),
                    }).then(r => {
                        Upload.sendPromise({ file: vaucher[0].file }, SSocket.api.root + "upload/conciliacion_restaurante/" + r.data.key).then(e => {
                            this.setState({ error: "", loading: false })
                            Popup.close();
                            SNavigation.goBack();
                        }).catch(e => {
                            console.error(e);
                            this.setState({ error: e.error, loading: false })
                        })
                        // this.setState({ error: "", loading: false })
                    }).catch(e => {
                        console.error(e);
                        this.setState({ error: e.error, loading: false })
                    })
                    // Popup.close();
                }} >{!this.state.loading ? "Confirmar" : <SLoad />}</Btn>
            </SView>
            <SHr height={8} />
        </SView>
    }
}