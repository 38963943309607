import { SPage } from "servisofts-component";
import Model from "../../Model";
import list from "./list";
import profile from "../usuario/profile";
const model = Model.usuario;

export const Parent = {
    name: "driver",
    path: `/driver`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "profile": profile

})
