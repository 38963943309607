import { SHr, SNavigation, SPopup, SText, SThread, SView } from 'servisofts-component';
import DPA from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import { connect } from 'react-redux';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "key_servicio", "estado", "key_restaurante", "descripcion"],
            onRefresh: (res) => {
                Parent.model.Action.CLEAR();
            }
            // item: Item,

        });
        this.pk = SNavigation.getParam("pk");
    }
    componentDidMount() {
        if (this.pk) {
            this.setState({ key_restaurante: this.pk })
            return;
        }
        new SThread(100, "AwaitNav").start(() => {
            SNavigation.navigate("/restaurante", {
                onSelect: (obj) => {
                    if (this.state.key_restaurante) return;
                    if (!obj) {
                        SNavigation.goBack();
                        return;
                    }
                    this.state.key_restaurante = obj.key
                    this.setState({ key_restaurante: obj.key })
                }
            })
        })

    }

    // $allowNew() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    // }

    // onNew() {
    //     super.onNew({ key_restaurante: this.state.key_restaurante })
    // }

    // $allowTable() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    // }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0
    }

    $getData() {
        let key = this.pk ?? this.state.key_restaurante
        if (!key) return null;
        return Parent.model.Action.getAllBy({ key_restaurante: key })
    }

    $order() {
        return [{ key: "fecha_cierre", order: "desc", peso: 1 }, { key: "fecha_on", order: "desc", peso: 2 }]
    }

    getNombreRestaurante(){
        let restaurante = Model.restaurante.Action.getByKey(this.state.key_restaurante);
        return restaurante?.nombre ?? "Nombre Restaurante no encontrado, recargue la ventana";
    }

    $header() {
        let valid = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
        return <SView col={"xs-12"}>
            <SHr />
            <SHr />
            <SText>key_restaurante: {this.state.key_restaurante}</SText>
            <SHr />
            <SText>Nombre Restaurante: {this.getNombreRestaurante()}</SText>
            <SHr />
            <SHr />
            <SView col={"xs-12"} height={50} card center onPress={() => {
                if (!valid) {
                    SPopup.alert("No tienes permisos para esta acción.")
                    return;
                }
                let key = this.pk ?? this.state.key_restaurante
                super.onNew({ key_restaurante: key })
            }}>
                <SText fontSize={18} bold>{"CONCILIAR"}</SText>
            </SView >
            <SHr />
            <SText>Historial de conciliaciones:</SText>
        </SView>
    }

}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);