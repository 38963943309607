import DPA, { connect } from 'servisofts-page';
import { Parent } from ".";
import { SNavigation, SView, SHr, SText, SDate, STheme } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            limit: 10,
            excludes: ["key", "key_pedido", "transaction_id", "key_usuario"],
            title: "Lista transacciones eliminadas"
            // item: Item,
        });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" });
    }

    $filter(data) {
        return data.estado != 1
    }

    $getData() {
        return Parent.model.Action.getAll({eliminados: true});
    }

    $item(obj) {
        let usuarioCliente = Model.usuario.Action.getByKey(obj.key_cliente);
        let usuarioAdmin = Model.usuario.Action.getByKey(obj.key_usuario);
        return <SView key={obj.key} col={"xs-12"} card padding={8} onPress={() => {
            this.$onSelect(obj)
        }}>
            <SView col={"xs-12"} row>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SHr h={10} />
                        <SText fontSize={16}>{`Tipo de Pago:  ${(obj?.tipo_pago)}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Fecha Creación:  ${new SDate(obj?.fecha_on, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Cliente:  ${usuarioCliente?.Nombres} ${usuarioCliente?.Apellidos}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{usuarioAdmin ? `Creador Transacción:  ${usuarioAdmin?.Nombres} ${usuarioAdmin?.Apellidos}` : "Creador Transacción: Servidor Tapeke"}</SText>

                        <SHr h={10} />
                        <SText style={{
                            color: obj?.monto < 0 ? STheme.color.danger : "green"
                        }} fontSize={16}>{`Monto:  ${obj?.monto}`}</SText>
                    </SView>
                </SView>
            </SView>
        </SView>
    }
}
export default connect(index);