import { SPage } from "servisofts-component";
import Model from "../../../Model";
import root from "./root";

const model = Model.notification_task;

export const Parent = {
    name: "notification_suscription_config",
    path: `/notification/notification_suscription_config`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": root,
})