import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SNavigation, SPage, SPopup, STable2, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '..'
import { connect } from 'react-redux'
import Model from '../../../Model'
class index extends Component {
  state = {
    data: {}
  }
  constructor(props) {
    super(props);
    this.params = SNavigation.getAllParams() ?? {}
  }

  componentDidMount() {
    this.setState({ loading: true, error: "" })
    SSocket.sendPromise({
      component: "reporte",
      type: "executeFile",
      src: "query/contexto_data/pedidos_no_recogidos.pgsql",
      params: [this.params.fecha_inicio, this.params.fecha_fin]
    }).then(e => {
      this.setState({ loading: false, error: "", data: e.data })
    }).catch(e => {
      SPopup.alert(e.error)
      this.setState({ loading: false, error: e.error })
    })
  }

  renderIncentivo(incentivo) {
    if (!incentivo) return
    if (incentivo.length <= 0) return

    let textIncentivo = "";
    incentivo.map((item) => {
      textIncentivo += `${item.tipo} = Monto: (${item.monto}) \n`
    })

    return textIncentivo;
  }

  renderMotivoCancelacion(motivo) {
    if (!motivo) return
    let textMotivo = "";

    if (motivo.motivo == "pedido_prueba") {
      motivo.motivo = "Pedido de prueba"
    }

    textMotivo += `${motivo.motivo}`

    if (motivo.detalle != "") {
      textMotivo += ` | detalle: ${motivo.detalle}`
    }

    return textMotivo.toUpperCase();
  }

  renderTable() {
    let users = Model.usuario.Action.getAll()
    if (!users) return <SLoad />
    if (!this.state.data) return <SLoad />
    return <STable2
      header={
        [
          { key: "fecha_on", label: "fecha", width: 140, order: "desc", render: val => new SDate(val).toString("yyyy-MM-dd hh:mm") },
          { key: "restaurante/nombre", label: "Nombre Restaurante", width: 200 },
          { key: "state", label: "estado", width: 120, center: true },
          { key: "key_usuario_cancelado", label: "usuario que cancelo el pedido", width: 200, render: key => key=='' ? '' : `${users[key]?.Nombres} ${users[key]?.Apellidos}` },
          // {
          //   key: "-motivo", label: "motivo", width: 200, render: obj => {
          //     if (obj.delivery <= 0) return "El cliente no recogio del lugar."
          //     if (!obj.key_conductor) return "Falta de driver.";
          //     return "El driver no llego ah tiempo."
          //   }
          // },
          // {
          //   key: "-culpable", label: "culpable", width: 100, render: obj => {
          //     if (obj.delivery <= 0) return "cliente"
          //     if (!obj.key_conductor) return "tapeke";
          //     return "driver"
          //   }
          // },
          { key: "motivo_cancelacion", label: "Motivo Cancelación", width: 240, render: key => this.renderMotivoCancelacion(key) },
          // { key: "incentivos", label: "incentivos", width: 200, render: key => this.renderIncentivo(key) },
          { key: "key_usuario", label: "cliente", width: 200, render: key => `${users[key]?.Nombres} ${users[key]?.Apellidos}` },
          { key: "key_conductor", label: "Driver", width: 200, render: key => key ? `${users[key]?.Nombres} ${users[key]?.Apellidos}` : "" },
          { key: "key", label: "pedido", width: 100, component: (key) => <SButtom onPress={() => SNavigation.navigate("/pedido/profile", { pk: key })}>VER</SButtom> },
        ]
      }
      data={this.state.data} />
  }

  render() {
    return <SPage
      title={`Reporte de pedidos no recogidos y cancelados desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
      disableScroll
    >{this.renderTable()}</SPage>
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);