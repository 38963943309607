import React, { Component } from 'react';
import { SHr, SPage, SText, SList, SDate, SView, STable2, SLoad, SNavigation } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../../Model'
import { connect } from 'react-redux';
// import Container from '../Components/Container';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key_restaurante = SNavigation.getParam("key_restaurante")
        this.dia = SNavigation.getParam("dia")
        this.key_horario = SNavigation.getParam("pk")
    }
    componentDidMount() {
        this.getHistoricoHorario()
    }

    getHistoricoHorario() {
        let type
        let data

        if (this.key_restaurante || this.dia) {
            type = "horariohistorico";
            data = { key_restaurante: this.key_restaurante, dia: this.dia }
        } else {
            type = "horarioHistoricoKeyHorario";
            data = { key_horario: this.key_horario }
        }

        SSocket.sendPromise({
            component: "horario",
            type: type,
            data: data
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            console.error(e);
        })
    }

    getDiaSemana(dia) {
        switch (dia) {
            case 0: return "Lunes";
            case 1: return "Martes";
            case 2: return "Miercoles";
            case 3: return "Jueves";
            case 4: return "Viernes";
            case 5: return "Sabado";
            case 6: return "Domingo";
            default: return "Dia Desconocido";
        }
    }

    renderTable() {
        // let users = Model.usuario.Action.getAll();
        let restaurants = Model.restaurante.Action.getAll();

        if (!this.state.data) return <SLoad />
        // if (!users) return <SLoad />
        if (!restaurants) return <SLoad />

        return <STable2
            header={[
                { key: "key", label: "key", width: 250, },
                // { key: "key_usuario", label: "usuario", width: 150, render: a => a ? users[a]?.Nombres + " " + users[a]?.Apellidos : "No se pillo el usuario" },
                { key: "usuario", label: "cliente", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                { key: "fecha_on", label: "fecha on", width: 150, order: "desc", render: a => new SDate(a, "yyyy-MM-ddThh:mm:ss").toString() },
                // { key: "estado", label: "estado", width: 50, },
                { key: "hora_inicio", label: "hora\ninicio", width: 50, },
                { key: "hora_fin", label: "hora\nfin", width: 50, },
                { key: "key_restaurante", label: "restaurante", width: 200, render: a => a ? restaurants[a]?.nombre : "No se pillo el restaurante" },
                { key: "dia", label: "dia", width: 50, render: a => this.getDiaSemana(a) },
                { key: "precio", label: "precio", width: 80, },
                { key: "cantidad", label: "cantidad", width: 80, },
                { key: "precio_original", label: "precio original", width: 100, },
                // { key: "estado_horario", label: "estado horario", width: 100, },
                { key: "key_horario", label: "key horario", width: 250, },
                { key: "key_pack", label: "key pack", width: 200, },
                { key: "porcentaje_comision", label: "porcentaje\ncomision", width: 80, },
                { key: "porcentaje_comision_online", label: "porcentaje comision online", width: 150, },
                { key: "porcentaje_comision_efectivo", label: "porcentaje comision efectivo", width: 150, },
            ]}
            data={this.state.data}
        />;
    }

    render() {
        return (
            <SPage disableScroll title={this.key_restaurante}>
                {this.renderTable()}
            </SPage>
        );
    }

}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);
