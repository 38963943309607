import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SText } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/activaciones_ofertadas.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin, ""]
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
            console.log(e.data);
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            rowHeight={25}
            limit={20}
            header={
                [
                    { key: "index", label: "#", width: 50 },
                    { key: "fecha", label: "Fecha", width: 100, center: true },
                    { key: "cantidad_tapekes_vendidos", label: "Tapekes Vendidos", width: 100, center: true },
                    { key: "cantidad_tapekes_no_vendidos", label: "Tapekes No vendidos", width: 100, center: true },
                    { key: "cantidad_tapekes_total", label: "Tapekes Total", width: 100, center: true },
                    // { key: "nombre", label: "Nombre Comercio", width: 200 },
                    // { key: "cantidad_pedido", label: "Cantidad Pedido", width: 100, order: "desc" },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de ACTIVACIONES OFERTADAS ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);