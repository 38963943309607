import { SView, SImage, SText, SIcon, SPopup, SNavigation, SLoad } from 'servisofts-component';
import DPA from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import { connect } from 'react-redux';
import restaurante from '../../restaurante';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            // params: ["key_restaurante"],
            excludes: ["key", "fecha_on", "key_usuario", "estado"],
            onRefresh: (resolve) => {
                Model.restaurante_mas_pedido.Action.CLEAR();
                resolve();
            }
        });

        this.state = {};
    }

    componentDidMount() {
        Parent.model.Action.CLEAR();

        SSocket.sendPromise({
            component: "restaurante",
            type: "getAll",
        }).then(e => {
            this.setState({ restaurante: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }


    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0;
    }

    $order() {
        return [{ key: "index", order: "asc" }]
    }

    $getData() {
        return Parent.model.Action.getAll();
    }

    onEdit(obj) {
        SNavigation.navigate(Parent.path + "/edit", { pk: obj.key })
    }

    onDelete(obj) {
        if (this.deletePermiso) {
            SPopup.confirm({
                title: "Eliminar Restaurante más pedido",
                message: "¿Seguro que desea remover Restaurante más pedido?",
                onPress: () => {
                    Parent.model.Action.editar({
                        data: {
                            // ...obj,  
                            key: obj.key,
                            estado: 0
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }
            })
        } else {
            SPopup.alert("No tiene permisos para eliminar el Popup Inicio")
        }
    }

    $item(obj) {

        this.deletePermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
        this.editPermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" });

        if (!this.state.restaurante) return <SLoad />

        return <>
            <SView card>
                <SView padding={10} row>
                    <SView width={100} height={100} >
                        <SImage src={Model.restaurante._get_image_download_path(SSocket.api, obj.key_restaurante)} />
                    </SView>

                    <SView row center flex
                        style={{
                            marginLeft: 10,
                            justifyContent: "space-between"
                        }}
                    >
                        <SView >
                            <SText>Index: {obj.index}</SText>
                            <SText>Restaurante: {this.state?.restaurante[obj.key_restaurante].nombre}</SText>
                        </SView>

                        <SView row>
                            <SView style={{ marginRight: "10px" }} onPress={() => this.onEdit(obj)}>
                                {this.editPermiso ? <SIcon name={"Edit"} height={30} width={30}></SIcon> : <SView />}
                            </SView>

                            <SView onPress={() => this.onDelete(obj)}>
                                {this.deletePermiso ? <SIcon name={"Delete"} height={30} width={30}></SIcon> : <SView />}
                            </SView>
                        </SView>
                    </SView>

                </SView>
            </SView>
        </>
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);