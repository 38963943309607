import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'LUGARES DE PEDIDOS'}>
                <SHr height={32} />
                <MenuPages path={"/reporte/app/lugares_pedidos"}  >
                    <MenuButtom label='MAPA DE CALOR POR PEDIDO RESTAURANTE' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/app/lugares_pedidos/lugares_pedidos_restaurante");
                    }} />

                    <MenuButtom label='MAPA DE CALOR POR PEDIDO PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/app/lugares_pedidos/lugares_pedidos_cliente");
                    }} />

                </MenuPages>
                <SHr height={32} />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);