import React, { Component } from 'react';
import { SPage, SView, SText, SIcon, STheme, SForm, SInput, SNavigation, STable, SLoad, SButtom, SPopup, SMarker } from 'servisofts-component';
import SSocket from "servisofts-socket";
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { View } from 'react-native';
import Model from '../../Model';
class Mapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: false,
        };
        this.circles = {};
    }

    createCircle(obj) {
        if (this.circles[obj.key]) return;
        this.circles[obj.key] = new this.maps.Circle({
            id: obj.key,
            map: this.map,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            center: {
                lat: (!obj.latitude ? 0 : obj.latitude),
                lng: (!obj.longitude ? 0 : obj.longitude)
            },
            radius: (!obj.radio ? 100 : obj.radio),
        })
        this.circles[obj.key].addListener('click', (evt) => {

            this.select(obj.key);
        })
    }
    select(key) {

        this.unselect(null);
        this.map.panTo({
            lat: this.data[key].latitude,
            lng: this.data[key].longitude
        });
        console.log(this.allowEdit)
        if (this.allowEdit) {
            this.circles[key].setEditable(true);
        }
        this.circles[key].setMap(this.map);

        this.setState({ select: key })
    }
    reset(key) {
        var obj = this.data[key];
        this.circles[key].setRadius((!obj.radio ? 100 : obj.radio));
        this.circles[key].setCenter({
            lat: (!obj.latitude ? 0 : obj.latitude),
            lng: (!obj.longitude ? 0 : obj.longitude)
        });
    }
    unselect(map) {
        Object.keys(this.circles).map((key) => {
            this.circles[key].setMap(map);
            this.circles[key].setEditable(false);
        })
        this.setState({ select: false })
    }
    onLoadMap() {
        if (!this.data) return;
        if (!this.map) return;
        Object.keys(this.data).map((key) => {
            var obj = this.data[key];
            if (obj.estado != "1") {
                if (this.circles[obj.key]) {
                    this.circles[obj.key].setMap(null);
                    delete this.circles[obj.key];
                }
                return;
            }
            this.createCircle(obj);
        })

    }
    getContentOnEdit() {
        if (!this.state.select) return <View />
        var obj = this.data[this.state.select];
        return <SView style={{
            position: 'absolute',
            top: 8,
            height: 80,
            backgroundColor: STheme.color.primary,
            borderRadius: 5,
        }} col={"xs-11 md-8 xl-4"} center>
            <SView xs={"xs-11"} center flex={1.8}>
                <SText fontSize={16} bold center> {obj.nombre}</SText>
            </SView>
            <SView col={"xs-11"} flex row style={{
            }}>
                <SView col={"xs-6 md-3"} center>
                    <SButtom
                        props={{
                            type: "outline"
                        }}
                        style={{
                            width: 60,
                            height: 25
                        }} onPress={() => {
                            this.reset(this.state.select);
                            this.unselect(this.map);
                        }}>
                        {"Salir"}
                    </SButtom>
                </SView>
                <SView col={"xs-6 md-3"} center>
                    <SButtom
                        props={{
                            type: "outline"
                        }}
                        style={{
                            width: 60,
                            height: 25
                        }} onPress={() => {
                            SNavigation.navigate("zona/perfil", { key: obj.key })
                        }}>
                        {"Ver"}
                    </SButtom>
                </SView>
                <SView col={"xs-6 md-3"} center>
                    {!this.allowDelete ? null : <SButtom
                        props={{
                            type: "danger",
                            variant: "confirm",
                        }}
                        style={{
                            width: 60,
                            height: 25
                        }}
                        onPress={() => {
                            var obj = this.data[this.state.select];
                            obj.estado = 0;
                            Model.zona.Action.editar({
                                data: obj,
                                key_usuario: Model.usuario.Action.getKey()
                            })
                            this.unselect(this.map);
                        }}
                    >
                        {"Eliminar"}
                    </SButtom>}
                </SView>
                <SView col={"xs-6 md-3"} center>
                    {!this.allowEdit ? null : <SButtom
                        props={{
                            type: "outline"
                        }}
                        style={{
                            width: 60,
                            height: 25
                        }} onPress={() => {
                            var obj = this.data[this.state.select];
                            var newCenter = this.circles[this.state.select].getCenter();
                            var newRadius = this.circles[this.state.select].getRadius();
                            if (newRadius != obj.radio || newCenter.lat() != obj.latitude || newCenter.lng() != obj.longitude) {
                                obj.radio = newRadius.toFixed(0);
                                obj.latitude = newCenter.lat();
                                obj.longitude = newCenter.lng();
                                Model.zona.Action.editar({
                                    data: obj,
                                    key_usuario: Model.usuario.Action.getKey()
                                })
                                this.unselect(this.map);
                            }
                        }}>
                        {"Guardar"}
                    </SButtom>}
                </SView>

            </SView>
        </SView>

    }
    getRestaurantes() {
        const restaurantes = Model.restaurante.Action.getAll();
        return Object.values(restaurantes).map((obj) => {
            return <SMarker lat={obj.latitude} lng={obj.longitude} >
                <SView width={40} height={40} >
                    <SIcon name='Marker' fill={"#000"} />
                    <SText fontSize={8}>{obj.nombre}</SText>
                </SView>
            </SMarker>
        })
    }
    getMapa() {
        const restaurantes = Model.restaurante.Action.getAll();
        if (!this.data) return <SLoad />
        if (!restaurantes) return <SLoad />
        return <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAt-U-cFcg5QNfPAvQW5YWT1d1SbEJFNuM" }}
            defaultCenter={{
                lat: -17.7799998333333332,
                lng: -63.180598333333336
            }}
            defaultZoom={13}
            options={{
                styles: STheme.color.mapStyle,
                disableDefaultUI: true,


            }}
            onClick={() => {

            }}
            onGoogleApiLoaded={({ map, maps }) => {
                this.map = map;
                this.maps = maps;
                this.onLoadMap();
            }}
        >
            {this.getRestaurantes()}
        </GoogleMapReact>
    }
    getButtomAdd() {
        let allowCreate = Model.usuarioPage.Action.getPermiso({ url: "/zona", permiso: "new", loading: false });
        if (!allowCreate) return null;
        return <SView style={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            height: 50,
            width: 50,
        }} onPress={() => {
            SPopup.open({
                key: "addZona",
                content: (<SView col={"xs-12 md-8 xl-6"} backgroundColor={STheme.color.background} style={{ borderRadius: 8 }} center withoutFeedback>
                    <SView col={"xs-11"} center>
                        <SView col={"xs-12"} height={16}></SView>
                        <SText fontSize={16} bold center>{"Nueva Zona"}</SText>
                        <SInput ref={ref => this.inpNameZona = ref} props={{ customStyle: "primary", label: "Nombre" }} />
                        <SView col={"xs-12"} height={16}></SView>
                        <SView col={"xs-12"} row center>
                            <SView col={"xs-6"} center>
                                <SButtom props={{ type: "danger" }} onPress={() => { SPopup.close("addZona") }}>Cancelar</SButtom>
                            </SView>
                            <SView col={"xs-6"} center>
                                <SButtom props={{ type: "outline" }} onPress={() => {
                                    if (!this.inpNameZona) {
                                        SPopup.alert("error");
                                    }
                                    if (this.inpNameZona.verify()) {
                                        var newCenter = this.map.getCenter();

                                        var obj = {
                                            nombre: this.inpNameZona.getValue(),
                                            estado: 1,
                                            radio: 1000,
                                            latitude: 0,
                                            longitude: 0
                                        }
                                        obj.latitude = newCenter.lat();
                                        obj.longitude = newCenter.lng();
                                        Model.zona.Action.registro({
                                            data: obj,
                                            key_usuario: Model.usuario.Action.getKey()
                                        })
                                        SPopup.close("addZona");
                                    }
                                }}>Guardar</SButtom>
                            </SView>
                        </SView>
                        <SView col={"xs-12"} height={16}></SView>
                    </SView>
                </SView>)
            })
        }}>
            <SIcon name={"Add"} />
        </SView>
    }

    render() {
        this.data = Model.zona.Action.getAll();
        this.onLoadMap();
        this.allowEdit = Model.usuarioPage.Action.getPermiso({ url: "/zona", permiso: "edit", loading: false });
        this.allowDelete = Model.usuarioPage.Action.getPermiso({ url: "/zona", permiso: "delete", loading: false });
        return (
            <SPage
                title={`Zonas`}
                disableScroll
            >
                <SView center height >
                    {this.getMapa()}
                    {this.getContentOnEdit()}
                    {this.getButtomAdd()}
                </SView >

            </SPage>
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Mapa);