import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SLoad, SPopup, SText, STheme, SView } from 'servisofts-component'
import Model from '../../../Model'
import SList from 'servisofts-component/Component/SList2'

export default class HabilitarDriver extends Component {
    hendlePress = (enabled) => {

        if (!this.allowAcces) {
            SPopup.alert("No tienes permisos para esta accion.")
            return;
        }
        if (!enabled) {
            SPopup.confirm({
                title: "Esta seguro que todos los documentos del driver estan correctos y cumplen con las politicas de Tapeke.",
                message: "Esta accion quedara registrada.",
                onPress: () => {
                    Model.usuario.Action.editar({
                        data: {
                            ...this.data,
                            enable: !enabled
                        }
                    })
                }
            })
            return;
        }
        SPopup.confirm({
            title: "Esta seguro que quieres deshabilitar este driver?",
            message: "Esta accion quedara registrada.",
            onPress: () => {
                Model.usuario.Action.editar({
                    data: {
                        ...this.data,
                        enable: !enabled
                    }
                })
            }
        })

    }
    renderCuentas() {
        let cuentas = Model.driver_cuenta.Action.getAllBy({ key_conductor: this.props.key_usuario });
        console.log(cuentas)
        return <SView col={"xs-12"}>
            <SText bold fontSize={16} >Cuentas bancarias</SText>
            <SHr />
            <SList data={cuentas}
                render={(obj) => {
                    return <SView col={"xs-12"} card padding={4}>
                        <SText fontSize={18} bold>{obj.numero_cuenta}</SText>
                        <SHr h={4} />
                        <SText fontSize={12} bold color={STheme.color.gray}>{obj.banco} / {obj.sucursal}</SText>
                        <SText fontSize={12} color={STheme.color.gray}>{obj.ci} {obj.nombre}</SText>
                    </SView>
                }} />
        </SView>
    }
    render() {
        this.roles = Model.usuarioRol.Action.getAllByKeyUsuario(this.props.key_usuario);
        this.data = Model.usuario.Action.getByKey(this.props.key_usuario);
        if (!this.data) return <SLoad />
        if (!this.roles) return <SLoad />
        this.allowAcces = Model.usuarioPage.Action.getPermiso({ url: "/usuario", permiso: "verificar_driver" })
        let rol_driver = Object.values(this.roles).find(a => a.key_rol == "b8920e90-1cbd-4fac-b740-62fac4d22bbd");
        if (!rol_driver) return null
        let enabled = JSON.parse(this.data?.enable ?? false)

        return (
            <SView col={"xs-12"}>
                {this.renderCuentas()}
                <SHr />
                <SText bold fontSize={16}>Verificacion de driver</SText>
                <SHr />
                <SText bold color={!!enabled ? STheme.color.success : STheme.color.warning}>{!enabled ? "Sin verificar" : "Verificado"}</SText>
                <SHr />
                <SView row col={"xs-12"} center>
                    <SView card padding={16} onPress={this.hendlePress.bind(this, enabled)} style={{
                        borderColor: !enabled ? STheme.color.success : STheme.color.danger,
                        borderWidth: 1
                    }}>
                        <SText bold>{!enabled ? "HABILITAR" : "DESHABILITAR"}</SText>
                    </SView>
                </SView>
            </SView>
        )
    }
}