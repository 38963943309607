import DPA, { connect } from 'servisofts-page';
import { Parent } from "./index"
import Model from "../../../../Model";
import { SHr, SIcon, SNavigation, SText, SView, SPopup } from 'servisofts-component';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            limit: 10,
            excludes: ["key", "estado", "key_usuario"],
        });

    }
    $allowNew() {
        // return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
        return true
    }

    $allowAccess() {
        // return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
        return true

    }
    $filter(data) {
        if (this.filter) return this.filter(data)
        return data.estado != 0
    }


    $getData() {
        let restaurantes = Model.restaurante.Action.getAll();
        let restaurante_excluir_accion_global = Model.restaurante_excluir_accion_global.Action.getAll();

        if (!restaurante_excluir_accion_global || !restaurantes) return null;

        return Object.values(restaurante_excluir_accion_global).map(obj => {
            obj.restaurante = restaurantes[obj?.key_restaurante]
            return obj
        })
    }

    $menu() {
        var menu = super.$menu();
        if (Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" })) {
            menu.push({
                label: "Eliminados", onPress: () => {
                    console.log(Parent.path)
                    SNavigation.navigate(Parent.path + "/eliminados")
                }
            })
        }
        return menu;
    }

    onDelete(obj) {
        SPopup.confirm({
            title: "Eliminar Exclusion",
            message: "¿Seguro que desea remover la exclusion?",
            onPress: () => {
                Model.restaurante_excluir_accion_global.Action.editar({
                    data: {
                        key: obj?.key,
                        estado: 0
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })
    }

    $item(data) {
        return <SView card row padding={10}>
            <SView flex>
                <SText>Restaurante: <SText>{data.restaurante.nombre}</SText></SText>
                <SHr />
                <SText>Acción: <SText>{data.accion}</SText></SText>
            </SView>
            <SView row>
                <SView
                    onPress={() => {
                        this.onDelete(data);
                    }}
                >
                    <SIcon name='Delete' width={30} />
                </SView>
            </SView>
        </SView>
    }

    $order() {
        return [{ key: "accion", order: "asc", type: "text" }];
    }
}
export default connect(index);