import DPA, { connect } from 'servisofts-page';
import { Parent } from "./index"
import { SDate, SHr, SIcon, SImage, SList, SLoad, SMath, SText, STheme, SView } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.item {
    sizeImage = 32
    constructor(props) {
        super(props, {
            Parent: Parent,
            padding: 0,
            // row:false
        });
    }
    $renderContent() {
        const { descripcion } = this.data
        const usuario = Model.usuario.Action.getByKey(this.data.key_usuario)

        return <SView col={"xs-12"} style={{
            overflow: "hidden",
            padding: 8,
        }} center >
            <SText fontSize={16} bold>{descripcion}</SText>
            <SText fontSize={12} color={STheme.color.lightGray} center>{this.data?.producto || this.data?.producto > 0 ? "Descuento Producto" : "Descuento Delivery"}</SText>
            <SText fontSize={12} color={STheme.color.lightGray} center>monto: {this.data.monto}</SText>
            <SText fontSize={12} color={STheme.color.lightGray} center>porcentaje: {this.data.porcentaje * 100} %</SText>
            <SText fontSize={12} color={STheme.color.lightGray} center>creado por: {usuario ? `${usuario.Nombres} ${usuario.Apellidos}` : 'no cargo el usuario'}</SText>
            <SHr />
            <SText fontSize={12}>{`Desde ${new SDate(this.data?.fecha_inicio, "yyyy-MM-ddThh:mm:ss").toString("dd de MONTH del yyyy a las hh:mm")}`}</SText>
            <SText fontSize={12}>{`Hasta ${new SDate(this.data?.fecha_fin, "yyyy-MM-ddThh:mm:ss").toString("dd de MONTH del yyyy a las hh:mm")}`}</SText>
        </SView>
    }
}
export default connect(index);