import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SDate, SPage, STable2 } from 'servisofts-component';
import SSocket from 'servisofts-socket';

export default class tokens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const respNotify = await SSocket.sendPromise({
            service: "notification",
            component: "firebase_token",
            type: "getAll"
        })
        const usuariosResp = await SSocket.sendPromise({
            service: "usuario",
            version: "2.0",
            component: "usuario",
            type: "getAll",
            cabecera: "%%"
        })

        this.state.usuarios = usuariosResp.data;

        this.setState({ data: respNotify.data })
    }
    render() {
        return <SPage disableScroll title={"Tokens de firebase"}>
            <STable2
                data={this.state.data}
                rowHeight={20}
                cellStyle={{
                    padding: 2,
                }}
                limit={50}
                header={[
                    {
                        key: "ultima_conexion", width: 120,
                        order: "desc",
                        render: e => new SDate(e).toString("yyyy-MM-dd hh:mm:ss")
                    },
                    // { key: "index", width: 50 },
                    { key: "app", width: 50 },
                    { key: "platform", width: 50 },
                    { key: "tags/app", width: 50 },
                    { key: "estado", width: 50 },
                    { key: "tags/app_version", width: 50 },
                    // { key: "tags/user_type", width: 50 },
                    { key: "tags/key_usuario-k", width: 50, },
                    {
                        key: "tags/key_usuario", width: 200, render: a => {
                            if (!a) return ""
                            const usr = this.state.usuarios[a]
                            return `${usr?.Nombres} ${usr?.Apellidos}`
                        }
                    },
                    // { key: "tags/platform", width: 50 },
                    { key: "tags/device_id", width: 150 },
                    { key: "tags/tester", width: 50 },
                    { key: "descripcion", width: 300 },


                ]}
            />
        </SPage>
    }
}
