import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "popup_inicio"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" },
        "estado": { type: "integer" },
        
        "cantidad": { type: "integer", notNull: true,editable: true, label: "Cantidad" },
        "tiempo_reinicio": { type: "integer", editable: true, label: "Tiempo Reinicio en Min" },
        "index": { type: "integer", notNull: true, editable: true, label: "Index" },
        "tiempo": { type: "integer", editable: true, label: "Tiempo para que cambie automático en MiliSegundos" },
        "url": { type: "text", editable: true, label: "url" },
        "params": { type: "text", editable: true, label: "params" },
    },
    image: {
        api: "root",
        name: "popup_inicio"
    },
    Action,
    Reducer,
});