import DPA, { connect } from 'servisofts-page';
import { SDate, SHr, SList, SLoad, SNavigation, SPopup, SText, SView, SIcon } from 'servisofts-component';

import Model from '../../../../Model'

const Parent = {
    name: "Tags",
    path: "/restaurante_tag",
    model: Model.restaurante_tag
}

class index extends DPA.list {


    constructor(props) {
        super(props, {
            Parent: Parent,
            type: "componentTitle",
            excludes: ["key", "fecha_on", "key_usuario", "estado", "Password", "key_restaurante"],
        });

        this.deletePermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    onNew() {
        SNavigation.navigate("/restaurante/tag", {
            filter: (obj) => {
                let item = Object.values(this.data).find(a => a.key_restaurante == this.props.key_restaurante && a.key_tag == obj.key && a.estado > 0)
                if (item) {
                    return false
                }
                return true;
            },
            onSelect: (obj) => {
                if (!obj) return;
                let item = Object.values(this.data).find(a => a.key_restaurante == this.props.key_restaurante && a.key_tag == obj.key && a.estado > 0)
                if (item) {
                    SPopup.alert("El tag ya se encuentra registrado.")
                    return;
                }

                Model.restaurante_tag.Action.registro({
                    data: {
                        key_restaurante: this.props.key_restaurante,
                        key_tag: obj.key,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })
    }

    $onSelect(obj) {
        if (this.deletePermiso) {
            SPopup.confirm({
                title: "Eliminar Tag",
                message: "¿Seguro que desea remover el tag?",
                onPress: () => {
                    Model.restaurante_tag.Action.editar({
                        data: {
                            // ...obj,
                            key: obj.key,
                            estado: 0
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }
            })
        } else {
            SPopup.alert("No tiene permisos para eliminar el tag")
        }
    }


    $filter(data) {
        return data.estado != 0 && data.key_restaurante == this.props.key_restaurante;
    }

    $item(obj) {
        let tag = Model.tag.Action.getByKey(obj.key_tag);
        return <>
            <SView flex>
                <SView flex row card style={{
                    padding: 8,
                    alignItems: "center",
                    justifyContent: "space-between"
                }} onPress={() => this.$onSelect(obj)}>
                    <SText fontSize={16}>{tag?.descripcion}</SText>
                    {this.deletePermiso ? <SIcon name={"Delete"} height={30} width={30}></SIcon> : <SView />}
                </SView>
            </SView>
        </>
    }

    $getData() {
        let data = Parent.model.Action.getAll();
        if (!data) return null;
        this.data = data;
        return data;
    }

}
export default connect(index);