import { SPage } from "servisofts-component";
import root from "./root";
import horario from "./horario";
import partners from "./partners";
import pedidos from "./pedidos";
import cuentas from "./cuentas";
import tags from "./tags";
import producto from "./producto";
import acciones from "./acciones";

export default SPage.combinePages("profile", {
    "": root,
    horario,
    partners,
    pedidos,
    cuentas,
    tags,
    producto,
    acciones
})
