import React, { Component } from 'react';
import { SInput, SText, SView } from 'servisofts-component';

type propsType = {

}

const options_day = [
    { key: "", content: "--" },
    { key: "0", content: "Lunes" },
    { key: "1", content: "Martes" },
    { key: "2", content: "Miercoles" },
    { key: "3", content: "jueves" },
    { key: "4", content: "Viernes" },
    { key: "5", content: "Sabado" },
    { key: "6", content: "Domingo" },
]

export default class InputDay extends Component<propsType> {
    static defaultProps: propsType = {

    }
    props: propsType;
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    verify(){
        return this.dia.verify()
    }
    getValue(){
       return this.dia.getValue()
    }
    render() {
        return <SInput {...this.props} type={"select"} options={options_day} ref={ref => this.dia = ref} />
    }
}