import { SButtom, SNavigation, SText, STheme, SView } from 'servisofts-component';
import DPA from 'servisofts-page';
import { Parent } from "."
import MenuOption from '../../Components/MenuOption';
import Model from '../../Model';
import { connect } from 'react-redux';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado", "latitude", "longitude", "descripcion", "porcentaje_comision_efectivo", "porcentaje_comision_online", "nombre_representante", "delivery", "capacidad_envio"],
            onRefresh: (resolve) => {
                Model.restaurante.Action.CLEAR();
                resolve();
            }
        });

        this.filter = SNavigation.getParam("filter");

        this.state = {
            select: {
                "pendientes": true,
                "aprobados": true,
            },
        }
    }

    componentWillUnmount() {
        if (this.$params.onSelect) {
            this.$params.onSelect(null)
        } else if (this.onSelect) {
            this.onSelect(null)
        }
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }


    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        if (this.state?.select?.aprobados) {
            if (data.estado == 1) {
                if (this.filter) return this.filter(data)
                return true;
            }
        }

        if (this.state?.select?.bloqueados) {
            if (data.estado == 2) return true;
        }

        return false;
    }

    $getData() {
        this.data = Parent.model.Action.getAll();
        return this.data;
    }

    buttonTags() {
        return <>
            <SButtom
                style={{ width: 150 }}
                onPress={() => {
                    SNavigation.navigate("/restaurante/tag")
                }}>
                <SText
                    style={{
                        color: STheme.color.text,
                        fontSize: 12,
                    }}
                >Tags Restaurantes</SText>
            </SButtom>
        </>
    }

    buttonCategoriasProductos() {
        return <>
            <SButtom style={{ width: 150 }} onPress={() => {
                SNavigation.navigate("/restaurante/categoria_producto", {
                    filter: (data) => {
                        if (data.key_restaurante == null && data.estado != 0) return true;
                        return false
                    },
                })
            }}>
                <SText
                    style={{
                        color: STheme.color.text,
                        fontSize: 12,
                    }}
                >Categoria Prod Globales</SText>
            </SButtom>
        </>
    }

    buttonRestauranteAccionGlobal() {
        return <>
            <SButtom style={{ width: 100 }} onPress={() => {
                SNavigation.navigate("/restaurante/restaurante_accion_global")
            }}>
                <SText
                    style={{
                        color: STheme.color.text,
                        fontSize: 12,
                    }}
                >Acciónes Global</SText>
            </SButtom>
        </>
    }

    $menu() {
        let menu = super.$menu();
        // menu.push({ children: <MenuOption id={"pendientes"} label={"Pendientes"} color={STheme.color.warning} parent={this} /> })
        menu.push({ children: this.buttonTags() })
        menu.push({ children: this.buttonCategoriasProductos() })
        menu.push({ children: this.buttonRestauranteAccionGlobal() })
        menu.push({ children: <MenuOption id={"aprobados"} label={"Aprobados"} color={STheme.color.success} parent={this} /> })
        menu.push({ children: <MenuOption id={"bloqueados"} label={"Bloqueados"} color={STheme.color.danger} parent={this} /> })
        return menu;
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);