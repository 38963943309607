import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SDate } from 'servisofts-component';
import Model from '../../../Model';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Editar Descuento",
            excludes: ["key", "fecha_on", "key_usuario", "estado"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inp = super.$inputs();

        inp["fecha_inicio"].label = "Fecha de Inicio"
        inp["fecha_inicio"].type = "date"
        inp["fecha_inicio"].defaultValue = new SDate(inp["fecha_inicio"].defaultValue, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd");

        inp["fecha_fin"].label = "Fecha de expiración"
        inp["fecha_fin"].type = "date"
        inp["fecha_fin"].defaultValue = new SDate(inp["fecha_fin"].defaultValue, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd");

        inp["porcentaje"].type = "number"
        inp["porcentaje"].defaultValue = parseFloat(inp["porcentaje"].defaultValue ?? 0).toFixed(2) * 100;

        return inp;
    }
    $onSubmit(data) {
        data.fecha_fin = data.fecha_fin + " 23:59:59"
        if (data.porcentaje <= 0) {
            SPopup.alert("El porcentaje no puede ser 0")
            return;
        }

        data.porcentaje = data.porcentaje / 100
        
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);