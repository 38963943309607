import DPA, { connect } from 'servisofts-page';
import { Parent } from './';
import { SHr, SInput, SNavigation, SPopup } from 'servisofts-component';
import Model from "../../../../Model";
import PButtom from '../../../../Components/PButtom';
import restaurante from '../../../../Model/tapeke/restaurante';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: [],
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_usuario_off", "fecha_off", "accion"]
        });

        this.state = {
            restaurante: null,
            loading: false
        };
    }
    $allowAccess() {
        // return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
        return true
    }

    $inputs() {
        var inp = super.$inputs();

        inp["key_restaurante"].onPress = (val) => {
            SNavigation.navigate("/restaurante/list", {
                onSelect: (val) => {
                    if (!val) return
                    this.setState({ restaurante: val })
                }
            })
        }

        inp["key_restaurante"].value = this.state.restaurante ? this.state.restaurante.nombre : null;

        return inp;
    }

    $submitName() {
        return null
    }

    $onSubmit(data) {
        this.setState({ loading: true });

        if (!this.state.restaurante?.key) {
            this.setState({ loading: false });
            return SPopup.alert("Seleccione una restaurante");
        }

        data.key_restaurante = this.state.restaurante.key;

        if (!this.tipo_accion.getValue()) {
            this.setState({ loading: false });
            return SPopup.alert("Seleccione una tipo de acción");
        }

        data.accion = this.tipo_accion.getValue()

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            SPopup.alert("Error: " + e.error)
            console.error(e);
            this.setState({ loading: false });
        })
    }

    $footer() {
        return <>
            <SInput ref={ref => this.tipo_accion = ref}
                type='select'
                options={["", { key: "desactivar_delivery", content: "Desactivar Delivery" }]}
                col={"xs-12"}
                label={"Tipo de acción a excluir"}
            />
            <SHr h={15}/>
            <PButtom
                danger={true}
                loading={this.state.loading}
                fontSize={20}
                onPress={() => { this.form.submit() }}
            >
                Excluir Restaurante
            </PButtom>
        </>
    }
}

export default connect(index);