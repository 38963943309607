import React from "react";
import { SForm, SHr, SNavigation, SNotification, SPage, SText, STheme, Upload } from "servisofts-component";
import Container from "../../../Components/Container";
import SSocket from "servisofts-socket";
import Model from "../../../Model";
// import insignia from ".";
import root from "./root";

export default class index extends React.Component {
    state = {
        pk: SNavigation.getParam("pk"),
        key_insignia: SNavigation.getParam("key_insignia"),
        data: {},
        load: false
    }
    form: SForm;

    componentDidMount() {
        if (this.state.pk) {
            SSocket.sendPromise({
                component: "recompensa_insignia",
                type: "getByKey",
                key: this.state.pk
            }).then(e => {
                this.setState({ data: e.data, load: true })
            }).catch(e => {

            })
        } else {
            this.setState({ load: true })
        }
    }

    subirFotos({ url = SSocket.api.root + "upload", folder = "/" }) {
        const files = this.form.getFiles();
        Object.keys(files).map(k => {
            const file = files[k];
            if (!file) return;
            if (typeof file != "object") return;
            if (!file[0]) return;
            const finalUrl = url + folder + "/" + k;
            SNotification.send({
                key: finalUrl,
                title: "Subiendo archibo",
                body: finalUrl,
                type: "loading",
            })

            Upload.sendPromise(file[0], finalUrl).then(e => {
                SNotification.remove(finalUrl);
            }).catch(e => {
                SNotification.remove(finalUrl);
                SNotification.send({
                    key: finalUrl,
                    title: "Error al subir el archibo",
                    body: finalUrl,
                    color: STheme.color.danger,
                    time: 5000,
                })
            })
        })
    }
    render() {
        return <SPage title={"Editar insignia"}>
            <Container loading={!this.state.load}>
                <SHr />
                <SForm
                    ref={ref => this.form = ref}
                    style={{
                        justifyContent: "space-between"
                    }}
                    row
                    inputs={{
                        // "enable": { type: "image", col: "xs-5.5", label: "Foto habilitado", defaultValue: SSocket.api.root + "insignia/" + this.state.pk + "/enable" },
                        // "disable": { type: "image", col: "xs-5.5", label: "Foto deshabilitado", defaultValue: SSocket.api.root + "insignia/" + this.state.pk + "/disable" },
                        "tipo_recompensa": { label: "Tipo de Recompensa", type: "select", defaultValue: this.state?.data?.tipo_recompensa, options: ["", "cupon", "abono_billetera", "descuento_delivery", "descuento_producto"] },
                        // "tipo_recompensa": { label: "Tipo", col: "xs-7", type: "select", options: ["permanente", "semanal"], defaultValue: this.state?.data?.tipo },
                        "valor": { label: "Valor ( Solo para abono a billetera )", col: "xs-4.5", defaultValue: this.state?.data?.valor },
                        "descripcion": { label: "Descripcion", type: "textArea", defaultValue: this.state?.data?.descripcion },
                        "observacion": { label: "Observacion", type: "textArea", defaultValue: this.state?.data?.observacion },
                    }}
                    onSubmitName={"GUARDAR"}
                    onSubmit={(values) => {
                        // if (this.state.pk) this.form.uploadFiles(SSocket.api.root + "upload/insignia/" + this.state.pk + "/activa")
                        if (this.state.pk) {
                            // this.subirFotos({ folder: "/insignia/" + this.state.pk + "" });
                            SSocket.sendPromise({
                                component: "recompensa_insignia",
                                type: "editar",
                                data: {
                                    ...this.state.data,
                                    ...values
                                },
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(e => {
                                if (root.INSTANCE) root.INSTANCE.componentDidMount();
                                SNavigation.goBack();
                            }).catch(e => {
                                SNotification.send({
                                    title: "Error",
                                    body: e.error ?? "Error desconocido",
                                    color: STheme.color.danger,
                                    time: 5000,
                                })
                            })
                        } else {
                            SSocket.sendPromise({
                                component: "recompensa_insignia",
                                type: "registro",
                                data: {
                                    ...values,
                                    key_insignia: this.state.key_insignia
                                },
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(e => {
                                // this.subirFotos({ folder: "/insignia/" + e.data.key + "" });
                                if (root.INSTANCE) root.INSTANCE.componentDidMount();
                                SNavigation.goBack();

                            }).catch(e => {
                                SNotification.send({
                                    title: "Error",
                                    body: e.error ?? "Error desconocido",
                                    color: STheme.color.danger,
                                    time: 5000,
                                })
                                console.error(e);
                            })
                        }
                    }}
                />
            </Container>
        </SPage>;
    }
}