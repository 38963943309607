import { SPage } from "servisofts-component";
import list from "./list";
import _new from "./new";
import edit from "./edit";

import Model from "../../../Model";

const model = Model.restaurante_mas_pedido;
export const Parent = {
    name: "restaurante_mas_pedido",
    path: `/marketing/restaurante_mas_pedido`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "new": _new,
    "edit": edit,
})
