import { SIcon, SText, STheme, SView } from "servisofts-component"

const MenuOption = ({ id, label, color, parent }) => {
    let select = !!parent.state.select[id]
    return <SView height={35} center style={{
        paddingLeft: 8,
        paddingRight: 8,
        opacity: select ? 1 : 0.5,
        backgroundColor: color + "AA"
    }} onPress={() => {
        if (!select) {
            parent.state.select[id] = true;
        } else {
            delete parent.state.select[id];
        }
        parent.setState({ ...parent.state })
    }} row>
        {!select ? null : <> <SIcon name={"Close"} width={12} height={12} fill={STheme.color.text} /> <SView width={8} /></>}
        <SText>{label}</SText>
    </SView>
}
export default MenuOption;