import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SPage, SView, SText, SIcon, STheme, SForm, SInput, SNavigation, STable, SLoad, SButtom, SPopup, SMarker, SMapView2, SMapView, SThread, SDate } from 'servisofts-component';
import Model from '../../Model';
class Mapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: false,
        };
        this.circles = {};
        this.isRun = false;
    }
    componentDidMount() {
        this.isRun = true;
        this.hilo();
    }
    componentWillUnmount() {
        this.isRun = false;
    }
    hilo() {
        if (!this.isRun) return;
        // this.state.data = Model.background_location.Action.CLEAR();
        this.setState({ ...this.state })
        new SThread(5000, "hilo_pedidos", true).start(() => {
            if (!this.isRun) return;
            this.hilo();
        })
    }
    render() {
        let usuarios = Model.usuario.Action.getAll();
        let pedidos = Model.pedido.Action.getAllActivos()
        // let conductores = Model.background_location.Action.getAll();
        if (!usuarios) return <SLoad />
        if (!pedidos) {
            pedidos = {};
        }
        return (
            <SPage
                title={`Pedidos`}
                disableScroll
            >
                <SView center height >
                    <SMapView initialRegion={{
                        latitude: -17.783799,
                        longitude: -63.180,
                        latitudeDelta: 0.1,
                        longitudeDelta: 0.1
                    }}>
                        {Object.values(pedidos).filter(a => a.state != "pendiente_pago"
                            && a.state != "timeout_pago"
                            && a.state != "entregado"
                            && a.state != "cancelado"
                            // && a.state != "pagado"
                            // && a.state != "listo"
                        ).map((obj) => {
                            return <SMapView.SMarker latitude={obj?.direccion?.latitude} longitude={obj?.direccion?.longitude} >
                                <SView width={50} height={50} center onPress={() => {
                                    SNavigation.navigate("/pedido/profile", { pk: obj.key })
                                }}>
                                    <SIcon name={"Marker"} fill={STheme.color.text} />
                                    <SText fontSize={10}>{usuarios[obj.key_usuario]?.Nombres}</SText>
                                    <SText fontSize={10}>{obj.state}</SText>
                                    {/* <SText fontSize={10}>{obj.state}</SText> */}
                                </SView>
                            </SMapView.SMarker>
                        })}
                    </SMapView>
                </SView >

            </SPage>
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Mapa);