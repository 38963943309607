import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["type"],
            excludes: ["key", "fecha_on", "key_usuario", "estado", "delivery", "producto","tipo_rango"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var inp = super.$inputs();

        inp["fecha_inicio"].type = "date"
        inp["fecha_fin"].type = "date"

        inp["porcentaje"].type = "number"

        inp["monto"].type = "money"
        inp["monto"].defaultValue = parseFloat(0).toFixed(2);

        inp["cobertura"].type = "number"
        return inp;
    }

    $onSubmit(data) {
        switch (this.$params.type) {
            case "delivery":
                data.delivery = 1;
                data.producto = 0;
                data.tipo_rango = "producto";
                break;
            case "producto":
                data.delivery = 0;
                data.producto = 1;
                data.tipo_rango = "producto";
                break;
            default:
                SPopup.alert("No puede crear este tipo de descuento")
                return;
        }

        let porcentaje = parseFloat(data.porcentaje);

        data.fecha_fin = data.fecha_fin + "T23:59:59.999Z";

        if (!porcentaje) {
            data.porcentaje = 0;
        } else {
            if (porcentaje >= 0 && porcentaje <= 100) {
                data.porcentaje = porcentaje / 100;
            } else {
                SPopup.alert("El porcentaje del descuento tiene que estar entre 0 y 100")
                return;
            }
        }

        let cobertura = parseFloat(data.cobertura);

        if (cobertura >= 0 && cobertura <= 100) {
            data.cobertura = cobertura / 100;
        } else {
            SPopup.alert("El porcentaje de cobertura Tapeke tiene que estar entre 0 y 100")
            return;
        }

        Model.descuento.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);