import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SHr, SIcon, SNavigation, SPage, SPopup, SPopupOpen, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Reportes Comercios'}>
                <SHr height={32} />
                <MenuPages path={"/reporte/comercio"}  >

                    <MenuButtom label='Deshabilitación Tapekes' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/comercio/tapekes_deshabilitados"/* , { key_restaurante: obj.key } */);

                        // SNavigation.navigate("/restaurante", {
                        //     onSelect: (obj) => {
                        //         if (!obj) return;
                        //         SNavigation.navigate("/reporte/partner/tapeke_deshabilitado", { key_restaurante: obj.key });
                        //         return true;
                        //     }
                        // })

                    }} />

                    <MenuButtom label='COMERCIOS MAS PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/comercio/comercios_favoritos_ranking_pedidos", evt);
                        });
                    }} />

                    <MenuButtom label='COMERCIOS MAS CORAZONES' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/comercio/comercios_favoritos_corazones");
                    }} />

                    <MenuButtom label='TIEMPO DE APERTURA PROMEDIO' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.openContainer({
                            render: (props) => {
                                return <SView
                                    flex
                                    center
                                    width={50}
                                    style={{
                                        padding: 15,
                                    }}
                                >
                                    <SHr h={20} />
                                    <SButtom
                                        props={{
                                            type: "outline"
                                        }}
                                        style={{
                                            width: 150,
                                            height: 50
                                        }} onPress={() => {
                                            SPopup.dateBetween("Selecciona las fechas", (evt) => {
                                                evt.key_restaurante = "null";
                                                SNavigation.navigate("/reporte/comercio/tiempo_apertura_promedio", evt);
                                            });
                                            props.close();
                                        }}>
                                        {"Todos los restaurantes"}
                                    </SButtom>
                                    <SHr h={20} />
                                    <SButtom
                                        props={{
                                            type: "outline"
                                        }}
                                        style={{
                                            width: 150,
                                            height: 50
                                        }} onPress={() => {
                                            SNavigation.navigate("/restaurante", {
                                                onSelect: (obj) => {
                                                    if (!obj) return;
                                                    SPopup.dateBetween("Selecciona las fechas", (evt) => {
                                                        evt.key_restaurante = obj.key;
                                                        SNavigation.navigate("/reporte/comercio/tiempo_apertura_promedio", evt);
                                                    });
                                                    return true;
                                                }
                                            });
                                            props.close();
                                        }}>
                                        {"Restaurante específico"}
                                    </SButtom>
                                </SView>
                            }
                        })
                    }} />

                    <MenuButtom label='TAGS DE LOS COMERCIOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/comercio/tags_de_los_comercios");
                    }} />

                    <MenuButtom label='ACTIVACIONES OFERTADAS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/comercio/activaciones_ofertadas", evt);
                        });
                    }} />

                    <MenuButtom label='QUÉ PRODUCTO VENDE MÁS - ITEAMS' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/restaurante", {
                            onSelect: (obj) => {
                                if (!obj) return;
                                SPopup.dateBetween("Selecciona las fechas", (evt) => {
                                    evt.key_restaurante = obj.key;
                                    SNavigation.navigate("/reporte/comercio/ranking_de_productos_mas_vendidos", evt);
                                });
                                return true;
                            }
                        })
                    }} />

                    <MenuButtom label='TICKET PROMEDIO DE OFERTAS' icon={<SIcon name='Excel' />} onPress={() => {
                        // SNavigation.navigate("/restaurante", {
                        // onSelect: (obj) => {
                        // if (!obj) return;
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            // evt.key_restaurante = obj.key;
                            SNavigation.navigate("/reporte/comercio/ticked_promedio_ofertas", evt);
                        });
                        // return true;
                        // }
                        // })
                    }} />

                    <MenuButtom label='PLAN COMERCIAL' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/comercio/porcentaje_de_contratos");
                    }} />

                    <MenuButtom label='COMERCIOS RENTABILIDAD' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/comercio/comercios_rentabilidad", evt);
                        });
                    }} />

                </MenuPages>
                <SHr height={32} />

            </SPage >
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);