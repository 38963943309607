import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Reportes Cliente'}>
                <SHr height={32} />

                <MenuPages path={"/reporte/cliente"}  >

                    <MenuButtom label='Cupones' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/cliente/cupones", evt);
                        });
                    }} />

                    <MenuButtom label='CLIENTE FRECUENCIA DE COMPRA' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/cliente/cliente_frecuencia_de_compra", evt);
                        });
                    }} />

                    <MenuButtom label='TICKET PROMEDIO DE PEDIDOS - TAPEKE' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/cliente/ticket_promedio_tapekes", evt);
                        });
                    }} />

                    <MenuButtom label='TICKET PROMEDIO DE PEDIDOS - PRODUCTO' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/cliente/ticket_promedio_productos", evt);
                        });
                    }} />

                    <MenuButtom label='DATA USUARIO EDAD GENERO' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/cliente/data_usuario_edad_genero");
                    }} />
                </MenuPages>
                <SHr height={32} />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);