import { SView, SImage, SText, SIcon, SPopup, SNavigation } from 'servisofts-component';
import DPA from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import { connect } from 'react-redux';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["key_restaurante"],
            excludes: ["key", "fecha_on", "key_usuario", "estado"],
            onRefresh: (resolve) => {
                Model.restaurante.Action.CLEAR();
                resolve();
            }
        });
    }

    componentDidMount() {
        Parent.model.Action.CLEAR();
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }


    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0;
    }

    $order() {
        return [{ key: "index", order: "asc" }]
    }

    $getData() {
        return Parent.model.Action.getAll({ key_restaurante: this.$params.key_restaurante });
    }

    onEdit(obj) {
        SNavigation.navigate(Parent.path + "/edit", { pk: obj.key, key_restaurante: this.props.key_restaurante })
    }

    onDelete(obj) {
        if (this.deletePermiso) {
            SPopup.confirm({
                title: "Eliminar Banner Slider Tapeke",
                message: "¿Seguro que desea remover Banner Slider Tapeke?",
                onPress: () => {
                    Parent.model.Action.editar({
                        data: {
                            // ...obj,  
                            key: obj.key,
                            estado: 0
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }
            })
        } else {
            SPopup.alert("No tiene permisos para eliminar el Banner Slider Tapeke")
        }
    }

    $item(obj) {
        this.deletePermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
        this.editPermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" });

        return <>
            <SView card>
                <SView padding={10}>
                    <SView width={400} height={200} center
                        style={{
                            justifyContent: "flex-evenly",
                        }}
                    >
                        <SImage src={Parent.model._get_image_download_path(SSocket.api, obj.key)} />
                    </SView>

                    <SView>
                        <SView row>
                            <SView flex>
                                <SText>Descripción: {!!obj.descripcion ? obj.descripcion : "No se le configuro ninguna descripción"}</SText>
                                <SText>Tiempo: {obj.tiempo}</SText>
                                <SText>Index: {obj.index}</SText>
                            </SView>

                            <SView row>
                                <SView style={{ marginRight: "10px" }} onPress={() => this.onEdit(obj)}>
                                    {this.editPermiso ? <SIcon name={"Edit"} height={30} width={30}></SIcon> : <SView />}
                                </SView>

                                <SView onPress={() => this.onDelete(obj)}>
                                    {this.deletePermiso ? <SIcon name={"Delete"} height={30} width={30}></SIcon> : <SView />}
                                </SView>
                            </SView>
                        </SView>

                    </SView>
                </SView>
            </SView>
        </>
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);