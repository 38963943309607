import { SPage } from "servisofts-component";

import root from "./root";
import pedidos_no_recogidos from "./operaciones/pedidos_no_recogidos"
import test from "./test"
import app from "./app";
import cliente from "./cliente";
import comercio from "./comercio";
import operaciones from "./operaciones";


export default SPage.combinePages("reporte",
    {
        "": root,
        test,
        pedidos_no_recogidos,
        
        ...app,
        ...cliente,
        ...comercio,
        ...operaciones
    }
)



export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k + "-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}