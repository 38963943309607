import { SPage } from "servisofts-component";
import Model from "../../../../Model";
import list from "./list";
import _new from "./new";

const model = Model.restaurante_excluir_accion_global;
export const Parent = {
    name: "restaurante_excluir_accion_global",
    path: `/restaurante/restaurante_accion_global/restaurante_excluir_accion_global`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "new": _new,
})
