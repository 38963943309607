import { SPage } from "servisofts-component";

import root from "./root";
import novedades from "./novedades";
import popup_inicio from "./popup_inicio";
import restaurante_mas_pedido from "./restaurante_mas_pedido";
import company_patrocinado from "./company_patrocinado";

export default SPage.combinePages("marketing",
    {
        "": root,
        ...novedades,
        ...popup_inicio,
        ...restaurante_mas_pedido,
        ...company_patrocinado
    }
)