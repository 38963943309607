import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '..'
import { connect } from 'react-redux'
import Model from '../../../Model'

class index extends Component {
  state = {
    data: {}
  }
  constructor(props) {
    super(props);
    this.params = SNavigation.getAllParams() ?? {}
  }

  componentDidMount() {
    this.setState({ loading: true, error: "" })

    SSocket.sendPromise({
      component: "reporte",
      type: "executeFile",
      src: "query/contexto_data/costo_promedio_entrega_distancia.pgsql",
      params: [this.params.fecha_inicio, this.params.fecha_fin]
    }).then(e => {
      this.promedio = this.calcularPromedio(e.data)

      let keys = [...new Set(Object.values(e.data).map(a => a.key_driver).filter(key => key !== null))];
      SSocket.sendPromise({
        version: "2.0",
        service: "usuario",
        component: "usuario",
        type: "getAllKeys",
        keys: keys,
      }).then(e2 => {
        Object.values(e.data).map(a => {
          a.usuario = e2?.data[a.key_driver]?.usuario ?? {}
        })
        this.setState({ data: e.data })
      }).catch(e2 => {
        SPopup.alert(e2.error)
      })
    }).catch(e => {
      SPopup.alert(e.error)
    })
  }

  calcularPromedio(data) {
    let sumDelivery = 0;
    let sumDistancia = 0;
    let promedio = {
      promedioDelivery: 0,
      promedioDistancia: 0
    }
    data.map((item) => {
      sumDelivery += item.delivery;
      sumDistancia += item.distancia;
    })

    if (data.length > 0) {
      promedio.promedioDelivery = sumDelivery / data.length;
      promedio.promedioDistancia = sumDistancia / data.length;
    }

    return promedio;
  }

  renderTable() {
    if (!this.state.data) return <SLoad />

    return <STable2
      header={
        [
          { key: "usuario", label: "cliente", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
          { key: "state", label: "Estado", width: 120 },
          { key: "delivery", label: "Monto Delivery", width: 120, component: val => val ? <SText>{parseFloat((val).toFixed(2))} Bs</SText> : null },
          { key: "distancia", label: "Distancia", width: 120, component: val => val ? <SText>{parseFloat((val).toFixed(2))} Mts</SText> : null },
          {
            key: "key_pedido", label: "Ver Pedido", width: 120, component: val =>
              <SText onPress={() => {
                SNavigation.navigate("/pedido/profile", { pk: val })
              }}>VER</SText>
          },
        ]
      }
      data={this.state.data} />
  }

  render() {
    return <SPage
      title={`Reporte de costo promedio de entrega ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
      disableScroll
    >
      <SView col={"xs-12"} flex>
        {this.renderTable()}
      </SView>

      <SView row>
        <SText fontSize={15}>promedio monto: {parseFloat(this.promedio?.promedioDelivery).toFixed(2)} Bs</SText>
        <SText fontSize={15} style={{ marginLeft: 10 }}>promedio distancia: {parseFloat(this.promedio?.promedioDistancia).toFixed(2)} Mts</SText>
      </SView>
    </SPage>
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);