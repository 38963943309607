import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/porcentaje_de_contratos.pgsql",
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
            console.log(e.data);
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "tipo", label: "tipo de pago", width: 80 },
                    { key: "cantidad_inicial", label: "Inicial", width: 80 },
                    { key: "cantidad_creciente", label: "Creciente", width: 80 },
                    { key: "cantidad_expansivo", label: "Expansivo", width: 80 },
                    { key: "cantidad_premium", label: "Premium", width: 80 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de Plan Comercial`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);