import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SIcon, SImage, SMath, SNavigation, SNotification, SPage, SPopup, STable2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';

export default class root extends Component {
  static INSTANCE: root;
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.key_insignia = SNavigation.getParam("key_insignia");

  }
  componentDidMount() {
    root.INSTANCE = this;
    if (!this.key_insignia) {
      SNavigation.goBack()
      return;
    }
    SSocket.sendPromise({
      component: "recompensa_insignia",
      type: "getAllDetalle",
      key_insignia: this.key_insignia
    }).then(e => {
      this.setState({ data: e.data })
    }).catch(e => {
      SNotification.send({
        title: "Error",
        body: e.error ?? "Error desconocido",
        color: STheme.color.danger
      })
      console.error(e);
    })
  }
  render() {
    return <SPage title={"Recompensas"}>
      <STable2 data={this.state.data}
        rowHeight={50}
        cellStyle={{
          // height: "100%",
          // height: 100,
          width: "100%",
          alignItems: 'center',
          justifyContent: "center"
        }}
        header={[
          { key: "index", width: 30 },
          { key: "descripcion", width: 200 },
          { key: "tipo_recompensa", width: 130 },
          // { key: "valor", width: 80 },

          // { key: "key-enable", width: 50, component: (key) => <SImage src={SSocket.api.root + "insignia/" + key + "/enable"} /> },
          // { key: "key-disable", width: 50, component: (key) => <SImage src={SSocket.api.root + "insignia/" + key + "/disable"} /> },
          // { key: "nombre", width: 200 },
          {
            key: "-relacion", width: 200, component: (obj) => {
              if (obj.tipo_recompensa == "abono_billetera") return <SText>{SMath.formatMoney(obj.valor)}</SText>
              if (obj.tipo_recompensa == "cupon") {
                if (!obj.cupones) return <SText fontSize={12} onPress={() => {
                  SNavigation.navigate("/cupon/new", { key_recompensa_insignia: obj.key })
                }} color={STheme.color.warning}>{"Agregar cupon"}</SText>;
                return <SView flex height center>
                  {obj.cupones.map(o => <SView padding={4} card onPress={() => {
                    SNavigation.navigate("/cupon/profile", { pk: o.key })
                  }}>
                    <SText>{o.descripcion}</SText>
                  </SView>)}

                </SView>
              } else {
                if (!obj.descuentos) return <SText fontSize={12} onPress={() => {
                  SNavigation.navigate("/descuento/new", { key_recompensa_insignia: obj.key, type: obj.tipo_recompensa == "descuento_delivery" ? "delivery" : "producto" })
                }} color={STheme.color.warning}>{"Agregar descuento"}</SText>;
                return <SView flex height center>
                  {obj.descuentos.map(o => <SView padding={4} card onPress={() => {
                    SNavigation.navigate("/descuento/profile", { pk: o.key })
                  }}>
                    <SText>{o.descripcion}</SText>
                  </SView>)}

                </SView>
              }
              return <SText>{"NO REPRENSENTADO"}</SText>
            }
          },
          { key: "observacion", width: 200 },

          // { key: "cantidad_tapekes", width: 50, center: true },
          // { key: "nivel", width: 50, center: true, order: "asc", orderPriority: 5, },
          { key: "key-edit", width: 50, component: (key) => <SView width={26} height={26} onPress={() => SNavigation.navigate("/insignia/recompensa/form", { pk: key })}><SIcon name='Edit' /></SView> },
          {
            key: "key-delete", width: 50, component: (key) => <SView width={26} height={26} onPress={() => SPopup.confirm({
              title: "Seguro de eliminar?", onPress: () => {
                SSocket.sendPromise({
                  component: "recompensa_insignia",
                  type: "editar",
                  data: {
                    key: key,
                    estado: 0,
                  }
                }).then(e => {
                  this.componentDidMount();
                  console.log(e);
                }).catch(e => {
                  console.error(e);
                })
              }
            })}><SIcon name='Delete' /></SView>
          },
          // { key: "consejo", width: 200 },
          // // { key: "key_usuario", width: 50 },
          { key: "fecha_on", width: 100 },


        ]}
      />
      <SView width={35} height={35} style={{ position: "absolute", bottom: 24, right: 8 }} onPress={() => {
        SNavigation.navigate("/insignia/recompensa/form", { key_insignia: this.key_insignia })
      }}>
        <SIcon name='Add' />
      </SView>
    </SPage >
  }
}
