import dato from "./dato";
import rol_dato from "./rol_dato";
import usuario_dato from "./usuario_dato";
import enviroment from "./enviroment";
import costo_envio from "./costo_envio";
import novedades from "./novedades";
import restaurante from "./restaurante";
import restaurante_cuenta from "./restaurante_cuenta";
import zona from "./zona";
import horario from "./horario";
import usuario_restaurante from "./usuario_restaurante";
import billetera from "./billetera";
import pack from "./pack";
import conciliacion_pasarela from "./conciliacion_pasarela";
import conciliacion_restaurante from "./conciliacion_restaurante";
import conciliacion_delivery from "./conciliacion_delivery";
import orden_pago from "./orden_pago";
import pedido from "./pedido";
import background_location from "./background_location";
import pago_tarjeta from "./pago_tarjeta";
import cupon from "./cupon";
import cupon_usuario from "./cupon_usuario";
import cupon_restaurante from "./cupon_restaurante";
import incentivo from "./incentivo";
import base_notification from "./base_notification";
import sms_code from "./sms_code";
import driver_cuenta from "./driver_cuenta";
import descuento from "./descuento";
import tag from "./tag";
import restaurante_tag from "./restaurante_tag";
import multiplicador_billetera from "./multiplicador_billetera";
import producto from "./producto";
import sub_producto from "./sub_producto";
import sub_producto_detalle from "./sub_producto_detalle";
import categoria_producto from "./categoria_producto";
import restaurante_slider from "./restaurante_slider";
import pedido_producto from "./pedido_producto";
import descuento_restaurante from "./descuento_restaurante";
import popup_inicio from "./popup_inicio";
import descuento_tag from "./descuento_tag";
import descuento_producto from "./descuento_producto";
import notification_task from "./notification_task";
import notification_suscription_config from "./notification_suscription_config";
import company from "./company";
import restaurante_mas_pedido from "./restaurante_mas_pedido";
import company_patrocinado from "./company_patrocinado";
import categoria_tag_tag from "./categoria_tag_tag";
import restaurante_excluir_accion_global from "./restaurante_excluir_accion_global";

export default {
    dato,
    rol_dato,
    usuario_dato,
    enviroment,
    costo_envio,
    novedades,
    restaurante,
    restaurante_cuenta,
    driver_cuenta,
    zona,
    horario,
    usuario_restaurante,
    billetera,
    pack,
    conciliacion_pasarela,
    conciliacion_restaurante,
    conciliacion_delivery,
    orden_pago,
    pedido,
    background_location,
    pago_tarjeta,
    cupon,
    cupon_usuario,
    cupon_restaurante,
    incentivo,
    base_notification,
    sms_code,
    descuento,
    tag,
    restaurante_tag,
    multiplicador_billetera,
    producto,
    sub_producto,
    sub_producto_detalle,
    categoria_producto,
    restaurante_slider,
    pedido_producto,
    descuento_restaurante,
    popup_inicio,
    descuento_tag,
    descuento_producto,
    notification_task,
    notification_suscription_config,
    restaurante_mas_pedido,
    company,
    company_patrocinado,
    categoria_tag_tag,
    restaurante_excluir_accion_global
}