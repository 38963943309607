import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import { SHr, SImage, SList, SLoad, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket'
class index extends DPA.item {
    constructor(props) {
        super(props, {
            Parent: Parent,
            // row:false
        });
    }
    $getData() {
        return super.$getData();
    }

    $renderContent() {
        return <SView
            padding={10}
            width={450}
        >
            <SText
                style={{
                    fontSize: 14,
                    fontWeight: "bold"
                }}
            >{this.data.title}</SText>

            <SHr h={15} />
            <SText>{this.data.body}</SText>
            <SHr h={15} />

            <SView row flex
                style={{
                    justifyContent: "space-between"
                }}>
                <SText>{this.data.tipo}</SText>
                <SText>{this.data.comentario}</SText>
            </SView>

        </SView>
    }
}
export default connect(index);