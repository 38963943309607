import { SPage } from "servisofts-component";

import root from "./root";
import comercios_favoritos_ranking_pedidos from "./comercios_favoritos_ranking_pedidos"
import comercios_favoritos_corazones from "./comercios_favoritos_corazones"
import tags_de_los_comercios from "./tags_de_los_comercios"
import activaciones_ofertadas from "./activaciones_ofertadas";
import tiempo_apertura_promedio from "./tiempo_apertura_promedio"
import ranking_de_productos_mas_vendidos from "./ranking_de_productos_mas_vendidos"
import ticked_promedio_ofertas from "./ticked_promedio_ofertas"
import porcentaje_de_contratos from "./porcentaje_de_contratos"
import comercios_rentabilidad from "./comercios_rentabilidad"
import tapekes_deshabilitados from "./tapekes_deshabilitados";


export default SPage.combinePages("comercio",
    {
        "": root,
        tapekes_deshabilitados,
        tiempo_apertura_promedio,
        comercios_favoritos_ranking_pedidos,
        comercios_favoritos_corazones,
        tags_de_los_comercios,
        activaciones_ofertadas,
        ranking_de_productos_mas_vendidos,
        ticked_promedio_ofertas,
        porcentaje_de_contratos,
        comercios_rentabilidad,
    }
)

export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k + "-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}