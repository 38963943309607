import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado", "tipo"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var inp = super.$inputs();
        // TODO Validar que el input sea en mayusculas y no deje poner espacios
        inp["descripcion"].label = "Código del Cupón"

        inp["observacion"].label = "Descripción del Cupón"

        inp["monto"].type = "money"

        inp["monto_minimo_compra"].type = "money"
        inp["monto_minimo_compra"].label = "Monto mínimo de compra"

        inp["fecha_off"].label = "Fecha de expiración"

        inp["cantidad_maxima_usuarios"].label = "Cantidad máxima de usuarios"
        inp["cantidad_maxima_usuarios"].type = "number"
        return inp;
    }

    $onSubmit(data) {
        if (parseFloat(data.monto_minimo_compra) <= parseFloat(data.monto)) {
            SPopup.alert("El valor del cupón debe ser menor al monto mínimo de compra.")
            return;
        }

        data.fecha_off = `${data.fecha_off}T23:59:00`

        if(data.key_recompensa_insignia == ''){
            data.key_recompensa_insignia = null
        }
        
        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
            SPopup.alert(e.error)

        })
    }
}

export default connect(index);