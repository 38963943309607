import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SText } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket'

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado"]
        });

        this.state = {
            restaurante: null,
        };
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    componentDidMount() {
        Parent.model.Action.CLEAR();

        SSocket.sendPromise({
            component: "restaurante_mas_pedido",
            type: "getAll",
        }).then(e => {
            this.setState({ restaurante_mas_pedido: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    $inputs() {
        var inp = super.$inputs();
        inp["key_restaurante"].onPress = (val) => {
            SNavigation.navigate("/restaurante", {
                filter: (obj) => {
                    if (this.state.restaurante_mas_pedido) {
                        let item = Object.values(this.state.restaurante_mas_pedido).find(a => a.key_restaurante == obj.key && a.estado > 0)
                        if (item) {
                            return false
                        }
                    }
                    return true;
                },
                onSelect: (obj) => {
                    if (!obj) return;
                    if (this.state.restaurante_mas_pedido) {
                        let item = Object.values(this.state.restaurante_mas_pedido).find(a => a.key_restaurante == obj.key && a.estado > 0)
                        if (item) {
                            SPopup.alert("El restaurante ya se encuentra registrado.")
                            return;
                        }
                    }
                    this.setState({ restaurante: obj })
                }
            })
        }

        inp["key_restaurante"].value = this.state.restaurante ? this.state.restaurante.nombre : null;
        return inp;
    }

    $onSubmit(data) {
        if (!this.state.restaurante) {
            SPopup.alert("Seleccione un restaurante");
            return;
        }

        data.key_restaurante = this.state.restaurante.key;

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);