import DPA, { connect } from 'servisofts-page';
import { SDate, SHr, SList, SLoad, SNavigation, SPopup, SText, SView } from 'servisofts-component';

import Model from '../../../../Model'

const Parent = {
    name: "cuentas",
    path: "/restaurante_cuenta",
    model: Model.restaurante_cuenta
}
class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            type: "componentTitle",
            excludes: ["key", "fecha_on", "key_usuario", "estado", "Password", "key_restaurante"],
        });
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    onNew() {
        SNavigation.navigate(Parent.path + "/new", { key_restaurante: this.props.key_restaurante })
    }

    // $onSelect(obj) {
    //     SPopup.confirm({
    //         title: "Eliminar partner",
    //         message: "¿Seguro que desea remover el partner?",
    //         onPress: () => {
    //             Model.usuario_restaurante.Action.editar({
    //                 data: {
    //                     ...obj.usuario_restaurante,
    //                     estado: 0
    //                 },
    //                 key_usuario: Model.usuario.Action.getKey()
    //             })
    //         }
    //     })

    // }
    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    // $filter(data) {
    //     return data.estado != 0 && data?.usuario_restaurante?.estado != 0
    // }

    $getData() {
        let data = Parent.model.Action.getAll();
        if (!data) return null;
        this.data= Object.values(data).filter(a => a.estado != 0 && a.key_restaurante == this.props.key_restaurante);
        return this.data;
        // var usuarios = Parent.model.Action.getAll();
        // var usuario_restaurante = Model.usuario_restaurante.Action.getAllBy({
        //     key_restaurante: this.props.key_restaurante
        // });
        // if (!usuarios || !usuario_restaurante) return null;
        // this.data = []
        // Object.values(usuario_restaurante).map(o => {
        //     usuarios[o.key_usuario].usuario_restaurante = o;
        //     if (this.$filter(usuarios[o.key_usuario])) {
        //         this.data.push(usuarios[o.key_usuario])
        //     }
        // });
        // return this.data;
    }

}
export default connect(index);