import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SLoad, SMapView, SMapView2, SMarker, SText, SView } from 'servisofts-component'

export default class MapaDelPedido extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (!this.props.pedido) return <SLoad />

        if (this.props.pedido.direccion && this.props.pedido.restaurante) {
            let direccionPedido = this.props.pedido?.direccion;
            let direccionRestaurante = this.props.pedido?.restaurante;
            return (
                <SView col={"xs-12"} card height={300}>
                    <SMapView ref={ref => this.map = ref} initialRegion={{
                        latitude: direccionRestaurante.latitude,
                        longitude: direccionRestaurante.longitude,
                        latitudeDelta: 0.09,
                        longitudeDelta: 0.09
                    }}>
                        <SMapView.SMarker key={"a"} latitude={direccionRestaurante.latitude} longitude={direccionRestaurante.longitude} />
                        <SMapView.SMarker key={"b"} latitude={direccionPedido.latitude} longitude={direccionPedido.longitude} />
                    </SMapView>
                </SView>
            )
        }
    }
}