import DPA, { connect } from 'servisofts-page';
import { SDate, SHr, SList, SLoad, SNavigation, SPopup, SText, SView } from 'servisofts-component';

import Model from '../../../../Model'

const Parent = {
    name: "partner",
    path: "/restaurante/profile/partners",
    model: Model.usuario
}
class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            type: "componentTitle",
            excludes: ["key", "fecha_on", "key_usuario", "estado", "Password"],
            // item: Item,
        });
    }
    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    onNew() {
        SNavigation.navigate("/usuario", {
            filter: (obj) => {
                let item = Object.values(this.data).find(a => a.key == obj.key && a.estado > 0)
                if (item) {
                    return false
                }
                return true;
            },
            onSelect: (obj) => {
                if (this.data.find(o => o.key == obj.key && this.$filter(o))) {
                    SPopup.alert("El usuario ya es partner.")
                    return;
                }
                Model.usuario_restaurante.Action.registro({
                    data: {
                        key_restaurante: this.props.key_restaurante,
                        key_usuario: obj.key,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })
    }

    $onSelect(obj) {
        if (!this.allowdelete) {
            SPopup.alert("No tienes permisos para esta acción.")
            return;
        }
        SPopup.confirm({
            title: "Eliminar partner",
            message: "¿Seguro que desea remover el partner?",
            onPress: () => {
                Model.usuario_restaurante.Action.editar({
                    data: {
                        ...obj.usuario_restaurante,
                        estado: 0
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })

    }
    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    $filter(data) {
        return data.estado != 0 && data?.usuario_restaurante?.estado != 0
    }

    $getData() {
        this.allowdelete = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
        var usuarios = Parent.model.Action.getAll();
        var usuario_restaurante = Model.usuario_restaurante.Action.getAllBy({
            key_restaurante: this.props.key_restaurante
        });
        if (!usuarios || !usuario_restaurante) return null;
        this.data = []
        Object.values(usuario_restaurante).map(o => {
            if (!usuarios[o.key_usuario]) return;
            usuarios[o.key_usuario].usuario_restaurante = o;
            if (this.$filter(usuarios[o.key_usuario])) {
                this.data.push(usuarios[o.key_usuario])
            }
        });
        return this.data;
    }

}
export default connect(index);