import { SPage } from "servisofts-component";
import Model from "../../../Model";
import list from "./list";
import table from "./table";
import _new from "./new";
import profile from "./profile";
import edit from "./edit";
import _delete from "./delete";
import conciliar from "./conciliar";

const model = Model.conciliacion_delivery;

export const Parent = {
    name: "delivery",
    path: `/conciliacion/delivery`,
    model,
}

export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "table": table,
    // "new": _new,
    "new": conciliar,
    "profile": profile,
    "edit": edit,
    "delete": _delete
})
