import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SButtom, SForm, SHr, SInput, SLoad, SNavigation, SPopup, STheme, SView } from 'servisofts-component';
import Model from '../../../Model';
import PButtom from '../../../Components/PButtom';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: []
        });

        this.state = {
            loading: false
        }

        this.key_restaurante = SNavigation.getParam("key_restaurante");
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $getData() {
        this.packs = Model.pack.Action.getAllBy({ key_horario: this.pk })
        var data = Parent.model.Action.getByKey(this.pk);
        if (!data || !this.packs) return null;
        return data;
    }

    $inputs() {
        var inp = super.$inputs();
        inp["hora_inicio"].col = "xs-5.5"
        inp["hora_inicio"].type = "hour"
        inp["hora_inicio"].required = true
        inp["hora_fin"].col = "xs-5.5"
        inp["hora_fin"].required = true
        inp["hora_fin"].type = "hour"
        return inp;
    }

    $submitName() {
        return ""
    }

    $footer() {
        if (!this.packs) return <SLoad />
        var arr_pack = Object.values(this.packs);
        let pack = {};
        if (arr_pack) {
            pack = arr_pack[0]
        }
        let permisoDelete = Model.usuarioPage.Action.getPermiso({ url: "/restaurante/horario", permiso: "delete" })

        return <>
            <SInput
                ref={ref => this.iprecioOriginal = ref}
                col={"xs-5.5"}
                type={"money"}
                label={"Precio Original"}
                defaultValue={!pack?.precio_original ? 0 : parseFloat(pack?.precio_original).toFixed(2)}
                required={true}
            />

            <SInput
                ref={ref => this.iprecio = ref}
                col={"xs-5.5"}
                type={"money"}
                label={"Precio"}
                defaultValue={parseFloat(pack?.precio).toFixed(2)}
                required={true}
            />

            <SInput
                ref={ref => this.icantidad = ref}
                col={"xs-5.5"}
                type={"number"}
                label={"Cantidad"}
                defaultValue={(pack?.cantidad)}
                required={true}
            />

            <SHr h={20} />


            <PButtom
                danger={true}
                width={120}
                fontSize={12}
                center
                loading={this.state.loading}
                onPress={() => {
                    let pack = {};
                    pack.cantidad = this.icantidad.getValue();
                    pack.precio = this.iprecio.getValue();
                    pack.precio_original = this.iprecioOriginal.getValue();

                    this.dataAux = {
                        key_horario: this.pk,
                        ...pack
                    }

                    this.form.submit()
                }}
            >
                Editar Horario
            </PButtom>

            <SHr h={20} />

            {!!permisoDelete ? <SButtom type='danger' onPress={() => {
                SPopup.confirm({
                    title: "Esta seguro que desea eliminar el horario?",
                    onPress: () => {
                        Model.horario.Action.editar({
                            data: {
                                ...this.data,
                                estado: 0,
                            },
                            key_usuario: Model.usuario.Action.getKey()
                        }).then((e) => {
                            SNavigation.navigate("/restaurante/profile/horario", { pk: this.key_restaurante })
                        }).catch(e => {
                            SPopup.alert(`Error: ${e.error} - intente de nuevo la acción`)
                        })
                    }
                })
            }}>ELIMINAR</SButtom> : null}

            <SHr h={30} />
            <SButtom type='success' styleText={{ fontSize: 10, color: STheme.color.text }} style={{ width: 150 }} onPress={() => {
                SNavigation.navigate("/restaurante/horario/horariohistorico", { pk: this.data?.key })
            }}>HISTORIAL EDICIÓN</SButtom>
        </>
    }

    $onSubmit(data) {
        this.setState({ loading: true });

        if (data.hora_inicio.length != 5) {
            SPopup.alert("La hora inicio debe ser el formato 24:00");
            return;
        }

        if (data.hora_fin.length != 5) {
            SPopup.alert("La hora fin debe ser el formato 24:00");
            return;
        }

        // if(this.data.hora_inicio != data.hora_inicio || this.data.hora_fin != data.hora_fin){
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            pack: this.dataAux,
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            if (this.key_restaurante) {
                SNavigation.navigate("/restaurante/profile/horario", { pk: this.key_restaurante });
            } else {
                SPopup.alert("Recuerda navegar con la interfaz de la aplicación, no con los botones del navegador, sino pasan cosas raras como esta. Nota: si necesitas recargar y no hay la opción recuerda reportarlo, para añadirlo");
                SNavigation.goBack();
            }
            this.setState({ loading: false });
        }).catch(e => {
            SPopup.alert(`Error: ${e.error} - intente de nuevo la acción`)
            console.error(e);
            this.setState({ loading: false });
        })
        // }
    }
}


export default connect(index);