import { SPage } from "servisofts-component";
import Model from "../../Model";
import profile from "./profile";
import movimientos from "./movimientos";
import list from "./list";
import table from "./table";
import oldTable from "./old_table";
const model = Model.pedido;

export const Parent = {
    name: "pedido",
    path: `/pedido`,
    model
}
export default SPage.combinePages(Parent.name, {
    // "": root,
    "":list,
    profile,
    movimientos,
    table,
    oldTable,
})

