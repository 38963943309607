import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SText } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket'

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["key_restaurante"],
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_restaurante"]
        });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var imp = super.$inputs();
        return imp;
    }

    $onSubmit(data) {

        data.key_restaurante = this.$params.key_restaurante;

        if (data.tiempo <= 0) {
            SPopup.alert("El tiempo debe ser mayor a 0");
            return;
        }

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {

            this.form.uploadFiles(
                SSocket.api.root + "upload/restaurante_slider/" + resp.data.key
            );

            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);