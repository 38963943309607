import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import { SText, SView, STheme, SDate, SHr, SNavigation, SLoad, SList, SIcon, SPopup } from 'servisofts-component';
import item from '../item';
import DescuentosHijos from './Components/DescuentosHijos';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil Descuento",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            item: item
        });
        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.descuento_restaurante.Action.CLEAR()
        Model.descuento_tag.Action.CLEAR()
    }

    onBack() {
        return true;
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }


    registrar_restaurante = () => {
        if (!this.descuento_restaurante) return null
        SNavigation.navigate("/restaurante", {
            filter: (obj) => {
                if (this.descuento_restaurante) {
                    let item = Object.values(this.descuento_restaurante).find(a => a.key_restaurante == obj.key && a.estado > 0)
                    if (item) {
                        return false
                    }
                }
                return true;
            },
            onSelect: (obj) => {
                if (!obj) return;
                if (this.descuento_restaurante) {
                    let item = Object.values(this.descuento_restaurante).find(a => a.key_restaurante == obj.key && a.estado > 0)
                    if (item) {
                        SPopup.alert("El restaurante ya se encuentra registrado.")
                        return;
                    }
                }
                Model.descuento_restaurante.Action.registro({
                    data: {
                        key_descuento: this.pk,
                        key_restaurante: obj.key,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })
    }

    getRestaurante() {
        this.descuento_restaurante = Model.descuento_restaurante.Action.getAll({ key_descuento: this.pk });
        if (!this.descuento_restaurante) return null
        // var descuento_restaurante = Model.descuento_restaurante.Action.getAll({ key_descuento: this.pk });
        var restaurantes = Model.restaurante.Action.getAll();
        if (!this.descuento_restaurante) return <SLoad />
        if (!restaurantes) return <SLoad />
        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={this.descuento_restaurante}
                filter={a => a.estado != 0}
                render={(obj) => {
                    var restaurante = restaurantes[obj.key_restaurante]
                    return <SView card col={"xs-12"} row style={{
                        padding: 8
                    }}>
                        <SView col={"xs-11"} row>
                            <SText fontSize={16}>{restaurante?.nombre}</SText>
                        </SView>
                        <SView flex style={{ alignItems: "flex-end" }} center
                            onPress={() => {
                                SPopup.confirm({
                                    title: "Eliminar Restaurante",
                                    message: "¿Seguro que desea remover el restaurante?",
                                    onPress: () => {
                                        obj.estado = 0;
                                        Model.descuento_restaurante.Action.editar({
                                            data: obj,
                                            key_usuario: Model.usuario.Action.getKey()
                                        })
                                    }
                                })
                            }}
                        >
                            <SIcon name={'Delete'} width={20} height={20} />
                        </SView>
                    </SView>
                }} />
        </SView>
    }
    $footer() {
        return <SView col={"xs-12"}>
            <SHr />
            <SText card padding={16} width={200} center onPress={this.registrar_restaurante.bind(this)}>AGREGAR RESTAURANTE</SText>
            {this.getRestaurante()}
        </SView>
        // return <DescuentosHijos data={this.data}/>
    }
}
export default connect(index);