import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';
import { SNavigation, SView, SHr, SText, SDate, STheme } from 'servisofts-component';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            limit: 10,
            excludes: ["key", "estado", "key_pedido", "transaction_id", "key_usuario"],
            // item: Item,
        });
    }
    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    $filter(data) {
        return data.estado != 0
    }
    $getData() {
        return Parent.model.Action.getAll({ eliminados: true });
    }
    $onSelect(data) {
        SNavigation.navigate("/billetera/profile", { pk: data.key })
    }

    $menu() {
        var menu = super.$menu();
        if (Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" })) {
            menu.push({
                label: "Eliminados", onPress: () => {
                    console.log(Parent.path)
                    SNavigation.navigate(Parent.path + "/eliminados")
                }
            })
        }

        if (Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "recharge_multiplier" })) {
            menu.push({
                label: "Configurar Multiplicador", onPress: () => {
                    SNavigation.navigate(Parent.path + "/multiplicador_billetera")
                }
            })
        }
        return menu;
    }

    $order() {
        return [{ key: "fecha_on", order: "desc", type: "date" }];
    }

    $item(obj) {
        let usuarioCliente = Model.usuario.Action.getByKey(obj.key_cliente);
        let usuarioAdmin = Model.usuario.Action.getByKey(obj.key_usuario);

        obj.usuarioCliente = usuarioCliente ? usuarioCliente : 'no cargaron los usuarios';
        obj.usuarioAdmin = usuarioAdmin ? usuarioAdmin : 'no cargaron los usuarios';

        return <SView key={obj.key} col={"xs-12"} card padding={8} onPress={() => {
            this.$onSelect(obj)
        }}>
            <SView col={"xs-12"} row>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SHr h={10} />
                        <SText fontSize={16}>{`Tipo de Pago:  ${(obj?.tipo_pago)}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Fecha Creación:  ${new SDate(obj?.fecha_on, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd hh:mm:ss")}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{`Cliente:  ${obj.usuarioCliente?.Nombres} ${obj.usuarioCliente?.Apellidos}`}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>{usuarioAdmin ? `Creador Transacción:  ${obj.usuarioAdmin?.Nombres} ${obj.usuarioAdmin?.Apellidos}` : "Creador Transacción: Servidor Tapeke"}</SText>

                        <SHr h={10} />
                        <SText fontSize={16}>Detalle:  {obj.detalle ? obj.detalle : "No hay detalle"}</SText>

                        <SHr h={10} />
                        <SText style={{
                            color: obj?.monto < 0 ? STheme.color.danger : "green"
                        }} fontSize={16}>{`Monto:  ${obj?.monto}`}</SText>
                    </SView>
                </SView>
            </SView>
        </SView>
    }
}
export default connect(index);