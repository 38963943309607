import { SPage } from "servisofts-component";

import root from "./root";
import cantidad_pedidos_cancelado from "./cantidad_pedidos_cancelado";
import pedidos_no_recogidos from "./pedidos_no_recogidos";
import cantidad_de_ofertas_de_carreras from "./cantidad_de_ofertas_de_carreras";
import tiempo_promedio_compras_anticipadas from "./tiempo_promedio_compras_anticipadas";
import rentabilidad_de_carreras from "./rentabilidad_de_carreras";
import costo_promedio_entrega_distancia from "./costo_promedio_entrega_distancia";
import ingresos_por_driver from "./ingresos_por_driver";
import cantidad_driver_con_incentivo from "./cantidad_driver_con_incentivo";
import reportes_gpx from "./reportes_gpx";

export default SPage.combinePages("operaciones",
    {
        "": root,
        cantidad_pedidos_cancelado,
        pedidos_no_recogidos,
        cantidad_de_ofertas_de_carreras,
        tiempo_promedio_compras_anticipadas,
        rentabilidad_de_carreras,
        costo_promedio_entrega_distancia,
        ingresos_por_driver,
        cantidad_driver_con_incentivo,
        ...reportes_gpx
    }
)

export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k+"-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}