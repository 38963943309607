import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SPage, SHr, SSwitch, SNavigation, SText, SLoad, SView, SPopup, SInput, STheme, SButtom } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
import Model from '../../../Model';
import SSocket from 'servisofts-socket';

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        SSocket.sendPromise(
            {
                "component": "notification_suscription_config",
                "type": "getAll",
            }
        ).then((e) => {
            this.setState({ data: e.data })
        }).catch((e) => {
            console.log(e);
        })
    }

    handleChange_notification_suscription(obj) {
        // if (!this.editPermiso) {
        //     SPopup.alert("No tienes permisos para esta acción.")
        //     return;
        // }

        if (obj.enable) {
            obj.enable = false;
        } else {
            obj.enable = true;
        }

        Model.notification_suscription_config.Action.editar({
            data: {
                ...obj,
                enable: obj.enable
            },
            key_usuario: Model.usuario.Action.getKey()
        }).then((e) => {
            console.log(e.data);
        }).catch((e) => {
            SPopup.alert("Error al editar " + e.message)
        })
    }

    componentDaysSend({ obj }) {

        let days = {
            0: { "dayText": "Lunes", "dayNum": 0, "isSelected": false },
            1: { "dayText": "Martes", "dayNum": 1, "isSelected": false },
            2: { "dayText": "Miércoles", "dayNum": 2, "isSelected": false },
            3: { "dayText": "Jueves", "dayNum": 3, "isSelected": false },
            4: { "dayText": "Viernes", "dayNum": 4, "isSelected": false },
            5: { "dayText": "Sábado", "dayNum": 5, "isSelected": false },
            6: { "dayText": "Domingo", "dayNum": 6, "isSelected": false }
        }

        if (Array.isArray(obj.days_send)) {
            obj.days_send.forEach(day => {
                days[day].isSelected = true;
            });
        }

        return Object.values(days).map((day, i) => {
            return (
                <SView
                    key={i}
                    flex>
                    <SText>{day.dayText}</SText>
                    <SHr />
                    <SInput
                        type={"checkBox"}
                        defaultValue={day.isSelected}
                        // disabled={!allowEdit || !!this.props.disabled}
                        onChangeText={(e) => {
                            if (!obj.days_send) {
                                obj.days_send = [];
                            }

                            if (e) {
                                obj.days_send.push(day.dayNum);
                            } else {
                                obj.days_send = obj.days_send.filter((e) => e != day.dayNum);
                            }

                            Model.notification_suscription_config.Action.editar({
                                data: {
                                    ...obj,
                                    days_send: obj.days_send
                                },
                                key_usuario: Model.usuario.Action.getKey()
                            }).then((e) => {
                                console.log(e.data);
                            }).catch((e) => {
                                SPopup.alert("Error al editar " + e.message)
                            })
                        }} />
                </SView>
            )
        })
    }

    componetSuscription({ config }) {
        return Object.values(config).map(obj => {
            let { enable, key } = obj;
            return (
                <SView
                    flex
                    key={key}
                    col={"xs-6"}
                    style={{
                        margin: 10,
                        padding: 10,
                        borderWidth: 1,
                        borderRadius: 10,
                        borderColor: enable ? STheme.color.success : STheme.color.lightGray
                    }}
                >
                    <SView row
                        style={{
                            justifyContent: "space-between",
                        }}
                    >
                        <SText
                            style={{
                                justifyContent: "flex-start",
                            }}
                        // >{key.replace(/_/g, ' ').toUpperCase()}</SText>
                        >{key}</SText>

                        {/* <SView
                            styleText={{
                                color: STheme.color.text,
                                fontSize: 12
                            }}
                            style={{
                                borderWidth: 1,
                                padding: 5,
                                borderColor: STheme.color.text,
                                borderRadius: 10,
                            }}
                            onPress={() => {
                                SNavigation.navigate("notification_suscription_config", { key: key })
                            }}
                        >
                            <SText>
                                Ver Notificacion
                            </SText>
                        </SView> */}

                        <SSwitch center key={key} size={20} /* loading={this.state.loading}  */ onChange={this.handleChange_notification_suscription.bind(this, obj)} value={!!enable} />
                    </SView>
                    <SView row>
                        {this.componentDaysSend({ obj: obj })}
                    </SView>
                </SView>
            )
        })
    }

    render() {
        if (!this.state.data) return <SLoad />

        return (
            <SPage title={'Notificaciónes'}>
                <SView col={"xs-12"} center>
                    {this.componetSuscription({ config: this.state.data })}
                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);