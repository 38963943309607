import React from 'react';
import { SComponentContainer, SNavigation, SText, STheme, SMapView } from 'servisofts-component';
import SSocket, { setProps } from 'servisofts-socket';
import Redux, { store } from './Redux';
import Config from "./Config";
import Assets from './Assets';
import Pages from './Pages';
import BackgroundImage from './Components/BackgroundImage';
import NavBar from './Components/NavBar';
import PackageInfo from "../package.json"
import ErrorBoundary from './Components/ErrorBoundary';

SMapView.bootstrapURLKeys.key = "AIzaSyC4rcy6GRVM5_i9ZF0vGFmb1HRc0mXsAdk"
setProps(Config.socket);

const App = (props) => {
    return <Redux>
        <ErrorBoundary>
            <SComponentContainer
                debug
                socket={SSocket}
                background={<BackgroundImage />}
                assets={Assets}
                inputs={Config.inputs}
                theme={{ themes: Config.theme, initialTheme: "dark" }}
            >
                <SNavigation
                    linking={{
                        prefixes: ["https://admin.tapekeapp.com/app/", "http://admin.tapekeapp.com/app/", 'tapekeapp://app/'],
                    }}
                    props={{
                        navBar: NavBar,
                        title: 'Tapeke Admin', pages: Pages,
                    }}
                />
                <SSocket
                    store={store}
                    identificarse={(props) => {
                        var usuario = props.state.usuarioReducer.usuarioLog;
                        return {
                            data: usuario ? usuario : {},
                            deviceKey: 'as-asa-as'
                        };
                    }}
                />
                <SText style={{ position: "absolute", bottom: 2, right: 15, }} fontSize={10} color={STheme.color.lightGray}>v{PackageInfo.version}</SText>
            </SComponentContainer>
        </ErrorBoundary>
    </Redux>
}
export default App;