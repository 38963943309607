import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SText } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/ticket_promedio_productos.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
            console.log(e.data);
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "precio", label: "sumatoria precios", width: 140, render: val => `${val ? parseFloat((val)).toFixed(2) : 0} Bs` },
                    { key: "cantidad", label: "Cantidad", width: 140 },
                    { key: "promedio", label: "Promedio Producto", width: 140, render: val => val ? parseFloat((val)).toFixed(2) : 0 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de TICKET PROMEDIO DE PEDIDOS - PRODUCTO ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);