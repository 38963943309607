import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SIcon, SImage, SInput, SNavigation, SNotification, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';

export default class root extends Component {
  static INSTANCE: root;
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    root.INSTANCE = this;
  }
  componentDidMount() {
    root.INSTANCE = this;
    SSocket.sendPromise({
      component: "insignia",
      type: "getAll",
    }).then(e => {
      this.setState({ data: e.data })
    }).catch(e => {
      SNotification.send({
        title: "Error",
        body: e.error ?? "Error desconocido",
        color: STheme.color.danger
      })
    })
  }
  render() {
    return <SPage title={"Insignias"} disableScroll>
      <SView col={"xs-12"}>
        {/* <SView row center>
          <SText>{"Tipo de insignia: "}</SText>
          <SView width={200}>
            <SInput type='select' options={["Todos", "semanal", "permanente"]} />
          </SView>
        </SView> */}
        {/* <SInput type={"checkBox"} /> */}
      </SView>
      <SView col={"xs-12"} flex>
        <STable2 data={this.state.data}
          header={[
            { key: "index", width: 30 },
            { key: "key-enable", width: 50, component: (key) => <SImage src={SSocket.api.root + "insignia/" + key + "/enable"} /> },
            { key: "key-disable", width: 50, component: (key) => <SImage src={SSocket.api.root + "insignia/" + key + "/disable"} /> },
            { key: "nombre", width: 200 },
            { key: "tipo", width: 100, },
            { key: "cantidad_tapekes", width: 50, center: true },
            { key: "nivel", width: 50, center: true, order: "asc", orderPriority: 5, },
            { key: "key", width: 50, component: (key) => <SView width={26} height={26} onPress={() => SNavigation.navigate("/insignia/form", { pk: key })}><SIcon name='Edit' /></SView> },
            { key: "key-recompenza", width: 50, component: (key) => <SView width={26} height={26} onPress={() => SNavigation.navigate("/insignia/recompensa", { key_insignia: key })}><SIcon name='Ajustes' /></SView> },
            { key: "consejo", width: 200 },
            // { key: "key_usuario", width: 50 },
            { key: "fecha_on", width: 100 },
            { key: "fecha_inicio", width: 100 },


          ]}
        />
      </SView>
      <SView width={35} height={35} style={{ position: "absolute", bottom: 24, right: 8 }} onPress={() => {
        SNavigation.navigate("/insignia/form")
      }}>
        <SIcon name='Add' />
      </SView>
    </SPage>
  }
}
