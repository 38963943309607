import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
import Model from '../../../Model'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/cliente_frecuencia_de_compra.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ loading: false, error: "", data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderInsigniasDesbloquedas(insigniasDesbloquedas) {
        let insigniasDesbloquedasStr = ""
        if (insigniasDesbloquedas) {
            const insigniasDesbloquedasArray = Object.values(insigniasDesbloquedas);
            insigniasDesbloquedasArray.map((id, index) => {
                insigniasDesbloquedasStr += `${id.conteo}x ${id.insignia}`;
                if (index !== insigniasDesbloquedasArray.length - 1) {
                    insigniasDesbloquedasStr += " | ";
                }
            })
        }

        return insigniasDesbloquedasStr !== "" ? insigniasDesbloquedasStr : "NO TIENE INSIGNIA";

    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "usuario", label: "cliente", width: 200, center: true, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "cantidad_pedidos", label: "Cantidad Pedidos", center: true, width: 100, order: "desc" },
                    { key: "cantidad_pedidos_tapeke", label: "Cantidad Pedidos con Tapeke", center: true, width: 200, order: "desc" },
                    { key: "cantidad_tapeke", label: "Cantidad Tapeke", center: true, width: 100 },
                    { key: "cantidad_producto", label: "Cantidad Producto", center: true, width: 100 },
                    { key: "promedio_tapeke", label: "Promedio monto Tapeke", center: true, width: 120, render: val => `${parseFloat((val).toFixed(2))} Bs` },
                    { key: "promedio_producto", label: "Promedio monto Producto", center: true, width: 120, render: val => `${parseFloat((val).toFixed(2))} Bs` },
                    { key: "insignias_desbloquedas", label: "Insignias Desbloqueada", center: true, width: 300, render: val => this.renderInsigniasDesbloquedas(val) },
                    { key: "racha_pedidos", label: "Racha de Pedidos", center: true, width: 100 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de cliente frecuencia de compra desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);