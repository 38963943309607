import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SThread } from 'servisofts-component';
import Model from '../../../../../Model';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["pk", "key_producto"],
            excludes: []
        });
    }

    $allowAccess() {
        // return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
        return true;
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $inputs() {
        var inp = super.$inputs();
        inp["precio"].type = "money"

        inp["precio"].type = "money"
        inp["precio"].defaultValue = parseFloat(inp["precio"].defaultValue || 0).toFixed(2);

        return inp;
    }


    $onSubmit(data) {
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.replace("/restaurante/producto/sub_producto/list", {key_producto: this.$params.key_producto});
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);