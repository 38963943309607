import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SHr, SInput, SLoad, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../../Model';

class RangoItem extends Component {
    state = {

    }
    render() {
        const a = this.props?.data
        return <SView col={"xs-12"} card padding={4}>
            {/* <SText>fecha_inicio: {a.fecha_inicio}</SText> */}
            {/* <SText>fecha_fin: {a.fecha_fin}</SText> */}
            <SInput ref={ref => this.tipo_rango = ref} defaultValue={a.tipo_rango ?? "delivery"} type='select' options={["delivery", "producto"]} col={"xs-12"} label={"El rango de precio se aplica a el:"} />
            <SView row col={"xs-12"} style={{
                justifyContent: "space-between"
            }}>
                <SInput ref={ref => this.monto_minimo = ref} col={"xs-5.5"} type='money' label={"Desde:"} defaultValue={parseFloat(a.monto_minimo ?? 0).toFixed(2)} />
                <SInput ref={ref => this.monto_maximo = ref} col={"xs-5.5"} type='money' label={"Hasta:"} defaultValue={parseFloat(a.monto_maximo ?? 0).toFixed(2)} />
            </SView>
            <SView row col={"xs-12"} style={{
                justifyContent: "space-between"
            }}>
                <SInput ref={ref => this.monto = ref} col={"xs-5.5"} type="number" label={"Monto:"} defaultValue={a.monto} />
                <SInput ref={ref => this.porcentaje = ref} col={"xs-5.5"} type="number" label={"%:"} defaultValue={a.porcentaje * 100} />
            </SView>
            {/* <SText>porcentaje: {a.porcentaje}</SText> */}
            {/* <SText>tipo_rango: {a.tipo_rango}</SText> */}
            {/* <SText>monto_minimo: {a.monto_minimo}</SText> */}
            {/* <SText>monto_maximo: {a.monto_maximo}</SText> */}
            <SHr height={16} />
            <SView col={"xs-12"} row>
                <SView flex />
                <SText col={"xs-5"} card padding={4} center border={STheme.color.danger} color={STheme.color.danger} onPress={() => {
                    Model.descuento.Action.editar({
                        data: {
                            ...a,
                            estado: 0,
                        }
                    }).then(e => {
                        console.log(e)
                    }).catch(e => {
                        console.error(e)
                    })
                }}>ELIMINAR</SText>
                <SView flex />
                <SText col={"xs-5"} card padding={4} center border={STheme.color.warning} color={STheme.color.warning} onPress={() => {

                    let data = {
                        tipo_rango: this.tipo_rango.getValue(),
                        monto_minimo: this.monto_minimo.getValue(),
                        monto_maximo: this.monto_maximo.getValue(),
                        monto: this.monto.getValue(),
                        porcentaje: this.porcentaje.getValue() / 100,
                    }

                    this.setState({ loading: true })
                    Model.descuento.Action.editar({
                        data: {
                            ...a,
                            ...data
                        }
                    }).then(e => {
                        this.setState({ loading: false })
                        console.log(e)
                    }).catch(e => {
                        this.setState({ loading: false })
                        console.error(e)
                    })
                }}>{this.state.loading ? <SLoad /> : "GUARDAR"}</SText>
                <SView flex />
            </SView>
        </SView>
    }
}

export default RangoItem;
