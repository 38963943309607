import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SNavigation, SPage, SPopup, STable2, SText, SView, STheme, SDate, SHr, SImage } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '..'
import { connect } from 'react-redux'
import Model from '../../../Model'

class index extends Component {
    state = {
        data: {},
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/tiempo_promedio_compras_anticipadas.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario_conductor = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ data: e.data, loading: false })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    template() {
        const isMobile = window.innerWidth <= 768;
        if (isMobile) return null;
        return (<SView col={"xs-2.5 md-2.4"} border={"yellow"} style={{ position: "absolute", top: 80, left: 1300, borderRadius: 8, padding: 5, fontSize: 2 }}>
            <SText bold color={"yellow"}>Nota:</SText>
            <SHr />
            <SText>
                T1: TIEMPO DE LA APERTURA A LA ENTREGA DRIVER MEDIR ESTE TIEMPO.
            </SText>
            <SHr />
            <SText>
                T2: TIEMPO DE LA APERTURA A LA ENTREGA DRIVER MEDIR ESTE TIEMPO.
            </SText>
            <SHr />
            <SText>
                T3: TIEMPO DE LLEGADA DEL DRIVER A COMERCIO.
            </SText>
            <SHr />
            <SText>
                T4: TIEMPO DE ENTREGA DEL DRIVER A CLIENTE.
            </SText>
            <SHr />
            <SText>
                T5: TIEMPO DE TOMAR PEDIDO/ASIGNAR PEDIDO A CONDUCTOR.
            </SText>
            <SHr />
            <SView width={370} height={370} card>\
                <SImage
                    enablePreview
                    src={require('../../../Assets/img/infografia-T.png')}
                />
            </SView>
        </SView >);
    }

    convertSegMin(num) {
        let minutes = num / 60;
        // minutes = parseFloat(minutes).toFixed(2);
        return parseFloat(minutes).toFixed(2);
    }

    mapaColor({ tiempoSeg, Ti }) {
        let tiempo = this.convertSegMin(tiempoSeg);
        let color = STheme.color.text;

        const limitesTiempo = {
            "T2": 10,
            "T3": 15,
            "T4": 25,
            "T5": 5,
        };

        if (tiempo > limitesTiempo[Ti]) {
            color = STheme.color.danger;
        }

        return <SText fontSize={11} center color={color}>{tiempo}</SText>
    }

    renderTable() {
        if (this.state.loading) return <SLoad />

        return <STable2
            header={
                [
                    { key: "fecha_on", label: "fecha de registro", width: 150, render: (val) => new SDate(val).toString("yyyy-MM-dd hh:mm") },
                    { key: "usuario_conductor", label: "Driver", width: 180, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    {
                        key: "key_usuario", label: "Ver Driver", width: 60, component: val =>
                            <SText onPress={() => {
                                SNavigation.navigate("/usuario/profile", { pk: val })
                            }}>VER</SText>
                    },

                    { key: "restaurante", label: "Restaurante", width: 200 },

                    { key: "t1", label: "T1 (Min)", width: 70, center: true, render: (val) => this.convertSegMin(val) },
                    { key: "t2", label: "T2 (Min)", width: 70, component: val => this.mapaColor({ tiempoSeg: val, Ti: "T2" }), renderExcel: (val) => this.convertSegMin(val) },
                    { key: "t3", label: "T3 (Min)", width: 70, component: val => this.mapaColor({ tiempoSeg: val, Ti: "T3" }), renderExcel: (val) => this.convertSegMin(val) },
                    { key: "t4", label: "T4 (Min)", width: 70, component: val => this.mapaColor({ tiempoSeg: val, Ti: "T4" }), renderExcel: (val) => this.convertSegMin(val) },
                    { key: "t5", label: "T5 (Min)", width: 70, component: val => this.mapaColor({ tiempoSeg: val, Ti: "T5" }), renderExcel: (val) => this.convertSegMin(val) },
                    {
                        key: "key_pedido", label: "Ver Pedido", width: 120, component: val =>
                            <SText onPress={() => {
                                SNavigation.navigate("/pedido/profile", { pk: val })
                            }}>VER</SText>
                    },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <>
            <SPage
                title={`Reporte de costo promedio de entrega ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
                disableScroll
            >
                <SView col={"xs-12"} flex>
                    {this.renderTable()}
                </SView>
            </SPage>
            {this.template()}
        </>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);