export default {
    "dayOfWeek": {
        "0": {
            "text": "Monday",
            "textSmall": "Mon"
        },
        "1": {
            "text": "Tuesday",
            "textSmall": "Tue"
        },
        "2": {
            "text": "Wednesday",
            "textSmall": "Wed"
        },
        "3": {
            "text": "Thursday",
            "textSmall": "Thu"
        },
        "4": {
            "text": "Friday",
            "textSmall": "Fri"
        },
        "5": {
            "text": "Saturday",
            "textSmall": "Sat"
        },
        "6": {
            "text": "Sunday",
            "textSmall": "Sun"
        }
    },
    "month": {
        "1": {
            "text": "January",
            "textSmall": "Jan"
        },
        "2": {
            "text": "February",
            "textSmall": "Feb"
        },
        "3": {
            "text": "March",
            "textSmall": "Mar"
        },
        "4": {
            "text": "April",
            "textSmall": "Apr"
        },
        "5": {
            "text": "May",
            "textSmall": "May"
        },
        "6": {
            "text": "June",
            "textSmall": "Jun"
        },
        "7": {
            "text": "July",
            "textSmall": "Jul"
        },
        "8": {
            "text": "August",
            "textSmall": "Aug"
        },
        "9": {
            "text": "September",
            "textSmall": "Sep"
        },
        "10": {
            "text": "October",
            "textSmall": "Oct"
        },
        "11": {
            "text": "November",
            "textSmall": "Nov"
        },
        "12": {
            "text": "December",
            "textSmall": "Dec"
        }
    }
};
