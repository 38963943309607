import DPA, { connect } from 'servisofts-page';
import { Parent } from ".";
import { SNavigation, SView, SHr, SText, SDate, STheme } from 'servisofts-component';
import Model from '../../../Model';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado"],
            // item: item
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" });
    }

    $filter(data) {
        return data.estado != 1
    }

    $getData() {
        return Parent.model.Action.getAll({eliminados: true});
    }
}
export default connect(index);