import { SDate, SStorage, STheme } from "servisofts-component";
import { SAction } from "servisofts-model";
import SSocket from 'servisofts-socket'
import Model from "../..";

export default class Action extends SAction {


    calcular_estate(data: any) {
        const fecha_inicio = new SDate(data.fecha_inicio, "yyyy-MM-ddThh:mm:ss")
        const fecha_fin = new SDate(data.fecha_fin, "yyyy-MM-ddThh:mm:ss");
        const now = new SDate();
        if (now.isAfter(fecha_inicio) && now.isBefore(fecha_fin)) {
            return "activo";
        } else if (now.isAfter(fecha_fin)) {
            return "pasado";
        } else if (now.isBefore(fecha_inicio)) {
            return "futuro";
        }
        return "undefined";
    }

    calcular_state_label(data: any) {
        const fecha_inicio = new SDate(data.fecha_inicio, "yyyy-MM-ddThh:mm:ss")
        let state = this.calcular_estate(data);
        switch (state) {
            case "activo":
                return "El decuento esta activo"
                break;
            case "futuro":
                return "El descuento se activara el "+fecha_inicio.toString("dd de MONTH del yyyy")
                break;
            case "pasado":
                return "El descuento ya vencio"
                break;
            case "undefined":
                return "No definido."
                break;
        }
    }
    calcular_state_color(data: any) {
        let state = this.calcular_estate(data);
        switch (state) {
            case "activo":
                return STheme.color.success
                break;
            case "futuro":
                return STheme.color.warning
                break;
            case "pasado":
                return STheme.color.danger
                break;
        }
    }
}