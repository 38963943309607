import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import { SHr, SIcon, SLoad, SText, STheme, SView, SImage } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../../Model';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
import Assets from '../../../Assets';
import { Image } from 'react-native-svg';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado", "foto_portada", "latitude", "longitude"],
            // item: item
        });
    }

    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    getHabilitar() {
        if (!this.data) return <SLoad type={"skeleton"} width={70} height={70} />
        if (this.data.estado == 2) {
            return <MenuButtom label={"Habilitar"} icon={<SIcon name={"Check"} fill={STheme.color.text} />} onPress={() => {
                this.setState({ loading: true })
                Model.restaurante.Action.editar({
                    data: {
                        ...this.data,
                        estado: 1,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                }).then(resp => {
                    this.setState({ loading: false })
                }).catch(e => {
                    this.setState({ loading: false })
                })
            }} />
        } else {
            return <MenuButtom label={"Bloquear"} icon={<SIcon name={"Close"} fill={STheme.color.text} />} onPress={() => {
                this.setState({ loading: true })
                Model.restaurante.Action.editar({
                    data: {
                        ...this.data,
                        estado: 2,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                }).then(resp => {
                    this.setState({ loading: false })
                }).catch(e => {
                    this.setState({ loading: false })
                })
            }} />
        }
    }

    $footer() {
        // var data =Model.incentivo.Action.getAllActivos({ key_restaurante: this.pk })
        // console.log(data);
        return <>
            <SView col={"xs-12"} >
                <SHr />
                <SText fontSize={16} bold>Menu</SText>
                <SHr />

                <MenuPages path={"/restaurante/profile/"} permiso={"ver"} params={{ pk: this.pk }}>
                    {this.getHabilitar()}
                    <MenuButtom label={"Conciliaciones"} url={"/conciliacion/restaurante"} params={{ pk: this.pk }} icon={<SIcon name={"Ajustes"} />} />

                    <MenuButtom
                        label={"Sliders Tapekes"}
                        url={Parent.path + "/restaurante_slider"} params={{ key_restaurante: this.pk }}
                        icon={
                            <SImage
                                src={require('../../../Assets/img/Icono restaurante_slider.png')}
                            />
                        }
                    />

                    <MenuButtom label={"Tags"} url={"/restaurante/profile/tags"} params={{ pk: this.pk }}
                        icon={
                            <SImage
                                src={require('../../../Assets/img/icono_tags_partner.png')}
                            />
                        }
                    />

                    <MenuButtom label={"Acciones"} url={"/restaurante/profile/acciones"} params={{ pk: this.pk }}
                        icon={
                            <SImage
                                src={require('../../../Assets/img/icono_acciones.png')}
                            />
                        }
                    />
                    <MenuButtom label={"Roles"} url={"/restaurante/roles"} params={{ pk: this.pk, key_restaurante: this.pk }}
                        icon={
                            <SImage
                                src={require('../../../Assets/img/icono_acciones.png')}
                            />
                        }
                    />
                </MenuPages>

            </SView>
        </>
    }
}
export default connect(index);