import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SText } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
        this.key_restaurante = this.params.key_restaurante ?? "";
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/tiempo_apertura_promedio.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin, this.key_restaurante]
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "index", label: "#", width: 50 },
                    { key: "nombre", label: "Nombre Comercio", width: 200 },
                    { key: "dia_promedio", label: "días promedio", width: 100, render: (obj) => obj ? obj.toFixed(2) : "No hay promedio"},
                    { key: "hora_promedio", label: "horas promedio", width: 100, order: "desc", render: (obj) => obj ? obj.toFixed(2) : "No hay promedio"},
                    // { key: "hora_promedio_hora", label: "Cantidad Pedido", width: 300 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de TIEMPO DE APERTURA PROMEDIO ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);