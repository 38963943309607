import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SNavigation, SPage, SPopup, STable2, SText, STheme, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
import SChart from 'servisofts-charts'
class index extends Component {
  state = {
    data: null
  }
  constructor(props) {
    super(props);
    this.params = SNavigation.getAllParams() ?? {}
  }

  componentDidMount() {
    this.setState({ loading: true, error: "" })
    SSocket.sendPromise({
      component: "reporte",
      type: "executeFile",
      src: "query/contexto_data/pedidos_por_horas_del_dia.pgsql",
      params: [this.params.fecha_inicio, this.params.fecha_fin]
    }).then(e => {
      const data = {};
      Object.values(e.data).map(e => {
        data[this.renderHora(e.hora)] = e.cantidad
      });
      this.setState({ data });
    }).catch(e => {
      SPopup.alert(e.error)
      this.setState({ loading: false, error: e.error })
    })
  }

  renderHora(hora) {
    let horaInicio = ("0" + hora) + ":00";
    let horaFin = ("0" + hora) + ":59";

    if (hora > 9) {
      horaInicio = hora + ":00";
      horaFin = hora + ":59";
    }

    return horaInicio + " a " + horaFin;
  }

  renderChart() {
    if (!this.state.data) return <SLoad />

    return <SChart data={this.state.data}
      type='Bar'
      textColor={STheme.color.text}
      strokeWidth={1}
      showLabel={true}
      showValue={true}
      showControl={true}
      showGuide={true}
      colors={['#fd5800', '#ff358b', '#e0ffff', '#ff006e', '#fa5820', '#e0ffff', '#8cfffb', '#39ff14', '#ff006e', '#ff00ff', '#fd5800', '#ff358b', '#e0ffff', '#ff006e', '#fd5800', '#e0ffff', '#8cfffb', '#39ff14', '#ff006e', '#ff00ff', '#fd5800', '#ff358b', '#e0ffff', '#ff006e']} />
  }

  render() {
    return <SPage
      title={`Reporte de pedidos por hora del día desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
      disableScroll
    >
      <SView card col={"xs-12"} flex>
        {this.renderChart()}
      </SView >
    </SPage>
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);