import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SNavigation, SPage, SText, SSwitch, SView, SLoad, SPopup, SInput, STheme, SNotification } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import SelectHabilitado from './Components/SelectHabilitado'

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "accion_global",
            type: "getActivo",
            key_usuario: Model.usuario.Action.getKey()
        }).then(resp => {
            let enable
            if (!resp.data) {
                resp.data = {}
                enable = false
            } else {
                enable = true
            }
            this.setState({ deliveryActivo: resp.data, enable: enable });
        }).catch(err => {
            console.log(err.data);
        })
    }

    handleChange_accion_global(obj) {
        if (this.state.enable) {
            SNotification.send({
                key: "guardando_producto",
                title: "Actualizando",
                body: "Estamos guardando los cambios.",
                type: "loading"
            })

            SSocket.sendPromise({
                component: "accion_global",
                type: "revertirDesactivarDelivery",
                key_usuario: Model.usuario.Action.getKey()
            }).then(async (e) => {
                let enable
                if (!e.data) {
                    e.data = {}
                    enable = false
                } else {
                    enable = true
                }
                SNotification.remove("guardando_producto")
                SNotification.send({
                    title: "Acción",
                    body: "Guardado con exito",
                    time: 5000,
                    color: STheme.color.success,
                })
                this.setState({ deliveryActivo: e.data, enable: enable });
            }).catch(err => {
                SNotification.remove("guardando_producto")
                SNotification.send({
                    title: "Acción",
                    body: "Ocurrio un error al guardar.",
                    time: 5000,
                    color: STheme.color.danger,
                })
                console.log(err.data);
            })
        } else {
            const key_popup = "popupkey";
            let top = 225;
            let left = 1000;
            SPopup.open({
                key: key_popup,
                type: "2",
                content: <SelectHabilitado
                    style={{
                        // position: "absolute",
                        // top: top,
                        // left: left,
                    }}
                    onSelect={(select) => {
                        let data = {}

                        if (select != "false") {
                            data.duracion = select.key;
                        }

                        SNotification.send({
                            key: "guardando_producto",
                            title: "Actualizando",
                            body: "Estamos guardando los cambios.",
                            type: "loading"
                        })

                        SSocket.sendPromise({
                            component: "accion_global",
                            type: "desactivarDelivery",
                            data: data,
                            key_usuario: Model.usuario.Action.getKey()
                        }).then(async (e) => {
                            let enable
                            let count
                            if (!e.data) {
                                e.data = {}
                                enable = false
                            } else {
                                enable = true
                            }

                            if(e.data.data){
                                count = e.data.data.length
                            }
                            SNotification.remove("guardando_producto")
                            SNotification.send({
                                title: "Acción",
                                body:  `Guardado con exito ${count} restaurantes`,
                                time: 10000,
                                color: STheme.color.success,
                            })
                            this.setState({ deliveryActivo: e.data, enable: enable });
                        }).catch(err => {
                            SNotification.remove("guardando_producto")
                            SNotification.send({
                                title: "Acción",
                                body: "Ocurrio un error al guardar.",
                                time: 5000,
                                color: STheme.color.danger,
                            })
                            console.log(err.data);
                        })
                        SPopup.close(key_popup)
                    }}
                />
            })
        }
    }

    renderContent() {
        if (!this.state.enable && !this.state.deliveryActivo) return <SLoad />

        return <SView margin={40}>
            <SView card center
                padding={10} borderRadius={4} width={250} margin={10}
                onPress={() => {
                    SNavigation.navigate('/restaurante/restaurante_accion_global/restaurante_excluir_accion_global')
                }}
            >
                <SText>Listar Restaurantes Excluidos</SText>
            </SView>
            <SView margin={10} row>
                <SText style={{ paddingRight: 5 }}>Solo recoger del lugar:</SText>
                <SSwitch center size={20} onChange={this.handleChange_accion_global.bind(this)} value={!!this.state.enable} />
            </SView>
        </ SView>
    }

    render() {
        return (
            <SPage>
                {this.renderContent()}
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);