import { SNavigation, SText, SView } from 'servisofts-component';
import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["onSelect?"],
            excludes: ["key", "fecha_on", "key_usuario", "Password", "Telefono", "Correo", "CI"],
        });
        this.state = {
            habilitados: true,
            desactivados: true,
        }
    }
    componentWillUnmount() {
        if (this.onSelect) {
            this.onSelect(null)
        }
    }

    $allowNew() {
        return false;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }
    $allowTable() {
        return false;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return true;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $onSelect(data) {
        if (this.$params.onSelect) {
            this.$params.onSelect(data);
            SNavigation.goBack();
            return;
        }
        SNavigation.navigate("/driver/profile", { pk: data.key })
    }
    $menu() {
        var menu = super.$menu();
        if (Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "show_deleted" })) {
            menu.push({
                label: "Eliminados", onPress: () => {
                    console.log(Parent.path)
                    SNavigation.navigate(Parent.path + "/eliminados")
                }
            })
        }
        menu.push({
            onPress: () => {
                // console.log(Parent.path)
                // SNavigation.navigate(Parent.path + "/eliminados")
                this.setState({ habilitados: !this.state.habilitados })
            },
            children: <SView padding={4} style={{
                opacity: this.state.habilitados ? 1 : 0.5
            }}>
                <SText>Habilitados</SText>
            </SView>
        })
        menu.push({
            onPress: () => {
                this.setState({ desactivados: !this.state.desactivados })
                // console.log(Parent.path)
                // SNavigation.navigate(Parent.path + "/eliminados")
            },
            children: <SView padding={4} style={{
                opacity: this.state.desactivados ? 1 : 0.3
            }}>
                <SText>Desactivados</SText>
            </SView>
        })

        return menu;
    }
    $order() {
        return [{ key: "Nombres", order: "asc", peso: 2 }, { key: "Apellidos", order: "asc", peso: 1 }]
    }
    $filter(data) {
        // return true;
        if (data.estado <= "0") return false;
        // if (!this.state.habilitados && !this.state.desactivados) return false;
        if (this.state.habilitados && JSON.parse(data.enable ?? false) == true) return true;
        if (this.state.desactivados && !JSON.parse(data.enable ?? false)) return true;
        return false;
    }
    $getData() {
        var usuarios = Model.usuario.Action.getAll();
        var usuarioRol = Model.usuarioRol.Action.getAllByKeyRol("b8920e90-1cbd-4fac-b740-62fac4d22bbd");
        if (!usuarios) return null;
        if (!usuarioRol) return null;
        var obj_final = {};
        Object.values(usuarios).map((obj) => {
            var enabled = Object.values(usuarioRol).find(a => a.key_usuario == obj.key)
            if (enabled) {
                obj["usuarioRol"] = enabled;
                obj_final[obj.key] = obj
            }
        })
        return obj_final;
    }
}
export default connect(index);