import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../../Model';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado"]
        });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var inp = super.$inputs();

        inp["fecha_inicio"].type = "date"
        inp["fecha_fin"].type = "date"

        inp["porcentaje"].type = "number"
        return inp;
    }

    $onSubmit(data) {
        
        data.fecha_fin  = data.fecha_fin + " 23:59:59"

        if (data.porcentaje <= 0) {
            SPopup.alert("El porcentaje no puede ser 0")
            return;
        }

        data.porcentaje = data.porcentaje / 100

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);