import { SPage } from "servisofts-component";
import Model from "../../../Model";
import list from "./list";
import _new from "./new";
import profile from "./profile";
import edit from "./edit";
import _delete from "./delete";
// import eliminados from "./eliminados";

const model = Model.multiplicador_billetera;
export const Parent = {
    name: "multiplicador_billetera",
    path: `/billetera/multiplicador_billetera`,
    model   
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "new": _new,
    "profile": profile,
    "edit": edit,
    "delete": _delete,
    // "eliminados": eliminados,
})
