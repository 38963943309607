import { SPage } from "servisofts-component";

import root from "./root";
import driver from "./driver";
import pedido from "./pedido";
import partner from "./partner";
import all from "./all";
import test from "./test";
export default SPage.combinePages("mapa",
    {
        "": root,
        driver,
        pedido,
        partner,
        test,
        all
    }
)