import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SView, SText, SInput, SHr, SLoad, STheme } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket';
import PButtom from '../../../Components/PButtom';

class index extends DPA.new {

    // filtroExplorar = 1;
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado"/* , "filtro_explorar" */]
        });

        this.state = {
            filtroExplorar: 1,
            categoriasTags: []
        }
    }

    handleChangeFiltroExplorar(obj) {
        this.state.filtroExplorar = this.state.filtroExplorar == 1 ? 0 : 1;

        Model.tag.Action.editar({
            data: {
                ...obj,
                filtro_explorar: this.state.filtroExplorar
            },
            key_usuario: Model.usuario.Action.getKey()
        })
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "categoria_tag",
            type: "getAll",
        }).then(res => {
            this.setState({ data: res.data });
        }).catch(e => {
            console.log(e);
        });
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $submitName() {
        return null;
    }

    $inputs() {
        var inp = super.$inputs();

        inp["filtro_explorar"].label = "filter";
        inp["filtro_explorar"] = { ...inp["filtro_explorar"], type: "checkBox" }
        inp["filtro_explorar"].defaultValue = true;

        return inp;
    }

    renderCbCategoriaTag() {
        return Object.values(this.state.data).map(ct => (
            <SView key={ct.key}
                border={STheme.color.text}
                style={{
                    borderRadius: 10,
                    margin: 5,
                    padding: 10
                }}
            >
                <SText>{ct.descripcion}</SText>
                <SHr />
                <SInput
                    type={"checkBox"}
                    onChangeText={(e) => {
                        if (e) {
                            this.state.categoriasTags.push(ct.key);
                        } else {
                            this.state.categoriasTags = this.state.categoriasTags.filter((e) => e != ct.key);
                        }
                    }}
                />
            </SView>
        ));
    }

    $footer() {
        if (!this.state.data) return <SLoad />;

        return (
            <SView center>
                <SText bold>Categorias Tags</SText>
                <SHr />
                <SView row>
                    {this.renderCbCategoriaTag()}
                </SView>

                <SHr />

                <PButtom
                    secondary={true}
                    fontSize={20}
                    onPress={() => { this.form.submit() }}
                >
                    Crear Tag
                </PButtom>
            </SView>
        );
    }

    $onSubmit(data) {
        data.filtro_explorar = data.filtro_explorar ? 1 : 0;

        Parent.model.Action.registro({
            data: data,
            categorias_tag: this.state.categoriasTags,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);