import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SLoad } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_servicio"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }
    // 56a3a99f-30fa-4258-9495-fbcd52ec9bfd
    $inputs() {
        let inp = super.$inputs();
        // inp["fecha_inicio"].type = "date";
        // inp["fecha_fin"].type = "date";
        inp["monto"].type = "money";
        inp["monto_driver"].type = "money";
        inp["tipo"].type = "select";
        inp["tipo"].options = ["lluvia", "dia_especial"]
        inp["tipo"].defaultValue = "lluvia";
        inp["fecha_inicio"].type = "date";
        inp["fecha_fin"].type = "date";

        let data = Model.zona.Action.getAll();
        var dataOk = []
        if (!data) return <SLoad />
        Object.keys(data).map((key) => {
            dataOk.push({ key: data[key].key, content: data[key].nombre })
        })
        console.log(JSON.stringify(dataOk) + " AQUIII")
        inp["key_zona"].type = "select";
        inp["key_zona"].options = dataOk;
        // inp["key_zona"].defaultValue = "lluvia";

        // inp["delivery"].options = [{ key: "", content: "SELECCIONAR" },{ key: "true", content: "SI" }, { key: "false", content: "NO" }]


        return inp;
    }
    $onSubmit(data) {
        if (!data.fecha_fin) {
            data.fecha_fin = null;
        }
        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);