import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';
import { SDate, SMath } from 'servisofts-component';

class index extends DPA.table {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key",]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" })
    }

    $filter(data) {
        return data.estado != 0
    }

    $order() {
        return [{ key: "fecha_on", order: "desc", peso: 1 }]
    }

    $headers() {
        return {
            "index": { label: "#" },
            "fecha_on": { label: "Fecha", width: 160, render: a => new SDate(a).toString("yyyy-MM-dd hh:mm:ss") },
            "transaction_id": { label: "id Transacción", width: 150 },
            "tipo_pago": { label: "Tipo de pago", width: 150 },
            "detalle": { label: "Detalle", width: 200 },
            "usuario_cliente": { label: "Cliente", width: 150, render: a => a.Nombres + " " + a.Apellidos },
            "usuario_administrador": { label: "Creador Transacción", width: 150, render: a => a ? a.Nombres + " " + a.Apellidos : "Servidor Tapeke" },
            "monto": { label: "Monto", width: 150, render: a => SMath.formatMoney(a) },
            "key_pedido": { label: "Pedido", width: 200 },
        };
    }
    $getData() {
        const usuarios = Model.usuario.Action.getAll();
        const billeteras = Parent.model.Action.getAll();
        if (!billeteras || !usuarios) return null;
        Object.values(billeteras).map(o => {
            o.usuario_administrador = usuarios[o.key_usuario]
            o.usuario_cliente = usuarios[o.key_cliente]
        })
        return billeteras;
    }
}
export default connect(index);