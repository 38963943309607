import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup, STable2, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
import SSocket from 'servisofts-socket';
import CryptoJS from 'crypto-js';
import Config from '../../Config';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "pago_tarjeta",
            type: "getAll",
            estado: "cargando"
        }).then(e => {
            this.setState({ data: e.data })
        }).catch(e => {

        })
    }

    render() {
        if (!this.state.data) return null;
        return (
            <SPage title={'Developer Tools - Encryptar Tarjetas'} disableScroll>
                <STable2
                    header={[
                        { key: "index", },
                        { key: "numero_tarjeta", width: 150, },
                        { key: "nombre", width:150 },
                        { key: "ano", },
                        { key: "mes", },
                        { key: "codigo_seguridad", width: 200 },
                        {
                            key: "-ecnrypt", width: 100, component: (data) => <SText onPress={() => {
                                if (data["codigo_seguridad"].length > 5) {
                                    SPopup.alert("ya esta encryptado");
                                    return;
                                }
                                let codigo = CryptoJS.AES.encrypt(data["codigo_seguridad"], Config.socket.secret_key_aes).toString();
                                console.log("codigo_seguridad", codigo, data["codigo_seguridad"]);
                                SSocket.sendPromise({
                                    component: "pago_tarjeta",
                                    type: "editar",
                                    estado: "cargando",
                                    data: {
                                        ...data,
                                        codigo_seguridad: codigo,
                                    }
                                }).then(e => {
                                    // this.setState({ data: e.data })
                                    this.state.data[e?.data?.key] = e?.data;
                                    this.setState({ ...this.state })
                                    console.log(e);
                                }).catch(e => {
                                    console.log(e);
                                })
                            }} underLine center>Encrypt</SText>
                        },
                    ]}
                    data={this.state.data} />
            </SPage>
        );
    }
}
