import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SNavigation, SPopup } from 'servisofts-component';
import { Chat } from 'servisofts-rn-chat';
import Model from '../../../Model';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.pk = SNavigation.getParam("pk");
    }

    render() {
        return <Chat key_chat={this.pk} key_usuario={Model.usuario.Action.getKey()} onPressHeader={() => {
            SPopup.confirm({
                title: "¿Seguro que desea dar por concluida la consulta?",
                onPress: () => {
                    Model.chat_mensaje.Action.registro({
                        data: {
                            descripcion: `Esperamos que el problema haya sido resuelto y estamos atento para cualquier duda o consulta que se tenga.`,
                            observacion: "",
                            tipo: "text"
                        },
                        key_chat: this.pk,
                        key_usuario: Model.usuario.Action.getKey()
                    }).then(resp2 => {
                        Model.chat.Action.editar({
                            data: {
                                key: this.pk,
                                estado: 2,
                            }
                        }).then(resp => {
                            console.log(resp);
                        }).catch((e) => {
                            console.error(e);
                        })
                    }).catch(e => {

                    })

                }
            })

        }} />
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);