import { SPage } from "servisofts-component";

import root from "./root";
import lugares_pedidos_restaurante from "./lugares_pedidos_restaurante"
import lugares_pedidos_cliente from "./lugares_pedidos_cliente"

export default SPage.combinePages("lugares_pedidos",
    {
        "": root,
        lugares_pedidos_restaurante, 
        lugares_pedidos_cliente
    }
)

export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k+"-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}